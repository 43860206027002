// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { JsonErrorResponse } from '../entities';
// @ts-ignore
import { LoginRequest } from '../entities';
// @ts-ignore
import { LoginResponse } from '../entities';
/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * check authentication cookie and return login-like response data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * validate credentials, create cookie and return json with user data
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            if (loginRequest === null || loginRequest === undefined) {
                throw new RequiredError('loginRequest','Required parameter loginRequest was null or undefined when calling login.');
            }
            const localVarPath = `/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginRequest !== undefined ? loginRequest : {}) : (loginRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * executes logout by deleting authentication cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoff: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logoff`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * check authentication cookie and return login-like response data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await AuthControllerApiAxiosParamCreator(configuration).check(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * validate credentials, create cookie and return json with user data
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await AuthControllerApiAxiosParamCreator(configuration).login(loginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * executes logout by deleting authentication cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoff(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthControllerApiAxiosParamCreator(configuration).logoff(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * check authentication cookie and return login-like response data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check(options?: any): AxiosPromise<LoginResponse> {
            return AuthControllerApiFp(configuration).check(options).then((request) => request(axios, basePath));
        },
        /**
         * validate credentials, create cookie and return json with user data
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return AuthControllerApiFp(configuration).login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * executes logout by deleting authentication cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoff(options?: any): AxiosPromise<void> {
            return AuthControllerApiFp(configuration).logoff(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - interface
 * @export
 * @interface AuthControllerApi
 */
export interface AuthControllerApiInterface {
    /**
     * check authentication cookie and return login-like response data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApiInterface
     */
    check(options?: any): AxiosPromise<LoginResponse>;

    /**
     * validate credentials, create cookie and return json with user data
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApiInterface
     */
    login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse>;

    /**
     * executes logout by deleting authentication cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApiInterface
     */
    logoff(options?: any): AxiosPromise<void>;

}

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI implements AuthControllerApiInterface {
    /**
     * check authentication cookie and return login-like response data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public check(options?: any) {
        return AuthControllerApiFp(this.configuration).check(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * validate credentials, create cookie and return json with user data
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public login(loginRequest: LoginRequest, options?: any) {
        return AuthControllerApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * executes logout by deleting authentication cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public logoff(options?: any) {
        return AuthControllerApiFp(this.configuration).logoff(options).then((request) => request(this.axios, this.basePath));
    }

}
