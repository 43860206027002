import React from "react";
import { ActionType } from "../../../managers/form.manager";
import { Emitter } from "../../../shared/components/Upload/event.emitter.component";
import { BookState } from "../../../shared/states/book.state";
import BookDetailsForm from "./book.form.details";

interface IBookItemProps {
    formValues?: BookState;
    emitter: Emitter;
    onActions: (actionType: ActionType, payload?: any) => void
    onChange?: (event: any)=>void 
    onBookUploaded?: (file: File) => void
    bookFileError?: boolean
}

export default function BookFormContainer(props: IBookItemProps)  {


    const { formValues, emitter, onActions, onChange, onBookUploaded, bookFileError } = props;
    return (
            <BookDetailsForm
                emitter={emitter}
                formValues={formValues}
                onActions={onActions}
                onChange={onChange}
                onBookUploaded={onBookUploaded}
                bookFileError={bookFileError}
            />
    )
}