import React, { useState } from "react";
import { SeverityEnum } from "../../enums/severity.enum";
import { PositionEnum } from "../../enums/position.enum";
import { ToastManager } from "../../../managers/toast.manager";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";

export interface ToastItemProps {
    message: string;
    variant: SeverityEnum;
}

/**
 * ToastItem
 * 
 * It shows the info of the toast
 * 
 * @property {string} message - message of the toast
 * @property {SeverityEnum} variant - severity of the toast
 * 
 * @returns JSX.Element
 */

const ToastItem: React.FC<ToastItemProps> = ({ message, variant }) => {

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    }

    const hide = () => ToastManager.getInstance().hide();
    
    return (
        <Snackbar
            className="position-snackbar"
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
                horizontal: PositionEnum.RIGHT,
                vertical: PositionEnum.TOP,
            }}
            TransitionProps={{onExited: hide}}
        >
            <Alert
                severity={variant}
                className={"snackbar-" + variant}
                icon={false}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <FormattedMessage id={message || "errors.generic"} />
            </Alert>
        </Snackbar>
    );
}

export default ToastItem;