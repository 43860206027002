import { Button, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import Next from "../../../assets/icons/Mix/Next.svg";
import NextDisabled from "../../../assets/icons/Mix/Next_Disabled.svg";
import Previous from "../../../assets/icons/Mix/Previus.svg";
import PreviousDisabled from "../../../assets/icons/Mix/Previus_Disabled.svg";
import { PaginationEnum } from "../../enums/pagination.enum";

interface PaginationComponentProps {
  onChange: (page: number, size: number) => void;
  page: number;
  size: number;
  total: number;
  sizes?: Array<number>;
}

export class PaginationComponent extends React.PureComponent<PaginationComponentProps> {

  handleOnEdgesClick(type: PaginationEnum) {
    const { page, size, total, onChange } = this.props;

    switch (type) {
      case PaginationEnum.PREV:
        if (page > 0) {
          onChange(page - 1, size);
        }
        break;
      case PaginationEnum.NEXT:
        if (page < Math.ceil(total / size) - 1) {
          onChange(page + 1, size);
        }
        break;
    }
  }

  render() {
    const { page, size, total } = this.props;

    const pages = [];
    for (let i = 0; i < Math.ceil(total / size); i++) {
      pages.push(i);
    }

    const handleOnPageClick = (event: React.ChangeEvent<unknown>, value: number) => {
      const { page, size, onChange } = this.props;

      // Avoid onChange if the current page is clicked again
      value - 1 !== page && onChange(value - 1, size);
    }

    const lastPage = pages[pages.length - 1];



    return (
      <>
        <Grid className="pagination-grid" >
          <Button
            onClick={() => this.handleOnEdgesClick(PaginationEnum.PREV)}
            disabled={page === 0}
            className="pagination-button"
          >
            {
              page === 0 ? <img src={PreviousDisabled} /> : <img src={Previous} />
            }

          </Button>
          {total > 0 && (
            <Pagination count={lastPage + 1} page={page + 1} onChange={handleOnPageClick} defaultPage={0}
              hidePrevButton hideNextButton className="MuiPaginationItem-root MuiPaginationItem-icon"
            />

          )}
          <Button
            onClick={() => this.handleOnEdgesClick(PaginationEnum.NEXT)}
            disabled={page === lastPage}
            className="pagination-button"
          >
            {
              page === lastPage ? <img src={NextDisabled} /> : <img src={Next} />
            }
          </Button>
        </Grid>
      </>
    );
  }
}
