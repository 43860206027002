import { BookStatsSearchRequest, BookStatsSearchResponse, InstituteStatsSearchResponse, StatsSearchFilter, TotalStatsResponse } from "../../api/entities";
import { IStatisticsApiClients, StatisticsApiClients } from "./statistics.api.client";


export interface IStatisticsApiService {
  getTotal(): Promise<TotalStatsResponse>;
  getBooksStats(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: StatsSearchFilter
  ): Promise<BookStatsSearchResponse>;
  getInstitutesStats(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: StatsSearchFilter
  ): Promise<InstituteStatsSearchResponse>;
  exportCSV();
  
}

export class StatisticsApiService implements IStatisticsApiService {
  protected static instance: IStatisticsApiService;
  protected api: IStatisticsApiClients;

  protected constructor(basePath: string) {
    this.api = StatisticsApiClients.getInstance(basePath);
  }
  
  async exportCSV() {
    return (await this.api.statistics.exportCsv());
  }
  

  async getTotal(): Promise<TotalStatsResponse> {
    return (await this.api.statistics.getTotal()).data;
  }

  static getInstance(basePath: string) {
    if (StatisticsApiService.instance == null) {
      StatisticsApiService.instance = new StatisticsApiService(basePath);
    }
    return StatisticsApiService.instance;
  }

  async getBooksStats(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: StatsSearchFilter
  ): Promise<BookStatsSearchResponse> {
    return (
      await this.api.statistics.searchBooks({
        page,
        size,
        query,
        sort,
        filter,
      })
    ).data;
  }

  async getInstitutesStats(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: StatsSearchFilter
  ): Promise<InstituteStatsSearchResponse> {
    return (
      await this.api.statistics.searchInstitutes({
        page,
        size,
        query,
        sort,
        filter,
      })
    ).data;
  }
 
}
