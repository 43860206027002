import { StoreManager } from "@hypereact/state";
import {
  BookResetFilterAction,
  BookResetFormAction,
  BookResetQueryAction,
  BookSetBookSearchOrder,
  BookSetBookSearchQuery,
  BookSetContextAction,
  BookSetFileErrorAction,
  BookSetItemAction,
  BookSetItemsAction,
  BookSetPaginationAction,
  BookSetShowClearFilter,
  BookStatisticsSearchGrade,
} from "../actions/book.actions";
import {
  BookSearchFilter,
  BookSearchResponse,
  InstituteGradeEnum,
} from "../api/entities";
import { Book } from "../api/entities/book";
import { BookCreateModel } from "../api/entities/book-create-model";
import { ReduxSlices } from "../config/enums/redux.slices";
import {
  BookApiService,
  IBookApiService,
} from "../services/book/book.api.service";
import { FilterBarEnum } from "../shared/enums/filterBar.enum";
import { Config } from "../shared/interfaces/list/config.interface";
import { ListPaginationInterface } from "../shared/interfaces/list/list.pagination.interface";
import { BookState, initialBookState } from "../shared/states/book.state";
import { UserState } from "../shared/states/user.state";
import { ProgressUtil } from "../shared/utils/progress.util";
import { ConfigManager } from "./config.manager";
import { GradeEnum } from "./statistics.manager";

export interface IBookManager {
  create(book: BookCreateModel, file: File): Promise<Book>;
  getById(id: number): Promise<Book>;
  patch(oldBook: Book, updatedBook: Book): Promise<Book>;
  search(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: BookSearchFilter
  ): Promise<BookSearchResponse>;
  upload(id: number, file: any): Promise<Book>;
  download(id);
  delete(id);
}

export class BookManager implements IBookManager {
  private static instance: BookManager;
  private bookService: IBookApiService;
  private progressUtil: ProgressUtil;
  private storeManager: StoreManager;
  private configManager: ConfigManager;

  private constructor() {
    this.bookService = BookApiService.getInstance(
      process.env.REACT_APP_MICROSERVICE_BIP_BASEPATH!
    );
    this.storeManager = StoreManager.getInstance();
    this.progressUtil = ProgressUtil.getInstance();
    this.configManager = ConfigManager.getInstance();
  }

  async upload(id: number, file: any): Promise<Book> {
    const response = await this.progressUtil.queue(
      this.bookService.upload(id, file),
      true,
      () => { }
    );
    return response;
  }

  async delete(id: any) {
    return await this.progressUtil.queue(
      this.bookService.delete(id),
      true,
      () => { }
    );
  }

  download(id: any) {
    window.open(
      `${window.location.origin}${process.env.REACT_APP_MICROSERVICE_BIP_BASEPATH}/download/${id}`
    );
  }

  /**
   * Singleton getInstance
   * @returns The single instance of the book manager
   */
  static getInstance(): BookManager {
    if (BookManager.instance == null) {
      BookManager.instance = new BookManager();
    }
    return BookManager.instance;
  }

  /**
   * Creates a book
   * @param book The book to be created
   * @returns The created book (including the id)
   */
  async create(book: BookCreateModel, bookFile: File): Promise<Book> {
    if (!bookFile) {
      this.storeManager.dispatch(new BookSetFileErrorAction(true));
      return;
    }

    const createdBook = await this.progressUtil.queue(
      this.bookService.create(book),
      true,
      () => { }
    );
    await this.upload(createdBook.id, bookFile);
    await this.storeManager.dispatch(new BookSetContextAction(undefined));
    this.storeManager.dispatch(new BookSetFileErrorAction(false));
    const userState: UserState = this.storeManager.getState(ReduxSlices.User);
    await this.storeManager.dispatch(
      new BookResetFormAction(userState.personalData.roles)
    );
    return createdBook;
  }

  /**
   * Gets a book by id
   * @param id The id of the book to be got
   * @returns The book with the specified id
   */
  async getById(id: number): Promise<Book> {
    const response = await this.progressUtil.queue(
      this.bookService.getById(id),
      true,
      () => { }
    );
    this.storeManager.dispatch(new BookSetItemAction(response));
    return response;
  }

  /**
   * Patches the book
   * @param oldBook The old book to be patched with
   * @param updatedBook The updted book
   */
  async patch(oldBook: Book, updatedBook: Book): Promise<Book> {
    if (oldBook.id! !== updatedBook.id) {
      throw new Error("the books identifiers are not equal");
    } else {
      return await this.progressUtil.queue(
        this.bookService.patch(oldBook, updatedBook),
        true,
        () => { }
      );
    }
  }

  /**
   * Searces the books in function of the searching criteria
   * @param page The page for pagination purposes
   * @param size The size for pagination purposes
   * @param query The keyword
   * @param sort The sorting options
   * @param filter The book search filter
   * @returns The books that meet the criteria
   */
  async search(): Promise<BookSearchResponse> {
    const bookState = this.getState();

    const pagination: ListPaginationInterface = bookState.pagination;

    const bookSearch = this.getState();
    const order = bookSearch.order
      ? { [bookSearch.order.by]: bookSearch?.order.direction }
      : undefined;

    const userState = this.getUserState();
    const bookSearchFilter: BookSearchFilter = bookState.filters;
    if (userState.institute) {
      bookSearchFilter.grade = InstituteGradeEnum._01 === userState.institute.grade ? 'O1' : 'O2';
    }

    const response: BookSearchResponse = await this.progressUtil.queue(
      this.bookService.search(
        pagination.page!,
        pagination.size!,
        bookState.query,
        order,
        bookSearchFilter
      ),
      true,
      () => { }
    );
    this.storeManager.dispatch(
      new BookSetItemsAction(response.books!, response?.page?.total || 0)
    );
    return response;
  }

  private getState(): BookState {
    return this.storeManager.getState(ReduxSlices.Book) as BookState;
  }

  private getUserState(): UserState {
    return this.storeManager.getState(ReduxSlices.User) as UserState;
  }

  async setPagination(page: number, size: number): Promise<void> {
    this.storeManager.dispatch(new BookSetPaginationAction(page, size));
    await this.search();
  }

  resetPagination(): void {
    this.storeManager.dispatch(new BookSetPaginationAction(initialBookState.pagination.page, initialBookState.pagination.size));
  }

  getConfig(items: Map<string, any>): Map<string, Config> {
    const bookState = this.getState();
    let configMap: Map<string, Config> = new Map();
    let itemKeys = Array.from(items.keys());
    itemKeys.forEach((key) => {
      switch (key) {
        case FilterBarEnum.QUERY:
          configMap.set(FilterBarEnum.QUERY, {
            name: "book",
            value: this.getState().query,
          });
          break;
        case FilterBarEnum.ORDER:
          configMap.set(FilterBarEnum.ORDER, {
            value: bookState.order,
            items: this.configManager.getState().config.bookSearch.items,
          });
          break;
        case FilterBarEnum.GRADE:
          const value: string[] = bookState.filters.grade
            ? bookState.filters.grade!.split(",")
            : [];
          configMap.set(FilterBarEnum.GRADE, {
            value: value,
            items: GradeEnum,
          });
          break;
      }
    });
    return configMap;
  }

  async onFilterChange(name: string, value: any) {
    const state = this.getState();
    switch (name) {
      case FilterBarEnum.QUERY:
        const stateQuery = state.query;
        const query = value !== "" ? value : undefined;
        this.storeManager.dispatch(new BookSetBookSearchQuery(query));
        if ((query !== stateQuery && query?.length! >= 3) || !query) {
          await this.search();
        }
        break;
      case FilterBarEnum.ORDER:
        this.storeManager.dispatch(new BookSetBookSearchOrder(value));
        await this.search();
        break;
      case FilterBarEnum.GRADE:
        this.storeManager.dispatch(new BookStatisticsSearchGrade(value));
        await this.search();
        break;
      default:
        break;
    }
  }

  clearFilters(): void {
    this.storeManager.dispatch(new BookResetFilterAction());
    this.storeManager.dispatch(new BookResetQueryAction());
  }

  async fullReset(makeCall: boolean = true) {
    this.clearFilters();
    if (makeCall) {
      await this.search();
    }
    this.storeManager.dispatch(new BookSetShowClearFilter(false));
  }
}
