import React from "react";
import { Backdrop as MuiBackDrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReduxConnect } from "@hypereact/state";
import { ReduxState } from "../../../config/redux.config";
import Loader from "../../../assets/images/loader_speeded.gif";

interface IBackdropProps {
  loading?: boolean;
}

@ReduxConnect((state: ReduxState) => {
  return {
    loading: state.http.loading,
  };
})
export class Backdrop extends React.Component<IBackdropProps> {
  render() {
    return (
      <div>
        <MuiBackDrop className="back-drop" open={this.props.loading!}  >
          <img src={Loader} className="image" style={{ width: '80px', height: '80px' }} />
        </MuiBackDrop>
      </div>
    );
  }
}
