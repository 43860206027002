import { BookSearchFilter } from "../../api/entities";
import { Book } from "../../api/entities/book";
import { BookDetailsInterface } from "../../feature/book/beans/book.details.data";
import { FileDetails } from "../beans/file.details";
import { ContextEnum } from "../enums/context.enum";
import { OrderDirectionEnum } from "../enums/order.direction.enum";
import { ListPaginationInterface } from "../interfaces/list/list.pagination.interface";
import { SharedState } from "./shared.state";

export interface BookState extends SharedState<Book, BookSearchFilter> {
    showClearFilter: boolean;
    createUpdate: BookCreateUpdate;
    bookFileError: boolean;
}

export interface BookCreateUpdate {
    details: BookDetailsInterface;
    context?: ContextEnum;
}

export const initialBookState: BookState = {
    items: [],
    order: {
        by: "updatedAt",
        direction: OrderDirectionEnum.DESC,
      },
    filters: {
        grade: "O1,O2"
    },
    pagination: {
        page: 0,
        size: 5,
        total: 0,
    },
    item: {
        code: "",
    },
    showClearFilter: false,
    createUpdate: {
        details: {
            code: "",
        }
    },
    bookFileError: false

}