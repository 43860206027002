import { IReduceableAction, ReduxAction } from "@hypereact/state";
import { BookStats, InstituteStats } from "../api/entities";
import { ReduxSlices } from "../config/enums/redux.slices";
import { StatisticsOptionsEnum } from "../shared/enums/statistics.options";
import { Order } from "../shared/interfaces/list/order.interface";
import {
  BooksStatisticsState,
  initialBookStatisticsState,
  initialInstituteStatisticsState,
  InstituteStatisticsState,
  StatisticsState,
} from "../shared/states/statistics.state";

enum StatisticsActions {
  SetOption = "SET_STATISTICS_OPTION",
  SetTotalNumberOfDownload = "SET_STATISTICS_TOTAL_NUMBER_OF_DOWNLOAD",
  SetBookStatisticsQuery = "SET_BOOK_STATISTICS_QUERY",
  SetInstituteStatisticsQuery = "SET_INSTITUTE_STATISTICS_QUERY",
  ResetBookStatisticsQuery = "RESET_BOOK_STATISTICS_QUERY",
  ResetInstituteStatisticsQuery = "RESET_INSTITUTE_STATISTICS_QUERY",
  ResetBookStatisticsFilter = "RESET_BOOK_STATISTICS_FILTER",
  ResetInstituteStatisticsFilter = "RESET_INSTITUTE_STATISTICS_FILTER",
  ResetBookStatisticsGrade = "RESET_BOOK_STATISTICS_GRADE",
  SetBookStatisticsOrder = "SET_BOOK_STATISTICS_ORDER",
  SetBookStatisticsGrades = "SET_BOOK_STATISTICS_GRADES",
  SetInstituteStatisticsGrade = "SET_INSTITUTE_STATISTICS_GRADES",
  ResetInstituteStatisticsGrade = "RESET_INSTITUTE_STATISTICS_GRADE",
  SetInstituteStatisticsOrder = "SET_INSTITUTE_STATISTICS_ORDER",
  SetBookStatisticsItems = "SET_BOOK_STATISTICS_ITEMS",
  SetInstituteStatisticsItems = "SET_INSTITUTE_STATISTICS_ITEMS",
  SetBookStatisticsShowClearFilter = "SET_BOOK_STATISTICS_SHOW_CLEAR_FILTER",
  SetInstituteStatisticsShowClearFilter = "SET_INSTITUTE_STATISTICS_SHOW_CLEAR_FILTER",
  SetBookStatisticsPaginationAction = "SET_BOOK_STATISTICS_PAGINATION_ACTION",
  SetInstituteStatisticsPaginationAction = "SET_INSTITUTE_STATISTICS_PAGINATION_ACTION",
}

@ReduxAction(
  StatisticsActions.SetBookStatisticsPaginationAction,
  ReduxSlices.BookStatistics
)
export class BookStatisticsSetPaginationAction {
  constructor(public page: number, public size: number) {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.pagination.page = this.page;
    bookStatisticsState.pagination.size = this.size;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetInstituteStatisticsPaginationAction,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsSetPaginationAction {
  constructor(public page: number, public size: number) {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.pagination.page = this.page;
    instituteStatisticsState.pagination.size = this.size;
    return instituteStatisticsState;
  }
}

@ReduxAction(StatisticsActions.SetTotalNumberOfDownload, ReduxSlices.Statistics)
export class StatisticsTotalNumberOfDownload {
  constructor(public totalNumberOfDownload: number) {}

  reduce(statisticsState: StatisticsState) {
    statisticsState.totalNumberOfDownload = this.totalNumberOfDownload;
    return statisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetBookStatisticsShowClearFilter,
  ReduxSlices.BookStatistics
)
export class BookStatisticsSetShowClearFilter {
  constructor(public showClearFilter: boolean) {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.showClearFilter = this.showClearFilter;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetInstituteStatisticsShowClearFilter,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsSetShowClearFilter {
  constructor(public showClearFilter: boolean) {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.showClearFilter = this.showClearFilter;
    return instituteStatisticsState;
  }
}

@ReduxAction(StatisticsActions.SetOption, ReduxSlices.Statistics)
export class ConfigSetStatisticsOptionAction {
  constructor(public statisticsOptions: StatisticsOptionsEnum) {}

  reduce(statisticsState: StatisticsState) {
    statisticsState.statisticsOption = this.statisticsOptions;
    return statisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetBookStatisticsQuery,
  ReduxSlices.BookStatistics
)
export class BookStatisticsSetQueryAction
  implements IReduceableAction<BooksStatisticsState>
{
  constructor(public query: string) {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.query = this.query!;
    bookStatisticsState.showClearFilter = bookStatisticsState.query != undefined;
    bookStatisticsState.pagination.page = 0;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetBookStatisticsItems,
  ReduxSlices.BookStatistics
)
export class BookStatisticsSetItemsAction
  implements IReduceableAction<BooksStatisticsState>
{
  constructor(public stats: Array<BookStats>, public total?: number) {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.items = this.stats;
    bookStatisticsState.pagination.total = this.total || 0;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetInstituteStatisticsItems,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsSetItemsAction
  implements IReduceableAction<InstituteStatisticsState>
{
  constructor(public stats: Array<InstituteStats>, public total?: number) {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.items = this.stats;
    instituteStatisticsState.pagination.total = this.total || 0;
    return instituteStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetInstituteStatisticsQuery,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsSetQueryAction
  implements IReduceableAction<InstituteStatisticsState>
{
  constructor(public query: string) {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.query = this.query!;
    instituteStatisticsState.showClearFilter = instituteStatisticsState.query != undefined;
    instituteStatisticsState.pagination.page = 0;
    return instituteStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.ResetBookStatisticsQuery,
  ReduxSlices.BookStatistics
)
export class BookStatisticsResetQueryAction {
  constructor() {}

  reduce(bookStatistics: BooksStatisticsState) {
    bookStatistics.query = undefined;
    return bookStatistics;
  }
}

@ReduxAction(
  StatisticsActions.ResetBookStatisticsGrade,
  ReduxSlices.BookStatistics
)
export class ResetBookStatisticsGradeAction {
  constructor() {}

  reduce(bookStatistics: BooksStatisticsState) {
    bookStatistics.filters.grade = initialBookStatisticsState.filters.grade;
    return bookStatistics;
  }
}

@ReduxAction(
  StatisticsActions.ResetInstituteStatisticsGrade,
  ReduxSlices.InstituteStatistics
)
export class ResetInstituteStatisticsGradeAction {
  constructor() {}

  reduce(instituteStatistics: InstituteStatisticsState) {
    instituteStatistics.filters.grade = initialInstituteStatisticsState.filters.grade;
    return instituteStatistics;
  }
}

@ReduxAction(
  StatisticsActions.ResetInstituteStatisticsQuery,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsResetQueryAction {
  constructor() {}
  reduce(instituteStatistics: InstituteStatisticsState) {
    instituteStatistics.query = undefined;
    return instituteStatistics;
  }
}

@ReduxAction(
  StatisticsActions.ResetBookStatisticsFilter,
  ReduxSlices.BookStatistics
)
export class BookStatisticsResetFilterAction {
  constructor() {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.filters = initialBookStatisticsState.filters;
    bookStatisticsState.order = initialBookStatisticsState.order;
    bookStatisticsState.pagination.page = 0;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.ResetInstituteStatisticsFilter,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsResetFilterAction {
  constructor() {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.filters = initialInstituteStatisticsState.filters;
    instituteStatisticsState.order = initialInstituteStatisticsState.order;
    instituteStatisticsState.pagination.page = 0;
    return instituteStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetBookStatisticsOrder,
  ReduxSlices.BookStatistics
)
export class BookStatisticsSetOrderAction
  implements IReduceableAction<BooksStatisticsState>
{
  constructor(public order: Order) {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.order = this.order;
    if (bookStatisticsState.order != initialBookStatisticsState.order) {
      bookStatisticsState.showClearFilter = true;
    }
    bookStatisticsState.pagination.page = 0;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetBookStatisticsGrades,
  ReduxSlices.BookStatistics
)
export class BookStatisticsSetGradesAction
  implements IReduceableAction<BooksStatisticsState>
{
  constructor(public grades: string[]) {}

  reduce(bookStatisticsState: BooksStatisticsState) {
    bookStatisticsState.filters.grade = this.grades.join(',');
    
    if (bookStatisticsState.filters.grade != initialBookStatisticsState.filters.grade) {
      bookStatisticsState.showClearFilter = true;
    }
    bookStatisticsState.pagination.page = 0;
    return bookStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetInstituteStatisticsGrade,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsSetGradesAction
  implements IReduceableAction<InstituteStatisticsState>
{
  constructor(public grades: string[]) {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.filters.grade = this.grades.join(',');
    
    if (instituteStatisticsState.filters.grade != initialInstituteStatisticsState.filters.grade) {
      instituteStatisticsState.showClearFilter = true;
    }
    instituteStatisticsState.pagination.page = 0;
    return instituteStatisticsState;
  }
}

@ReduxAction(
  StatisticsActions.SetInstituteStatisticsOrder,
  ReduxSlices.InstituteStatistics
)
export class InstituteStatisticsSetOrderAction
  implements IReduceableAction<InstituteStatisticsState>
{
  constructor(public order: Order) {}

  reduce(instituteStatisticsState: InstituteStatisticsState) {
    instituteStatisticsState.order = this.order;
    if (
      instituteStatisticsState.order != initialInstituteStatisticsState.order
    ) {
      instituteStatisticsState.showClearFilter = true;
    }
    instituteStatisticsState.pagination.page = 0;
    return instituteStatisticsState;
  }
}
