import React, { ReactElement } from "react";
import ToastItem, { ToastItemProps } from "../shared/components/Toast/toast.item";
import { NotificationManager } from "./notification.manager";


type Toast = ReactElement | null;

export class ToastManager extends NotificationManager {
  private static instance: ToastManager;
  private set: (toast: Toast) => void;

  static getInstance(set?: (toast: Toast) => void) {
    if (ToastManager.instance == null) {
      if (!set){
        throw new Error('ToastManager - Setter method must be defined at initialization time');
      }
      else ToastManager.instance = new ToastManager(set);
    }
    return ToastManager.instance;
  }

  private constructor(set: (toast: Toast) => void) {
    super();
    this.set = set;
  }

  show(props: ToastItemProps) {
    const toastItem: ReactElement = React.createElement(ToastItem, props);
    this.set(toastItem);
  }

  hide() {
    this.set(null);
  }
}
