import { FileTypeConfig } from "../../../beans/filetype.config";

export const fileConfig: FileTypeConfig = {
    cover: {
        maxSize: 25000000,
        ext: [".jpeg", ".png", ".jpg"],
        types: ["image/png", "image/jpg", "image/jpeg"]
    },
    book: {
        maxSize: 50000000,
        ext: [".pdf"],
        types: ["application/pdf"]
    }
}
