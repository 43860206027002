export enum StatisticsOptionsEnum {
    Institute = 'INSTITUTE',
    Book = 'BOOK',
}
export class StatisticsUtil {

    static statisticsEnumValues: string[] = [];

    public static getEnumValues() : string[] {
        if(StatisticsUtil.statisticsEnumValues.length === 0) {
            for (var key in StatisticsOptionsEnum) {
                StatisticsUtil.statisticsEnumValues.push(StatisticsOptionsEnum[key]); 
            }
        }
        return StatisticsUtil.statisticsEnumValues;
    }

}
