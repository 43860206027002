import {
  Button, Checkbox,




  Grid, Menu,
  MenuItem, Typography
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import { EnumType } from "typescript";
import CaretDownEmpty from "../../../assets/icons/Form-icon/Caret-down_empty.svg";
import Advanced from "../../../assets/icons/Mix/Advanced.svg";
import Check from "../../../assets/icons/Mix/Checkbox_Selected.svg";
import UnCheck from "../../../assets/icons/Mix/Checkbox_UnSelected.svg";
import TooltipComponent from "../TooltipComponent/tooltip.component";
import { IFilterBarItemProps } from "./filterbar.item.interface";

export interface GradeFilterBarItemProps extends IFilterBarItemProps {
  value: string[];
  items: EnumType;
  onChange: (value?: string[]) => void;
}

interface GradeCheckboxElementState {
  anchorEl: null;
  showStatusDropdown: boolean;
}

export class GradeFilterBarItem extends React.Component<
  GradeFilterBarItemProps,
  GradeCheckboxElementState
> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  constructor(
    props: GradeFilterBarItemProps,
    state: GradeCheckboxElementState
  ) {
    super(props);
    this.state = {
      anchorEl: null,
      showStatusDropdown: false,
    };

  }

  handleGetIndex() {}



  handleOnChange(enumKey: string, event: React.ChangeEvent<HTMLInputElement>) {
    const {items, value} = this.props;
    let copiedValue = [...value];
    
    if(event.target.checked) {
      copiedValue.push(items[enumKey]);
    } else {
      if (copiedValue.length > 1) {
        let index = copiedValue.indexOf(items[enumKey]);
        if (index > -1) {
          copiedValue.splice(index, 1);
        }
      }
    }

    this.props.onChange(copiedValue);
  }

  handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleClicck() {
    this.setState({ showStatusDropdown: !this.state.showStatusDropdown });
  }

  render() {
    const { name, value, items } = this.props;
    const { anchorEl } = this.state;


    return (
      <Grid container className="status-text">
        <Grid item>
          <span className="advanced-button">
            <Button
              aria-controls="menu"
              aria-haspopup="false"
              disableTouchRipple={true}
              className="MuiButton-label light-grey "
              onClick={() => this.handleClicck()}
            >
              <TooltipComponent
                placement="bottom"
                title={`${this.context.formatMessage({
                  id: `advanceSearch.tooltip.label`,
                })}`}
              >
                <img
                  className="advanced-search-icon button-border-filter"
                  src={Advanced}
                />
              </TooltipComponent>
            </Button>
          </span>
          {this.state.showStatusDropdown && (
            <Grid
              container
              justifyContent="flex-start"
              className="container-status"
            >
              <Grid item className="filter-satus">
                <Button
                  aria-controls="menu"
                  aria-haspopup="false"
                  disableTouchRipple={true}
                  className={
                    Object.keys(items ? items : {}).length > value.length
                      ? "status-style-change"
                      : "status-style"
                  }
                  onClick={(event: any) => this.handleClick(event)}
                >
                  <FormattedMessage id={`filter.bar.${name}.name`} />:
                  <FormattedMessage id={(Object.keys(items ? items : {}).length === value.length
                    || value.length === 0) ? `filter.bar.grade.all` : `filter.bar.grade.${value[0]}`} />
                  <img src={CaretDownEmpty} />
                </Button>

                <Menu
                  className="ml-10"
                  id="menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => this.handleClose()}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {Object.keys(items ? items : {}).map(
                    (enumKey: string, index: number) => (
                      <MenuItem key={index} className="menu-item-staus">
                        <Checkbox
                          icon={<img src={UnCheck} className="pl-5" />}
                          edge="start"
                          name={enumKey}
                          value={items[enumKey as keyof typeof items]}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.handleOnChange(enumKey, event)}
                          checked={
                            value.indexOf(items[enumKey]) > -1
                          }
                          checkedIcon={<img src={Check} className="pl-5" />}
                        />
                        <Typography className="text-color">
                          <FormattedMessage
                            id={`filter.bar.${name}.${enumKey}`}
                          />
                        </Typography>
                      </MenuItem>
                    )
                  )}
                </Menu>
              </Grid>
              <div className="distance-filter"></div>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
