// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { JsonErrorResponse } from '../entities';
/**
 * DownloadControllerApi - axios parameter creator
 * @export
 */
export const DownloadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * download document
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBook: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadBook.');
            }
            const localVarPath = `/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadControllerApi - functional programming interface
 * @export
 */
export const DownloadControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * download document
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBook(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DownloadControllerApiAxiosParamCreator(configuration).downloadBook(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DownloadControllerApi - factory interface
 * @export
 */
export const DownloadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * download document
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBook(id: number, options?: any): AxiosPromise<object> {
            return DownloadControllerApiFp(configuration).downloadBook(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadControllerApi - interface
 * @export
 * @interface DownloadControllerApi
 */
export interface DownloadControllerApiInterface {
    /**
     * download document
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadControllerApiInterface
     */
    downloadBook(id: number, options?: any): AxiosPromise<object>;

}

/**
 * DownloadControllerApi - object-oriented interface
 * @export
 * @class DownloadControllerApi
 * @extends {BaseAPI}
 */
export class DownloadControllerApi extends BaseAPI implements DownloadControllerApiInterface {
    /**
     * download document
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadControllerApi
     */
    public downloadBook(id: number, options?: any) {
        return DownloadControllerApiFp(this.configuration).downloadBook(id, options).then((request) => request(this.axios, this.basePath));
    }

}
