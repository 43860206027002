import { PopupItemProps } from "../shared/components/Popup/popup.item";
import { ToastItemProps } from "../shared/components/Toast/toast.item";


/**
 * Notification Manager as common abstract class for the types (e.g. PopupManager, ToastManager)
 */
export abstract class NotificationManager {  

  /**
   * Shows the notification in function of the input notification model
   * @param notificationModel the notification model
   */
  abstract show(notificationModel: PopupItemProps | ToastItemProps): void;
  /**
   * Hides the notification
   */
  abstract hide(): void;
}
