import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import React from "react";
import CountUp from "react-countup";
import { FormattedMessage } from "react-intl";
import { InstituteStats } from "../../../api/entities";

interface IInstituteViewProps {
  items?: any[];
}

export class StatisticsInstituteSearchResultComponent extends React.Component<IInstituteViewProps> {
  constructor(props: IInstituteViewProps) {
    super(props);
  }

  render() {
    const { items } = this.props;
    return items && items.length > 0 ? (
        <TableContainer component={Paper} className="table-container-statistics">
          <Table aria-label="simple table">
            <TableHead className="table-head table-body">
              <TableRow>
                <TableCell className="upload-document-list-header table-head links-style">
                  <FormattedMessage id="statistics.institutes.result.code.cellhead" />
                </TableCell>
                <TableCell className="upload-document-list-header table-head links-style">
                  <FormattedMessage id="statistics.institutes.result.title.cellhead" />
                </TableCell>
                <TableCell className="upload-document-list-header table-head links-style">
                  <FormattedMessage id="statistics.institutes.result.grade.cellhead" />
                </TableCell>
                <TableCell className="upload-document-list-header table-head links-style">
                  <FormattedMessage id="statistics.institutes.result.hitnumber.cellhead" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {items.map(
                (currentInstituteStats: InstituteStats, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="table-cell table-cell-statistics">
                      <div>{currentInstituteStats.institute.code ?? "-"}</div>
                    </TableCell>
                    <TableCell className="table-cell table-cell-statistics">
                      <div>{currentInstituteStats.institute.title ?? "-"}</div>
                    </TableCell>
                    <TableCell className="table-cell table-cell-statistics">
                      <div>
                        {currentInstituteStats.institute.grade ? (
                          <FormattedMessage
                            id={`statistics.institutes.grade.${currentInstituteStats.institute.grade}`}
                          />
                        ) : (
                          "-"
                        )}
                      </div>
                    </TableCell>

                    <TableCell className="table-cell">
                    <div>{currentInstituteStats.hits ? <CountUp end={currentInstituteStats.hits } duration={1}/> : "-"}</div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
    ) : <h4>
    <FormattedMessage id="statistics.institute.empty" />
</h4>
  }
}
