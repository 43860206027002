import Button from "@material-ui/core/Button";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RouteEnum } from "../../../shared/enums/route.enum";
import { NavigationService } from "../../../shared/services/navigation.service";

interface IInstituteHeaderListProps {
    
}


export class InstituteHeaderList extends React.Component<IInstituteHeaderListProps> {
    private navigationService: NavigationService;

    constructor(props: IInstituteHeaderListProps) {
        super(props);
        this.navigationService = NavigationService.getInstance();
    }

    async handleOnCreateClick() {
        this.navigationService.goto(RouteEnum.INSTITUTE_CREATE);
    }

    render() {
        return (
            <>
                {
                    (
                        <>
                            <Button
                                className="common-button" 
                                onClick={() => this.handleOnCreateClick()}>
                                <FormattedMessage id="common.button.createInstitute" />
                            </Button>
                        </>
                )}
            </>
        );
    }
}
