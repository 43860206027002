export enum ReduxSlices {
  Book = "book",
  User = "user",
  Http = "http",
  Navigation = "navigation",
  Config = "config",
  Institute = "institute",
  Statistics = "statistics",
  BookStatistics = "bookStatistics",
  InstituteStatistics = "instituteStatistics"
}
