import { FormProxy } from "../interfaces/form/form.proxy.interface";
import { IntlShape } from "react-intl";

export class FormHelperUtil {
    static generateFormProxy(useFormResult: any, proxy: FormProxy): any {
        const { register, handleSubmit, errors, setError, clearError, triggerValidation, reset, setValue, getValues, formState } = useFormResult;

        const wrappedHandleSubmit = handleSubmit(proxy.onSubmit);
        const wrapperHandleChange = function (event: any) {
            if (typeof proxy.onChange === "function") {
                proxy.onChange(event.target.name, event.target.value, errors[event.target.name], event);
            }
        }
        const wrapperHandleBlur = function (event: any) {
            if (typeof proxy.onBlur === "function") {
                proxy.onBlur(event.target.name, event.target.value, errors[event.target.name], event);
            }
        }

        const wrapperHandleReset = function () {
            if (typeof proxy.onReset === "function") {
                proxy.onReset();
            }
        }

        const wrapperHandleEnter = function (event: any) {
            if (event.key === "Enter" && typeof proxy.onEnter === "function") {
                proxy.onEnter();
            }
        }

        proxy.submit = function () {
            wrappedHandleSubmit();
        }
        proxy.reset = (defaultValues?: any) => {
            reset(defaultValues);
            wrapperHandleReset();
        }
        proxy.setValue = setValue;
        proxy.getValues = getValues;
        proxy.setError = setError;
        proxy.clearError = clearError;
        proxy.triggerValidation = triggerValidation;
        proxy.errors = errors;
        proxy.formState = formState;

        return Object.assign(useFormResult, {
            wrappedHandleSubmit,
            wrapperHandleChange,
            wrapperHandleBlur,
            wrapperHandleReset,
            wrapperHandleEnter
        });
    }

    static getFormFieldErrorTranslator(formName: string, intl: IntlShape) {
        return (fieldName: string, errors: any, errorFieldIntlValues: any = {}): string => {
            return intl.formatMessage({ id: `form.${formName}.${fieldName}.${errors[fieldName].type}` }, { ...errorFieldIntlValues });
        }
    }

    static mapApiErrorToFormProxy(data: any, proxy: FormProxy, fallbackFieldName?: string) {
        const formFields = Object.keys(proxy.getValues());
        let mappedErrors: number = 0;

        let messageKeyParts = data.message_key.split(".");
        if (data.errors) {
            data.errors.forEach((error: any) => {
                let subMessageKeyParts = error.message_key.split(".");
                if (formFields.includes(subMessageKeyParts[0])) {
                    proxy.setError(subMessageKeyParts[0], `api.error.${subMessageKeyParts[1]}`, error.message);
                    ++mappedErrors;
                }
            })
        } else if (formFields.includes(messageKeyParts[0])) {
            proxy.setError(messageKeyParts[0], `api.error.${messageKeyParts[1]}`, data.message);
            ++mappedErrors;
        }

        if (mappedErrors === 0 || (data.errors != null && mappedErrors < data.errors.length)) {
            if (fallbackFieldName) {
                proxy.setError(fallbackFieldName, `api.error.${data.message_key}`, data.message);
            } else {
                return data;
            }
        }
    }

    static scrollToError() {
        let target = document.querySelector(".invalid-feedback")
        if (target) {
            target.scrollIntoView({ block: "center", behavior: "smooth" });
        }
    }

}
