import { StoreManager } from "@hypereact/state";
import { BookResetFormAction, BookResetItemAction, BookSetContextAction } from "../actions/book.actions";
import { ConfigSetPanelsMenuAction } from "../actions/config.actions";
import { InstituteResetFormAction, InstituteResetSelectedInstitute, InstituteSetContextAction } from "../actions/institute.actions";
import Books from "../assets/icons/Menu-Icon/Books.svg";
import Home from "../assets/icons/Menu-Icon/Home.svg";
import Institutes from "../assets/icons/Menu-Icon/Institutes.svg";
import { AuthManager } from "../managers/auth.manager";
import { IMenuItemProps } from "../shared/components/Menu/menu.item.element";
import { RouteEnum } from "../shared/enums/route.enum";
import { NavigationService } from "../shared/services/navigation.service";

interface IMenuConfig {
  getMenuItems(): Array<IMenuItemProps>;
}

export class MenuConfig implements IMenuConfig {
  private navigationService: NavigationService;
  private storeManager: StoreManager;
  private static instance: MenuConfig;
  private authManager: AuthManager;

  constructor() {
    this.navigationService = NavigationService.getInstance();
    this.storeManager = StoreManager.getInstance();
    this.authManager = AuthManager.getInstance();
  }

  static getInstance() {
    if (MenuConfig.instance == null) {
      MenuConfig.instance = new MenuConfig();
    }
    return MenuConfig.instance;
  }

  getMenuItems(): Array<IMenuItemProps> {
    return [
      {
        name: "menu.item.statistics.title",
        route: RouteEnum.STATISTICS,
        onClick: (route?: RouteEnum) => {
          this.navigationService.goto(route!);
          this.storeManager.dispatch(new ConfigSetPanelsMenuAction(false));
        },
        icon: Home
      },
      {
        name: "menu.item.book.title",
        route: RouteEnum.BOOK,
        onClick: (route?: RouteEnum) => {
          this.navigationService.goto(route!);
          this.storeManager.dispatch(new ConfigSetPanelsMenuAction(false));
          this.storeManager.dispatch(new BookResetFormAction());
          this.storeManager.dispatch(new BookResetItemAction());
          this.storeManager.dispatch(new BookSetContextAction(undefined));
        },
        selected: this.navigationService.getCurrentRoute() === RouteEnum.BOOK_CREATE,
        icon: Books
      },
      {
        name: "menu.item.institute.title",
        route: RouteEnum.INSTITUTE,
        onClick: (route?: RouteEnum) => {
          this.navigationService.goto(route!);
          this.storeManager.dispatch(new ConfigSetPanelsMenuAction(false));
          this.storeManager.dispatch(new InstituteResetFormAction());
          this.storeManager.dispatch(new InstituteResetSelectedInstitute());
          this.storeManager.dispatch(new InstituteSetContextAction(undefined));
        },
        selected: this.navigationService.getCurrentRoute() === RouteEnum.INSTITUTE || this.navigationService.getCurrentRoute() === RouteEnum.INSTITUTE_CREATE,
        icon: Institutes
      }
    ];
  }

  getFilteredMenuItems(): Array<IMenuItemProps> {
    let updatedItems: Array<IMenuItemProps> = [];
    let routerEntries: Map<string, any> = new Map(
      this.navigationService.getRouterConfigEntries()
    );

    const items: Array<IMenuItemProps> = this.getMenuItems();
   
    items.forEach((item: IMenuItemProps) => {
      if (this.authManager.isUserAllowed(routerEntries.get(item.route!).router.roles)
      ) {
        updatedItems.push(item);
      }
    });

    return updatedItems;
  }
}
