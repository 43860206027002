// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Institute } from '../entities';
// @ts-ignore
import { InstituteCreateModel } from '../entities';
// @ts-ignore
import { InstituteSearchRequest } from '../entities';
// @ts-ignore
import { InstituteSearchResponse } from '../entities';
// @ts-ignore
import { JsonErrorResponse } from '../entities';
/**
 * InstituteControllerApi - axios parameter creator
 * @export
 */
export const InstituteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create institute
         * @param {InstituteCreateModel} instituteCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstitute: async (instituteCreateModel: InstituteCreateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instituteCreateModel' is not null or undefined
            if (instituteCreateModel === null || instituteCreateModel === undefined) {
                throw new RequiredError('instituteCreateModel','Required parameter instituteCreateModel was null or undefined when calling createInstitute.');
            }
            const localVarPath = `/institute/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof instituteCreateModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instituteCreateModel !== undefined ? instituteCreateModel : {}) : (instituteCreateModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete institute by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInstituteById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteInstituteById.');
            }
            const localVarPath = `/institute/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve institute matching the id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstituteById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstituteById.');
            }
            const localVarPath = `/institute/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * patch an institute matching the id
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInstitute: async (id: number, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchInstitute.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchInstitute.');
            }
            const localVarPath = `/institute/{id}/patch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of institutes matching sorting, filtering and pagination constraints
         * @param {InstituteSearchRequest} instituteSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstitute: async (instituteSearchRequest: InstituteSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instituteSearchRequest' is not null or undefined
            if (instituteSearchRequest === null || instituteSearchRequest === undefined) {
                throw new RequiredError('instituteSearchRequest','Required parameter instituteSearchRequest was null or undefined when calling searchInstitute.');
            }
            const localVarPath = `/institute/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof instituteSearchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instituteSearchRequest !== undefined ? instituteSearchRequest : {}) : (instituteSearchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstituteControllerApi - functional programming interface
 * @export
 */
export const InstituteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * create institute
         * @param {InstituteCreateModel} instituteCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInstitute(instituteCreateModel: InstituteCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Institute>> {
            const localVarAxiosArgs = await InstituteControllerApiAxiosParamCreator(configuration).createInstitute(instituteCreateModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete institute by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInstituteById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InstituteControllerApiAxiosParamCreator(configuration).deleteInstituteById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve institute matching the id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstituteById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Institute>> {
            const localVarAxiosArgs = await InstituteControllerApiAxiosParamCreator(configuration).getInstituteById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * patch an institute matching the id
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInstitute(id: number, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Institute>> {
            const localVarAxiosArgs = await InstituteControllerApiAxiosParamCreator(configuration).patchInstitute(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve a list of institutes matching sorting, filtering and pagination constraints
         * @param {InstituteSearchRequest} instituteSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInstitute(instituteSearchRequest: InstituteSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstituteSearchResponse>> {
            const localVarAxiosArgs = await InstituteControllerApiAxiosParamCreator(configuration).searchInstitute(instituteSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InstituteControllerApi - factory interface
 * @export
 */
export const InstituteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * create institute
         * @param {InstituteCreateModel} instituteCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstitute(instituteCreateModel: InstituteCreateModel, options?: any): AxiosPromise<Institute> {
            return InstituteControllerApiFp(configuration).createInstitute(instituteCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * delete institute by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInstituteById(id: number, options?: any): AxiosPromise<void> {
            return InstituteControllerApiFp(configuration).deleteInstituteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve institute matching the id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstituteById(id: number, options?: any): AxiosPromise<Institute> {
            return InstituteControllerApiFp(configuration).getInstituteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * patch an institute matching the id
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInstitute(id: number, body: object, options?: any): AxiosPromise<Institute> {
            return InstituteControllerApiFp(configuration).patchInstitute(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve a list of institutes matching sorting, filtering and pagination constraints
         * @param {InstituteSearchRequest} instituteSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstitute(instituteSearchRequest: InstituteSearchRequest, options?: any): AxiosPromise<InstituteSearchResponse> {
            return InstituteControllerApiFp(configuration).searchInstitute(instituteSearchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstituteControllerApi - interface
 * @export
 * @interface InstituteControllerApi
 */
export interface InstituteControllerApiInterface {
    /**
     * create institute
     * @param {InstituteCreateModel} instituteCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApiInterface
     */
    createInstitute(instituteCreateModel: InstituteCreateModel, options?: any): AxiosPromise<Institute>;

    /**
     * delete institute by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApiInterface
     */
    deleteInstituteById(id: number, options?: any): AxiosPromise<void>;

    /**
     * retrieve institute matching the id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApiInterface
     */
    getInstituteById(id: number, options?: any): AxiosPromise<Institute>;

    /**
     * patch an institute matching the id
     * @param {number} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApiInterface
     */
    patchInstitute(id: number, body: object, options?: any): AxiosPromise<Institute>;

    /**
     * retrieve a list of institutes matching sorting, filtering and pagination constraints
     * @param {InstituteSearchRequest} instituteSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApiInterface
     */
    searchInstitute(instituteSearchRequest: InstituteSearchRequest, options?: any): AxiosPromise<InstituteSearchResponse>;

}

/**
 * InstituteControllerApi - object-oriented interface
 * @export
 * @class InstituteControllerApi
 * @extends {BaseAPI}
 */
export class InstituteControllerApi extends BaseAPI implements InstituteControllerApiInterface {
    /**
     * create institute
     * @param {InstituteCreateModel} instituteCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApi
     */
    public createInstitute(instituteCreateModel: InstituteCreateModel, options?: any) {
        return InstituteControllerApiFp(this.configuration).createInstitute(instituteCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete institute by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApi
     */
    public deleteInstituteById(id: number, options?: any) {
        return InstituteControllerApiFp(this.configuration).deleteInstituteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve institute matching the id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApi
     */
    public getInstituteById(id: number, options?: any) {
        return InstituteControllerApiFp(this.configuration).getInstituteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * patch an institute matching the id
     * @param {number} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApi
     */
    public patchInstitute(id: number, body: object, options?: any) {
        return InstituteControllerApiFp(this.configuration).patchInstitute(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of institutes matching sorting, filtering and pagination constraints
     * @param {InstituteSearchRequest} instituteSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstituteControllerApi
     */
    public searchInstitute(instituteSearchRequest: InstituteSearchRequest, options?: any) {
        return InstituteControllerApiFp(this.configuration).searchInstitute(instituteSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

}
