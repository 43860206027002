import { StoreManager } from "@hypereact/state";
import { createIntl, createIntlCache, IntlShape } from "react-intl";
import { ConfigSetLanguageAction } from "../../actions/config.actions";
import { LanguageEnum } from "../enums/language.enum";

export class LanguageService {
  private static selectedLanguage: LanguageEnum = LanguageEnum.IT;
  private static fallbackLanguage = LanguageEnum.IT;

  private static languagePacks: any = {};

  private static intl: IntlShape;

  private static storeManager: StoreManager = StoreManager.getInstance();

  static getContext() {
    return this.intl;
  }

  static getBrowserLanguage(): string {
    return navigator.language.split(/[-_]/)[0] || this.fallbackLanguage;
  }

  static async initializeLanguage() {
    try {
      const initialLanguage = LanguageEnum.IT;
      if (Object.values(LanguageEnum).includes(initialLanguage)) {
        this.selectedLanguage = initialLanguage;
      }
    } catch (e) {
      //TODO: raise exception "current browser language is not supported"
    } finally {
      if (this.selectedLanguage == null) {
        this.selectedLanguage = this.fallbackLanguage;
      }
    }

    await this.switchLanguage(this.selectedLanguage);

    this.intl = createIntl(
      {
        locale: this.getSelectedLanguage(),
        messages: this.getLanguagePack(),
      },
      createIntlCache()
    );
  }

  static getSelectedLanguage() {
    return this.selectedLanguage;
  }

  static async switchLanguage(language: LanguageEnum) {
    try {
      if (this.languagePacks[language] == null) {
        this.languagePacks[language] = await this.loadLanguage(language);
      }
      this.selectedLanguage = language;
      this.storeManager.dispatch(new ConfigSetLanguageAction(language));
    } catch (e) {
      //TODO: raise exception "we werent able to change language"
    }
  }

  static async loadLanguage(language: LanguageEnum) {
    return await import(`../../i18n/${language}.json`);
  }

  static getLanguagePack() {
    return this.languagePacks[this.selectedLanguage];
  }
}
