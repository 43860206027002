import {IsNotEmpty, MaxLength} from "class-validator";
import { FileDetails } from "../../../shared/beans/file.details";

export class BookFormData {
    
    @MaxLength(30)
    @IsNotEmpty()
    code!: string;

    @MaxLength(100)
    @IsNotEmpty()
    subject!: string;

    @IsNotEmpty()
    grade!: string;//BookCreateModelGradeEnum;

    @IsNotEmpty()
    level!: string;//BookCreateModelGradeEnum;

    cover?: string;

    bookFileDetails: FileDetails;
}