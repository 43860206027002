import React from "react";
import RefreshIcon from "../../../assets/icons/Menu-Icon/Refresh.svg";

interface RefreshFilterBarItemProps {
    onChange: () => void;
}

export class RefreshFilterBarItem extends React.Component<RefreshFilterBarItemProps> {
    handleOnChange() {
        this.props.onChange();
    }

    render() {
        return (
            <>
                <div
                    className="lowercase clear-style refresh-filter clickable-icons"
                    onClick={() => this.handleOnChange()}
                >
                    <img src={RefreshIcon} />
                </div>
            </>
        );
    }
}
