import Button from "@material-ui/core/Button";
import React from "react";
import { FormattedMessage } from "react-intl"
export class InstituteHeaderCreate extends React.Component {

    render() {
        return (
            <Button
                className="common-button"
                form={"create-institute-form"}
                type='submit'>
                <FormattedMessage id="common.button.save" />
            </Button>
        );
    }
}
