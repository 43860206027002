import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import CountUp from "react-countup";
import { FormattedMessage } from "react-intl";

interface IStatisticViewProps {
  total: number;
}

export class StatisticsDashboardComponent extends React.Component<IStatisticViewProps> {
  constructor(props: IStatisticViewProps) {
    super(props);
  }

  render() {
    return (
      <>
            <Card className="card-root" variant="outlined" elevation={0}>
              <CardContent>
                <Typography className="card-title" align="center" gutterBottom>
                  <FormattedMessage id="statistics.dashboard.card.downloadnumber.title" />
                </Typography>
                <Typography
                  className="card-subtitle"
                  align="center"
                  gutterBottom
                >
                  <CountUp end={this.props.total} duration={1}/>
                </Typography>
              </CardContent>
            </Card>        
      </>
    );
  }
}
