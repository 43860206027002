import Button from "@material-ui/core/Button";
import React from "react";
import { FormattedMessage } from "react-intl"
import { AuthManager } from "../../../managers/auth.manager";
import { StatisticsManager } from "../../../managers/statistics.manager";
import { RouteEnum } from "../../../shared/enums/route.enum";
import { NavigationService } from "../../../shared/services/navigation.service";
export class StatisticsHeader extends React.Component {

    private statisticsManager: StatisticsManager;
    private authManager: AuthManager;
    private navigationService: NavigationService;

    constructor(props) {
        super(props);
        this.statisticsManager = StatisticsManager.getInstance();
        this.authManager = AuthManager.getInstance();
        this.navigationService = NavigationService.getInstance();
    }

    async handleOnClick(): Promise<void> {
        await this.statisticsManager.exportCSV();
    }

    render() {
        if (this.authManager.isAdmin() && this.navigationService.getCurrentRoute() === RouteEnum.STATISTICS) {
            return (
                <Button
                    className="common-button"
                    type='submit'
                    onClick={() => { this.handleOnClick() }}>
                    <FormattedMessage id="statistics.button.export" />
                </Button>
            );
        } else {
            return <></>
        }
    }
}
