// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserSearchFilter
 */
export interface UserSearchFilter {
    /**
     * 
     * @type {string}
     * @memberof UserSearchFilter
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchFilter
     */
    createdAt?: UserSearchFilterCreatedAtEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSearchFilter
     */
    updatedAt?: UserSearchFilterUpdatedAtEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserSearchFilter
     */
    active?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSearchFilterCreatedAtEnum {
    ALL = 'ALL',
    OVERDUE = 'OVERDUE',
    NOT_OVERDUE = 'NOT_OVERDUE',
    NEXT_WEEK = 'NEXT_WEEK',
    NEXT_TWO_WEEKS = 'NEXT_TWO_WEEKS',
    NEXT_MONTH = 'NEXT_MONTH',
    BEYOND_A_MONTH = 'BEYOND_A_MONTH',
    YESTERDAY = 'YESTERDAY',
    TODAY = 'TODAY',
    LAST_WEEK = 'LAST_WEEK',
    LAST_MONTH = 'LAST_MONTH'
}
/**
    * @export
    * @enum {string}
    */
export enum UserSearchFilterUpdatedAtEnum {
    ALL = 'ALL',
    OVERDUE = 'OVERDUE',
    NOT_OVERDUE = 'NOT_OVERDUE',
    NEXT_WEEK = 'NEXT_WEEK',
    NEXT_TWO_WEEKS = 'NEXT_TWO_WEEKS',
    NEXT_MONTH = 'NEXT_MONTH',
    BEYOND_A_MONTH = 'BEYOND_A_MONTH',
    YESTERDAY = 'YESTERDAY',
    TODAY = 'TODAY',
    LAST_WEEK = 'LAST_WEEK',
    LAST_MONTH = 'LAST_MONTH'
}



