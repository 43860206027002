import { Tooltip } from "@material-ui/core";
import React from "react";
import { PropsWithChildren } from "react";

interface ITooltipProps extends PropsWithChildren<any> {
    title: string;
    placement: any;
}


export default function TooltipComponent(props: ITooltipProps) {

    return (
        <Tooltip title={props.title} placement={props.placement} arrow >
            {props.children}
        </Tooltip >
    );
}