import { Grid } from "@material-ui/core";
import { default as React, useContext } from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import { BookGradeEnum, BookLevelEnum } from "../../../api/entities";
import { ActionType, FormManager } from "../../../managers/form.manager";
import { FileDetails } from "../../../shared/beans/file.details";
import { FileTypeConfig } from "../../../shared/beans/filetype.config";
import SelectInput, { ISelectItem } from "../../../shared/components/SelectInput/SelectInput";
import TextInput from "../../../shared/components/TextInput";
import { fileConfig } from "../../../shared/components/Upload/constant/file.config.constant";
import { Emitter } from "../../../shared/components/Upload/event.emitter.component";
import { FileTypesEnum, UploadBookComponent } from "../../../shared/components/Upload/upload.book.component";
import { ContextEnum } from "../../../shared/enums/context.enum";
import { BookState } from "../../../shared/states/book.state";
import { FormHelperUtil } from "../../../shared/utils/form.helper.util";
import { BookFormData } from "../beans/book.form.data";

interface IBookDetailsFormProps {
    formValues?: BookState;
    fileConfig?: FileTypeConfig;
    emitter: Emitter;
    onActions: (actionType: ActionType, payload?: any) => void
    onChange: (event: any) => void
    onBookUploaded?: (fileDetails: File) => void
    bookFileError: boolean
}

const formFields: Pick<Record<keyof BookFormData, string>, 'code' | "subject" | "grade" | "level"> = {
    code: 'code',
    subject: 'subject',
    grade: 'grade',
    level: 'level'
}

export default function BookDetailsForm(props: IBookDetailsFormProps) {
    const { formValues, onActions, onChange, onBookUploaded } = props;
    const { errors, control, handleSubmit, setValue } = FormManager.getInstance().buildFormProxy<BookFormData>(BookFormData, onActions);

    if (formValues?.createUpdate?.context === ContextEnum.UPDATE) {
        setValue(formFields.code, formValues.item.code)
        setValue(formFields.subject, formValues.item.title)
        setValue(formFields.grade, formValues.item.grade)
        setValue(formFields.level, formValues.item.level)
    }
    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator("book", intl);

    const onFileUploaded = (fileDetails: FileDetails, fileTypeEnum: FileTypesEnum): void =>  {   
        if(FileTypesEnum.Cover === fileTypeEnum) {
            setValue('cover', fileDetails.data ? fileDetails.data : null);
        } else {
            onBookUploaded(fileDetails.file);
        }
    }

    const bookGradeItems = Object.keys(BookGradeEnum).map((key: string): ISelectItem => {
        return {
            name: <FormattedMessage id={`book.grade.${BookGradeEnum[key]}`} />,
            value: BookGradeEnum[key]
        }
    })

    const bookLevelItems = Object.keys(BookLevelEnum).map((key: string): ISelectItem => {
        return {
            name: <FormattedMessage id={`book.level.${BookLevelEnum[key]}`} />,
            value: BookLevelEnum[key]
        }
    })

    return (
        <form id='book-form' onSubmit={handleSubmit} className="textInputStyle">
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <TextInput
                        name={formFields.code}
                        value={formValues?.createUpdate.context === ContextEnum.UPDATE ? (formValues?.createUpdate.details.code || formValues?.item.code)  : undefined}
                        label={
                            <FormattedMessage id="form.book.details.code.label" />
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        control={control}
                        error={!!errors.code}
                        onChange={onChange}
                        helperText={errors.code && getMessageByFieldAndError(formFields.code, errors)}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextInput
                        name={formFields.subject}
                        value={formValues?.createUpdate.context === ContextEnum.UPDATE ? (formValues?.createUpdate.details.subject || formValues?.item.title)  : undefined}
                        label={
                            <FormattedMessage id="form.book.details.subject.label" />
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        control={control}
                        error={!!errors.subject}
                        onChange={onChange}
                        helperText={errors.subject && getMessageByFieldAndError(formFields.subject, errors)}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SelectInput
                        value={formValues?.createUpdate.context === ContextEnum.UPDATE ? (formValues?.createUpdate.details.grade || formValues?.item.grade) : undefined}
                        name={formFields.grade}
                        label={
                            <FormattedMessage id="form.book.details.grade.label" />
                        }
                        variant="outlined"
                        control={control}
                        error={!!errors.grade}
                        onChange={onChange}
                        items={bookGradeItems}
                        helperText={errors.grade && getMessageByFieldAndError(formFields.grade, errors)}
                    />
                </Grid>
                        
                <Grid item xs={12} lg={4}>
                    <SelectInput
                        value={formValues?.createUpdate.context === ContextEnum.UPDATE ? (formValues?.createUpdate.details.level || formValues?.item.level) : undefined}
                        name={formFields.level}
                        label={
                            <FormattedMessage id="form.book.details.level.label" />
                        }
                        variant="outlined"
                        control={control}
                        error={!!errors.level}
                        onChange={onChange}
                        items={bookLevelItems}
                        helperText={errors.level && getMessageByFieldAndError(formFields.level, errors)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UploadBookComponent
                        onChange={(fileDetails: FileDetails, fileTypeEnum: FileTypesEnum) => onFileUploaded(fileDetails, fileTypeEnum)}
                        fileConfig={fileConfig}
                        bookFileError={props.bookFileError}
                        bookState={formValues}
                    />
                </Grid>
            </Grid>
        </form>
    )
}