import React, { useContext } from "react";
import { LoginFormData } from "../beans/login.form.data";
import { FormManager, ActionType } from "../../../managers/form.manager";
import { FormattedMessage, IntlContext } from "react-intl";
import { FormHelperUtil } from "../../../shared/utils/form.helper.util";
import TextInput from "../../../shared/components/TextInput";
import { ValidationService } from "../../../shared/services/validation.service";

interface LoginForm {
    onActions: (actionType: ActionType, payload?: any) => void
}

const formFields: Pick<Record<keyof LoginFormData, string>, 'email' | 'password'> = {
    email: 'email',
    password: 'password'
}

const locale: string = 'login.form.input';

export default function LoginForm(props: LoginForm) {
    const { onActions } = props;
    const { errors, control, handleSubmit, schemaObject } = FormManager.getInstance().buildFormProxy<LoginFormData>(LoginFormData, onActions);

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator("login", intl);
   
    return (
        <form id='login-form' onSubmit={handleSubmit} className="textInputStyle">
            <div className="lss-input-container ">
                <TextInput
                    inputProps={{
                        maxLength: ValidationService.extractFromSchemaObject(
                            schemaObject,
                            `email`
                        ).maxLength,
                    }}
                    name={formFields.email}
                    label={<FormattedMessage id={`${locale}.email.label`} />}
                    placeholder={intl.formatMessage({
                        id: "form.login.manual.email.placeholder",
                    })}
                    control={control}
                    error={!!errors.email}
                    helperText={errors.email && getMessageByFieldAndError(formFields.email, errors)}
                />
            </div>
            <div className="lss-input-container">
                <TextInput
                    inputProps={{
                        maxLength: ValidationService.extractFromSchemaObject(
                            schemaObject,
                            `password`
                        ).maxLength,
                    }}
                    name={formFields.password}
                    type="password"
                    label={<FormattedMessage id={`${locale}.password.label`} />}
                    placeholder={intl.formatMessage({
                        id: "form.login.manual.password.placeholder",
                    })}
                    control={control}
                    error={!!errors.password}
                    helperText={errors.password && getMessageByFieldAndError(formFields.password, errors)}
                />
            </div>
        </form>
    );
}
