import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Institute } from "../../../api/entities";
import {
  DateFormatter,
  IDateFormatter
} from "../../../shared/utils/dateFormatter.util";

interface IInstituteItemProps {
  institute: Institute;
  onClick?: (id: number) => void;
  selected?: boolean;
}

export class InstituteItem extends React.Component<IInstituteItemProps> {
  private dateFormatter: IDateFormatter;
  constructor(props: IInstituteItemProps) {
    super(props);
    this.dateFormatter = DateFormatter.getInstance();
  }

  handleOnClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const { institute, onClick } = this.props;
    onClick && onClick(institute.id!);
  }

  render() {
    const { institute, selected } = this.props;

    return (
      <div
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          this.handleOnClick(event)
        }
      >
        <Paper
          className={`institute-item ${selected ? "selected-item-card" : ""}`}
          elevation={0}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            className="card-item "
          >
            <Grid container className="card-item column1">
              <Grid item className="flex-institute-header">
                <Typography variant="h6" className="header-institute">
                  {institute.code}
                </Typography>
                <Typography variant="h6" className="summary-institute">
                  {institute.title}
                </Typography>
              </Grid>
              <Grid container className="mt institute-container">

                <Grid
                  className="mr-20 mb-7"
                >
                  <Typography
                    variant="body2"
                    className="institute-description light-gray"
                    noWrap
                  >
                   <FormattedMessage id={`institute.grade.${institute.grade}`} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}
