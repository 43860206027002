import { IReduceableAction, ReduxAction } from "@hypereact/state";
import { Role } from "../api/entities";
import { Book } from "../api/entities/book";
import { ReduxSlices } from "../config/enums/redux.slices";
import { ContextEnum } from "../shared/enums/context.enum";
import { Order } from "../shared/interfaces/list/order.interface";
import { BookState, initialBookState } from "../shared/states/book.state";
import { InstituteStatisticsState } from "../shared/states/statistics.state";

enum BookActions {
    SetItems = "BOOK_SET_ITEMS",
    SetBookFileError = "BOOK_SET_BOOK_FILE_ERROR",
    SetItem = "BOOK_SET_ITEM",
    SetPagination = "BOOK_SET_PAGINATION",
    SetBookSearchQuery = "BOOK_SET_BOOK_SEARCH_QUERY",
    SetBookSearchGrade = "BOOK_SET_BOOK_SEARCH_GRADE",
    SetBookSearchOrder = "BOOK_SET_BOOK_SEARCH_ORDER",
    AddDocument = "BOO_ADD_DOCUMENT",
    ResetItem = "BOOK_RESET_ITEM",
    SetContext = "BOOK_SET_CONTEXT",
    setForm = "BOOK_SET_FORM",
    ResetForm = "BOOK_RESET_FORM",
    SetQuery = "BOOK_SET_QUERY",
    SetOrder = "BOOK_SET_ORDER",
    SetRecentlyUpdated = "BOOK_SET_RECENTLY_UPDATED",
    SetStatus = "BOOK_SET_STATUS",
    SetShowClearFilter = "BOOK_SET_SHOW_CLEAR_FILTER",
    ResetFilter = "BOOK_RESET_FILTER",
    ResetQuery = "BOOK_RESET_QUERY",

}

@ReduxAction(BookActions.SetBookFileError, ReduxSlices.Book)
export class BookSetFileErrorAction {
    constructor(public bookFileError: boolean) { }

    reduce(bookState: BookState) {
        bookState.bookFileError = this.bookFileError;
        return bookState;
    }
}


@ReduxAction(BookActions.SetItems, ReduxSlices.Book)
export class BookSetItemsAction {
    constructor(public books: Array<Book>, public total?: number,) { }

    reduce(bookState: BookState) {
        bookState.items = this.books;
        bookState.pagination.total = this.total || 0;
        return bookState;
    }
}

@ReduxAction(BookActions.SetItem, ReduxSlices.Book)
export class BookSetItemAction {
    constructor(public book: Book) { }

    reduce(bookState: BookState) {
        bookState.item = this.book;
        return bookState;
    }
}

@ReduxAction(BookActions.SetPagination, ReduxSlices.Book)
export class BookSetPaginationAction
    implements IReduceableAction<BookState>
{
    constructor(public page: number, public size: number) { }

    reduce(book: BookState) {
        book.pagination.page = this.page;
        book.pagination.size = this.size;
        return book;
    }
}

@ReduxAction(BookActions.SetBookSearchQuery, ReduxSlices.Book)
export class BookSetBookSearchQuery {
    constructor(public searchQuery: string) { }
    reduce(bookState: BookState) {
        bookState.query = this.searchQuery;
        if (
            bookState.query != initialBookState.query
        ) {
            bookState.showClearFilter = true;
        }
        return bookState;
    }
}

@ReduxAction(BookActions.SetBookSearchOrder, ReduxSlices.Book)
export class BookSetBookSearchOrder {
    constructor(public order: Order) { }

    reduce(bookState: BookState) {
        bookState.order = this.order;
        if (
            bookState.order != initialBookState.order
        ) {
            bookState.showClearFilter = true;
        }
        bookState.pagination.page = 0;
        return bookState;
    }
}

@ReduxAction(BookActions.SetShowClearFilter, ReduxSlices.Book)
export class BookSetShowClearFilter {
    constructor(public showClearFilter: boolean) { }

    reduce(bookState: BookState) {
        bookState.showClearFilter = this.showClearFilter;
        return bookState;
    }
}

@ReduxAction(
    BookActions.SetBookSearchGrade,
    ReduxSlices.Book
  )
  export class BookStatisticsSearchGrade
    implements IReduceableAction<BookState>
  {
    constructor(public grades: string[]) {}
  
    reduce(bookState: BookState) {
        bookState.filters.grade = this.grades.join(',');
      
      if (bookState.filters.grade != initialBookState.filters.grade) {
        bookState.showClearFilter = true;
      }
      bookState.pagination.page = 0;
      return bookState;
    }
  }

@ReduxAction(BookActions.ResetFilter, ReduxSlices.Book)
export class BookResetFilterAction {
    constructor() { }
    reduce(bookState: BookState) {
        bookState.filters = initialBookState.filters;
        bookState.order = initialBookState.order;
        bookState.pagination.page = 0;
        return bookState;
    }
}

@ReduxAction(BookActions.ResetQuery, ReduxSlices.Book)
export class BookResetQueryAction {
    constructor() { }

    reduce(bookState: BookState) {
        bookState.query = undefined;
        return bookState;
    }
}

@ReduxAction(BookActions.SetContext, ReduxSlices.Book)
export class BookSetContextAction {
    constructor(public context: ContextEnum) { }

    reduce(bookState: BookState) {
        bookState.createUpdate.context = this.context;
        return bookState;
    }
}

@ReduxAction(BookActions.ResetForm, ReduxSlices.Book)
export class BookResetFormAction {
    constructor(public roles?: Array<Role>) { }

    reduce(bookState: BookState) {
        bookState.createUpdate = {
            ...initialBookState.createUpdate,
            context: bookState.createUpdate.context,
        }
        return bookState;
    }
}

@ReduxAction(BookActions.ResetItem, ReduxSlices.Book)
export class BookResetItemAction {
    constructor(public roles?: Array<Role>) { }

    reduce(bookState: BookState) {
        bookState.item = {
            ...initialBookState.item
        }
        return bookState;
    }
}

@ReduxAction(BookActions.setForm, ReduxSlices.Book)
export class BookSetFormAction {
    constructor(public name: string, public value?: any) { }

    reduce(book: BookState) {
        book.createUpdate.details = {
            ...book.createUpdate.details,
            [this.name]: this.value,
        };

        return book;
    }
}