import { ReduxConnect, StoreManager } from "@hypereact/state";
import { Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { BookResetFormAction, BookResetItemAction, BookSetContextAction } from "../../../actions/book.actions";
import { Book } from "../../../api/entities/book";
import { ReduxSlices } from "../../../config/enums/redux.slices";
import { ReduxState } from "../../../config/redux.config";
import { AuthManager } from "../../../managers/auth.manager";
import { ContextEnum } from "../../../shared/enums/context.enum";
import { RouteEnum } from "../../../shared/enums/route.enum";
import { NavigationService } from "../../../shared/services/navigation.service";
import { BookCreateUpdate } from "../../../shared/states/book.state";
import { UserState } from "../../../shared/states/user.state";

interface IBookHeaderListProps {
    book: Book;
    bookCreateUpdate: BookCreateUpdate;
}

@ReduxConnect((state: ReduxState) => {
    return {
        book: state.book.item,
        bookCreateUpdate: state.book.createUpdate
    };
})

export class BookHeaderList extends React.Component<IBookHeaderListProps> {
    private navigationService: NavigationService;
    private storeManager: StoreManager;
    private authManager: AuthManager;

    constructor(props: IBookHeaderListProps) {
        super(props);
        this.navigationService = NavigationService.getInstance();
        this.storeManager = StoreManager.getInstance();
        this.authManager = AuthManager.getInstance();
    }

    async handleOnCreateClick(e: any) {
        await this.storeManager.dispatch(new BookSetContextAction(ContextEnum.CREATE))
        const userState: UserState = this.storeManager.getState(ReduxSlices.User)
        await this.storeManager.dispatch(new BookResetFormAction(userState.personalData.roles))
        await this.storeManager.dispatch(new BookResetItemAction(userState.personalData.roles))
        this.navigationService.goto(RouteEnum.BOOK_CREATE);
        
    }

    render() {
        if (this.authManager.isAdmin() && this.navigationService.getCurrentRoute() === RouteEnum.BOOK) {
            return (
                <Button className="common-button" onClick={(e: any) => this.handleOnCreateClick(e)}>
                    <FormattedMessage id="common.button.new.book" />
                </Button>
            )
        } else {
            return <></>
        }
    }

}