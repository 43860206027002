import { ReduxConnect, StoreManager } from "@hypereact/state";
import { Grid } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { BookResetFormAction, BookSetContextAction } from "../../../actions/book.actions";
import { Book } from "../../../api/entities/book";
import { ReduxState } from "../../../config/redux.config";
import { AuthManager } from "../../../managers/auth.manager";
import { BookManager } from "../../../managers/book.manager";
import { ConfigManager } from "../../../managers/config.manager";
import { FilterBar } from "../../../shared/components/FilterBar/filterbar.component";
import { PaginationComponent } from "../../../shared/components/Pagination/pagination.component";
import { ConfigTypeEnum } from "../../../shared/enums/config.type.enum";
import { ContextEnum } from "../../../shared/enums/context.enum";
import { BookState } from "../../../shared/states/book.state";
import { FilterBarUtil } from "../../../shared/utils/filterBar.util";
import { FilterBarItems } from "../../statistics/config/filterbar.config";
import { BookItem } from "../components/book.item.component";
import { BookUpdateContainer } from "../components/book.update.container";

interface IBookViewProps {
    book: BookState;
}

@ReduxConnect((state: ReduxState) => {
    return {
        book: state.book,
    };
})

export class BookView extends React.Component<IBookViewProps> {
    private authManager: AuthManager;
    private configManager: ConfigManager;
    private bookManager: BookManager;
    private storeManager: StoreManager;
    private KEYWORD_MIN_CHAR: number = 3;
    constructor(props: IBookViewProps) {
        super(props);
        this.state = {
            selected: true
        };
        this.authManager = AuthManager.getInstance();
        this.bookManager = BookManager.getInstance();
        this.storeManager = StoreManager.getInstance();
        this.configManager = ConfigManager.getInstance();
    }

    async componentDidMount() {
        await this.bookManager.search();
    }

    async onPaginationChange(page: number, size: number) {
        await this.bookManager.setPagination(page, size);
    }

    async handleOnClickItem(id: number) {
        if (this.authManager.isAdmin()) {
            await this.storeManager.dispatch(new BookResetFormAction())
            await this.bookManager.getById(id)
            await this.storeManager.dispatch(new BookSetContextAction(ContextEnum.UPDATE))
        }
    }

    async handleOnChange(name: string, value: any) {
        this.bookManager.resetPagination();
        await this.bookManager.onFilterChange(name, value);
    }

    handleOnClickItemButton(id: number, e: any) {
        this.bookManager.download(id);
        e.stopPropagation();
    }

    async handleReset(makeSearch: boolean = true) {
        this.bookManager.fullReset(makeSearch);
    }

    render() {
        const { book } = this.props;
        const filterBarItems = FilterBarUtil.filter(
            this.configManager.getConfigByType(
                ConfigTypeEnum.BOOKS,
            ),
            FilterBarItems.getFilterBarItems()
        );
        return (
            <>
                <Grid>
                    <div className="">
                        <FilterBar
                            items={filterBarItems}
                            config={this.bookManager.getConfig(
                                filterBarItems
                            )}
                            onChange={(name: string, value: any) => { this.handleOnChange(name, value) }}
                            onClearFilters={() => { this.bookManager.fullReset() }}
                            onRefreshFilters={() => { this.bookManager.search() }}
                            showClearFilter={book?.showClearFilter}
                        />
                    </div>
                </Grid>
                {book.items && book.items.length > 0 ? (
                    <>
                        <Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={3}
                            >
                                <Grid item xs={12} sm={12} md={book.createUpdate.context === ContextEnum.UPDATE && this.authManager.isAdmin() ? 6 : 12}>
                                    {book.items.map((item: Book, index: number) => (
                                        <div className="mb-16" key={index}>
                                            <BookItem
                                                book={item}
                                                selected={item.id === book.item?.id}
                                                onClickItem={(id: number) => this.handleOnClickItem(id)}
                                                onClickButton={(id: number, e: any) => this.handleOnClickItemButton(id, e)}
                                            />
                                        </div>
                                    ))}
                                </Grid>
                                {book.createUpdate.context === ContextEnum.UPDATE && this.authManager.isAdmin() && (
                                    <Grid item xs={12} sm={12} md={6}>
                                        <BookUpdateContainer
                                            bookState={book}
                                            context={book.createUpdate.context}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <div className="align-center">
                            <PaginationComponent
                                onChange={(page: number, size: number) =>
                                    this.onPaginationChange(page, size)
                                }
                                page={book.pagination.page!}
                                total={book.pagination.total!}
                                size={book.pagination.size!}
                                sizes={[5, 10, 15, 20]}
                            />
                        </div>
                    </>
                ) : (
                    <h4>
                        <FormattedMessage id="book.list.empty" />
                    </h4>
                )}
            </>
        )
    }


    componentWillUnmount() {
        this.handleReset(false);
    }
}
