import React from "react";
import { Redirect } from "react-router-dom";
import { AuthManager } from "../../../managers/auth.manager";
import { RouteEnum } from "../../../shared/enums/route.enum";

export class HomeView extends React.Component {
  private authManager: AuthManager;

  constructor(props) {
    super(props);
    this.authManager = AuthManager.getInstance();
  }
  
  render() {
    if (this.authManager.isAdmin()) {
      return <Redirect to={RouteEnum.STATISTICS} />;
    } else {
      return <Redirect to={RouteEnum.BOOK} />;
    }
  }
}
