import { ReduxConnect, StoreManager } from "@hypereact/state";
import { AppBar, Box, Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  BookSetContextAction,
  BookSetFileErrorAction,
  BookSetFormAction,
} from "../../../actions/book.actions";
import { Book, BookGradeEnum, BookLevelEnum } from "../../../api/entities";
import { ReduxState } from "../../../config/redux.config";
import { BookManager } from "../../../managers/book.manager";
import { ActionType } from "../../../managers/form.manager";
import { NotificationFactory } from "../../../managers/notification.factory";
import { PopupManager } from "../../../managers/popup.manager";
import { ToastManager } from "../../../managers/toast.manager";
import { PopupType } from "../../../shared/components/Popup/popup.item";
import { Emitter } from "../../../shared/components/Upload/event.emitter.component";
import { ContextEnum } from "../../../shared/enums/context.enum";
import { SeverityEnum } from "../../../shared/enums/severity.enum";
import { BookState } from "../../../shared/states/book.state";
import { ConfigState } from "../../../shared/states/config.state";
import { BookFormData } from "../beans/book.form.data";
import BookFormContainer from "../components/book.form.container";

interface IBookViewProps {
  bookState?: BookState;
  config?: ConfigState;
  context?: ContextEnum;
}

@ReduxConnect((state: ReduxState) => {
  return {
    book: state.book,
  };
})
export class BookUpdateContainer extends React.Component<IBookViewProps> {
  private eventEmitter: Emitter;
  private storeManager: StoreManager;
  private popupManager: PopupManager;
  private bookManager: BookManager;
  private bookFile: File;
  private notificationFactory: NotificationFactory;
  constructor(props: IBookViewProps) {
    super(props);
    this.state = {
      selected: true,
    };
    this.eventEmitter = new Emitter();
    this.storeManager = StoreManager.getInstance();
    this.bookManager = BookManager.getInstance();
    this.popupManager = PopupManager.getInstance();
    this.notificationFactory = NotificationFactory.getInstance();
    this.onActions = this.onActions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnDeleteItem = this.handleOnDeleteItem.bind(this);
    this.onBookUploaded = this.onBookUploaded.bind(this);
  }

  private async handleChange(
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) {
    this.storeManager.dispatch(
      new BookSetFormAction(event.target.name, event.target.value)
    );
  }

  private async handleSubmit(formData: BookFormData) {
    const { bookState } = this.props;
    const bookCreateModel: Book = {
      ...bookState.item,
      id: bookState.item.id,
      code: bookState.createUpdate.details.code
        ? bookState.createUpdate.details.code
        : bookState.item.code
        ? bookState.item.code
        : undefined,
      title: bookState.createUpdate.details.subject
        ? bookState.createUpdate.details.subject
        : bookState.item.title
        ? bookState.item.title
        : undefined,
      grade: (bookState.createUpdate.details.grade
        ? bookState.createUpdate.details.grade
        : bookState.item.grade
        ? bookState.item.grade
        : undefined) as BookGradeEnum,
      cover: formData.cover,
      level: (bookState.createUpdate.details.level
        ? bookState.createUpdate.details.level
        : bookState.item.level
        ? bookState.item.level
        : undefined) as BookLevelEnum,
    };
    try {
      const response = await this.bookManager.patch(
        bookState.item,
        bookCreateModel
      );
      if (this.bookFile) {
        await this.bookManager.upload(response.id, this.bookFile);
        this.bookFile = undefined;
      }

      this.storeManager.dispatch(new BookSetContextAction(undefined));
      this.bookManager.search();
      this.notificationFactory.showToast({
        message: `book.update.toast.success.message`,
        variant: SeverityEnum.SUCCESS,
      });
    } catch (error) {
        NotificationFactory.getInstance().handleError(error);
    }
  }

  private async handleConfirm() {
    const { bookState } = this.props;
    await this.bookManager.delete(bookState?.item.id);
    this.popupManager.hide();
    ToastManager.getInstance().show({
      message: "Eliminato con successo",
      variant: SeverityEnum.SUCCESS,
    });
    this.storeManager.dispatch(new BookSetContextAction(undefined));
    this.bookManager.search();
  }

  private async handleOnDeleteItem() {
    this.popupManager.show({
      type: PopupType.Info,
      title: "book.popup.delete.title",
      description: "book.popup.delete.description",
      maxWidth: "xs",
      stylingProp: "popup-display",
      ctas: {
        primary: {
          label: "popup.button.yes",
          hide: false,
          cta: () => this.handleConfirm(),
        },
        secondary: {
          label: "popup.button.no",
          hide: true,
        },
      },
    });
  }

  onActions(actionType: ActionType, payload: any): void {
    switch (actionType) {
      case ActionType.SUBMIT: {
        this.handleSubmit(payload as BookFormData);
        break;
      }
      case ActionType.CHANGE: {
        break;
      }
      case ActionType.BLUR: {
        break;
      }
      default:
        break;
    }
  }

  onBookUploaded(file: File): void {
    this.bookFile = file;
    this.storeManager.dispatch(new BookSetFileErrorAction(false));
  }

  render() {
    const { bookState } = this.props;
    return (
      <>
        <Box
          component="div"
          padding="6px 16px 8px 16px"
          className="update-container-background"
        >
          <AppBar
            position="static"
            elevation={0}
            className="update-container-background"
          >
            <div className="common-button-delete-div ">
              <Button
                className="common-button"
                onClick={() => this.handleOnDeleteItem()}
              >
                <FormattedMessage id="common.button.delete" />
              </Button>

              <IconButton
                color="inherit"
                aria-label="close"
                form={"book-form"}
                type="submit"
                className="no-padding"
              >
                <CloseIcon htmlColor="#14438B" />
              </IconButton>
            </div>
          </AppBar>
        </Box>
        <Box
          component="div"
          padding="8px 16px"
          className="update-container-background"
        >
          <AppBar
            position="static"
            elevation={0}
            className="update-container-background"
          >
            <div className="book-code-update-container-div">
              <Typography className="book-code-update-container">
                {bookState.item.code}
              </Typography>
            </div>
          </AppBar>
        </Box>
        <Box
          component="div"
          padding="6px 16px"
          className="update-container-background"
        >
          <BookFormContainer
            emitter={this.eventEmitter}
            formValues={bookState}
            onActions={this.onActions}
            onChange={this.handleChange}
            bookFileError={bookState.bookFileError}
            onBookUploaded={this.onBookUploaded}
          />
        </Box>
      </>
    );
  }
}
