// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookStatsSearchRequest } from '../entities';
// @ts-ignore
import { BookStatsSearchResponse } from '../entities';
// @ts-ignore
import { InstituteStatsSearchRequest } from '../entities';
// @ts-ignore
import { InstituteStatsSearchResponse } from '../entities';
// @ts-ignore
import { JsonErrorResponse } from '../entities';
// @ts-ignore
import { TotalStatsResponse } from '../entities';
/**
 * StatsControllerApi - axios parameter creator
 * @export
 */
export const StatsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * export a csv including the stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/export`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve all the stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotal: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/total`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of stats matching sorting, filtering and pagination constraints
         * @param {BookStatsSearchRequest} bookStatsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBooks: async (bookStatsSearchRequest: BookStatsSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookStatsSearchRequest' is not null or undefined
            if (bookStatsSearchRequest === null || bookStatsSearchRequest === undefined) {
                throw new RequiredError('bookStatsSearchRequest','Required parameter bookStatsSearchRequest was null or undefined when calling searchBooks.');
            }
            const localVarPath = `/stats/search/books`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bookStatsSearchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bookStatsSearchRequest !== undefined ? bookStatsSearchRequest : {}) : (bookStatsSearchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of stats matching sorting, filtering and pagination constraints
         * @param {InstituteStatsSearchRequest} instituteStatsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstitutes: async (instituteStatsSearchRequest: InstituteStatsSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instituteStatsSearchRequest' is not null or undefined
            if (instituteStatsSearchRequest === null || instituteStatsSearchRequest === undefined) {
                throw new RequiredError('instituteStatsSearchRequest','Required parameter instituteStatsSearchRequest was null or undefined when calling searchInstitutes.');
            }
            const localVarPath = `/stats/search/institutes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof instituteStatsSearchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instituteStatsSearchRequest !== undefined ? instituteStatsSearchRequest : {}) : (instituteStatsSearchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsControllerApi - functional programming interface
 * @export
 */
export const StatsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * export a csv including the stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCsv(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await StatsControllerApiAxiosParamCreator(configuration).exportCsv(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve all the stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotal(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalStatsResponse>> {
            const localVarAxiosArgs = await StatsControllerApiAxiosParamCreator(configuration).getTotal(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve a list of stats matching sorting, filtering and pagination constraints
         * @param {BookStatsSearchRequest} bookStatsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBooks(bookStatsSearchRequest: BookStatsSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookStatsSearchResponse>> {
            const localVarAxiosArgs = await StatsControllerApiAxiosParamCreator(configuration).searchBooks(bookStatsSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve a list of stats matching sorting, filtering and pagination constraints
         * @param {InstituteStatsSearchRequest} instituteStatsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInstitutes(instituteStatsSearchRequest: InstituteStatsSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstituteStatsSearchResponse>> {
            const localVarAxiosArgs = await StatsControllerApiAxiosParamCreator(configuration).searchInstitutes(instituteStatsSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatsControllerApi - factory interface
 * @export
 */
export const StatsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * export a csv including the stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv(options?: any): AxiosPromise<object> {
            return StatsControllerApiFp(configuration).exportCsv(options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve all the stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotal(options?: any): AxiosPromise<TotalStatsResponse> {
            return StatsControllerApiFp(configuration).getTotal(options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve a list of stats matching sorting, filtering and pagination constraints
         * @param {BookStatsSearchRequest} bookStatsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBooks(bookStatsSearchRequest: BookStatsSearchRequest, options?: any): AxiosPromise<BookStatsSearchResponse> {
            return StatsControllerApiFp(configuration).searchBooks(bookStatsSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve a list of stats matching sorting, filtering and pagination constraints
         * @param {InstituteStatsSearchRequest} instituteStatsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstitutes(instituteStatsSearchRequest: InstituteStatsSearchRequest, options?: any): AxiosPromise<InstituteStatsSearchResponse> {
            return StatsControllerApiFp(configuration).searchInstitutes(instituteStatsSearchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsControllerApi - interface
 * @export
 * @interface StatsControllerApi
 */
export interface StatsControllerApiInterface {
    /**
     * export a csv including the stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApiInterface
     */
    exportCsv(options?: any): AxiosPromise<object>;

    /**
     * retrieve all the stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApiInterface
     */
    getTotal(options?: any): AxiosPromise<TotalStatsResponse>;

    /**
     * retrieve a list of stats matching sorting, filtering and pagination constraints
     * @param {BookStatsSearchRequest} bookStatsSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApiInterface
     */
    searchBooks(bookStatsSearchRequest: BookStatsSearchRequest, options?: any): AxiosPromise<BookStatsSearchResponse>;

    /**
     * retrieve a list of stats matching sorting, filtering and pagination constraints
     * @param {InstituteStatsSearchRequest} instituteStatsSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApiInterface
     */
    searchInstitutes(instituteStatsSearchRequest: InstituteStatsSearchRequest, options?: any): AxiosPromise<InstituteStatsSearchResponse>;

}

/**
 * StatsControllerApi - object-oriented interface
 * @export
 * @class StatsControllerApi
 * @extends {BaseAPI}
 */
export class StatsControllerApi extends BaseAPI implements StatsControllerApiInterface {
    /**
     * export a csv including the stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApi
     */
    public exportCsv(options?: any) {
        return StatsControllerApiFp(this.configuration).exportCsv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve all the stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApi
     */
    public getTotal(options?: any) {
        return StatsControllerApiFp(this.configuration).getTotal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of stats matching sorting, filtering and pagination constraints
     * @param {BookStatsSearchRequest} bookStatsSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApi
     */
    public searchBooks(bookStatsSearchRequest: BookStatsSearchRequest, options?: any) {
        return StatsControllerApiFp(this.configuration).searchBooks(bookStatsSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of stats matching sorting, filtering and pagination constraints
     * @param {InstituteStatsSearchRequest} instituteStatsSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsControllerApi
     */
    public searchInstitutes(instituteStatsSearchRequest: InstituteStatsSearchRequest, options?: any) {
        return StatsControllerApiFp(this.configuration).searchInstitutes(instituteStatsSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

}
