import { createTheme } from '@material-ui/core/styles';

export const themeLight = createTheme({
    breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 }
    },
    direction: "ltr",
    zIndex: {
        modal: 1300,
        snackbar: 1400,
        drawer: 1200,
        appBar: 1100,
        mobileStepper: 1000,
        tooltip: 1500
    },
    palette: {
        primary: {
            main: "#335eea",
            light: "rgb(79, 95, 146)",
            dark: "#1746e0",
            contrastText: "#fff"
        },
        secondary: {
            main: "#506690",
            light: "rgb(246, 124, 89)",
            dark: "#425577",
            contrastText: "#ffffff"
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.67)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        },
        tonalOffset: 0.2,
        background: { "paper": "#fff", "default": "#fafafa" },
        contrastThreshold: 3,
        divider: "rgba(0, 0, 0, 0.12)",
        common: { "black": "#000", "white": "#fff" },
        type: "light",
        action: {
            hoverOpacity: 0.08,
            hover: "rgba(0, 0, 0, 0.08)",
            selected: "rgba(0, 0, 0, 0.14)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabled: "rgba(0, 0, 0, 0.26)",
            active: "rgba(0, 0, 0, 0.54)"
        }
    }
});

export const themeDark = createTheme({
    breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 }
    },
    direction: "ltr",
    zIndex: {
        modal: 1300,
        snackbar: 1400,
        drawer: 1200,
        appBar: 1100,
        mobileStepper: 1000,
        tooltip: 1500
    },
    palette: {
        primary: {
            main: "#335eea",
            light: "rgb(79, 95, 146)",
            dark: "#1746e0",
            contrastText: "#fff"
        },
        secondary: {
            main: "#506690",
            light: "rgb(246, 124, 89)",
            dark: "#425577",
            contrastText: "#ffffff"
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(255, 255, 255, 1)",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
            hint: "rgba(255, 255, 255, 0.5)"
        },
        tonalOffset: 0.2,
        background: { "paper": "#424242", "default": "#303030" },
        contrastThreshold: 3,
        divider: "rgba(255, 255, 255, 0.12)",
        common: { "black": "#000", "white": "#fff" },
        type: "dark",
        action: {
            hoverOpacity: 0.08,
            hover: "rgba(255, 255, 255, 0.08)",
            selected: "rgba(255, 255, 255, 0.16)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
            disabled: "rgba(255, 255, 255, 0.3)",
            active: "rgba(255, 255, 255, 1)"
        }
    }
});