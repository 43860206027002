import { ReduxAction } from "@hypereact/state";
import { User } from "../api/entities";
import { ReduxSlices } from "../config/enums/redux.slices";
import { UserState } from "../shared/states/user.state";

enum UserActions {
  setItems = "USER_SET_ITEMS",
  setCredential ="CREDENTIAL_SET"
}

@ReduxAction(UserActions.setItems, ReduxSlices.User)
export class UserSetItemsAction {
  constructor(public users: Array<User>) {}

  reduce(user: UserState) {
    user.items = this.users;
    return user;
  }
}

@ReduxAction(UserActions.setCredential, ReduxSlices.User)
export class UserSetCredentialAction {
  constructor(public name: string , public value : string) {}

  reduce(user: UserState) {
    user = {
      ...user,
      [this.name]: this.value,
    };
  return user;
  }
}
