
import { BookHeaderList } from "../feature/book/components/book.header.list.component";
import { RouteEnum } from "../shared/enums/route.enum";
import {InstituteHeaderList} from "../feature/institute/components/institute.header.list.component";
import { InstituteHeaderCreate } from "../feature/institute/components/institute.header.create.component";
import { BookHeaderCreate } from "../feature/book/components/book.header.create.component";
import { StatisticsHeader } from "../feature/statistics/components/statistics.header.component";

export default new Map(
    Object.entries({
        [RouteEnum.STATISTICS]: StatisticsHeader,
        [RouteEnum.INSTITUTE]: InstituteHeaderList,
        [RouteEnum.INSTITUTE_CREATE]: InstituteHeaderCreate,
        [RouteEnum.BOOK]: BookHeaderList,
        [RouteEnum.BOOK_CREATE]: BookHeaderCreate,
    })
);
