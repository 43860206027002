import EventEmitter from 'eventemitter3';

export class Emitter {
    private eventEmitter = new EventEmitter();
    on(event: any, fn: any) {
        this.eventEmitter.on(event, fn)
    }

    once(event: any, fn: any) {
        this.eventEmitter.once(event, fn)
    }
    off(event: any, fn: any) {
        this.eventEmitter.off(event, fn)
    }
    emit(event: any, payload: any) {
        this.eventEmitter.emit(event, payload)
    }
}

