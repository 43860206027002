import { MenuItem } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import React from "react";
import { Controller } from "react-hook-form";

export interface ISelectItem {
    name: any;
    value: any;
  }

type Props = {
    name: string;
    control?: any;
    items: Array<ISelectItem>;
} & TextFieldProps;

/**
 * TextInput
 * 
 * It returns a wrapped component handled by react-hook-form if there is the control prop,
 * a standard Material UI component otherwise. 
 * 
 * @property {string} name - property indicating the name of the input text
 * @property {Control} control - property used to control the input text by hook form
 * 
 * @returns JSX.Element
 */

class SelectInput extends React.Component<Props> {

    handleOnChange(onHookChange: Function) {
        return (event: any) => {
            const { onChange } = this.props;
            onChange?.(event);
            onHookChange(event);
        }
    }

    handleOnBlur = (onHookBlur: Function) => (event: any) => {
        const { onBlur } = this.props;
        onBlur?.(event);
        onHookBlur();
    }

    render() {
        const { control, name, disabled, items, ...rest } = this.props;
        return (
            control ? (
                <Controller
                    control={control}
                    name={name}
                    //defaultValue=""
                    render={({ field }) => <TextField
                        {...field}
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{ ...this.props.inputProps }}
                        InputLabelProps={{ classes: { disabled: "textLabelInputStyleDisabled" } }}
                        InputProps={{
                            classes: { root: `textInputStyle height32`, disabled: "textInputStyleDisabled", focused: "focused-input" }
                        }}
                        disabled={disabled}
                        className={disabled ? "textInputBorder" : ''}
                        {...rest}
                        onChange={this.handleOnChange(field.onChange)}
                        onBlur={this.handleOnBlur(field.onBlur)}
                        select
                    >
                        {items?.map((item: ISelectItem, index: any) => (
                            <MenuItem key={index} value={item.value} selected={item.value === this.props.value  }>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>}
                />) :
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...rest}
                    select
                >
                    {items?.map((item: ISelectItem, index: any) => (
                        <MenuItem key={index} value={item.value}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>

        )
    }

}


export default SelectInput;