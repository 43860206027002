import React from "react";
import { AppBar as MuiAppBar, Button, Grid } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { UserState } from "../../states/user.state";
import { AuthEnum } from "../../enums/auth.enum";
import { ReduxConnect, StoreManager } from "@hypereact/state";
import { ReduxState } from "../../../config/redux.config";
import { NavigationState } from "../../states/navigation.state";
import { FormattedMessage, IntlContext } from "react-intl";
import HeaderConfig from "../../../config/header.config";
import { RouteEnum } from "../../enums/route.enum";
import { NavigationService } from "../../services/navigation.service";
import { ConfigState } from "../../states/config.state";
import { ReduxSlices } from "../../../config/enums/redux.slices";
import Image from "../../../assets/images/logo_bip.png";

import { RoleEnum } from "../../enums/role.enum";
import { Role } from "../../../api/entities";

interface IAppBarProps {
  user?: UserState;
  navigation?: NavigationState;
  config?: ConfigState;
}

@ReduxConnect((state: ReduxState) => {
  return {
    user: state.user,
    navigation: state.navigation,
    config: state.config,
  };
})
export class AppBar extends React.Component<IAppBarProps> {
  private navigationService: NavigationService;
  private storeManager: StoreManager;

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: IAppBarProps) {
    super(props);
    this.storeManager = StoreManager.getInstance();
    this.navigationService = NavigationService.getInstance();
  }


  render() {
    const { user, navigation, config } = this.props;
    const currentRoute = navigation?.url as RouteEnum;
    const isUserAuthenticated: boolean = user?.state === AuthEnum.Login;
    const isLoginPage: boolean = currentRoute === RouteEnum.LOGIN;
    const openSideMenu: boolean = config?.panels.menu!;
    const pageName: string = navigation?.data?.router?.name!;
    const toolBarComponent = HeaderConfig.get(currentRoute);


    const expandAppbar = () => {
      if (
        !isLoginPage &&
        isUserAuthenticated
      ) {
        return true;
      } else {
        return false;
      }
    };

    const showAppbar = () => {
      return isLoginPage
    };

    const constructBreadCrumb = (): string => {
      const { user } = this.props;
      if (user.state === AuthEnum.Login) {
        if (user.personalData.roles.length > 0) {
          switch (user.personalData.roles[0].name) {
            case 'ROLE_ADMIN':
              return `Amministratore/`;
            case 'ROLE_STUDENT':
              return `${user.institute.title}(Studente)/`;
            case 'ROLE_INSTITUTE':
              return `${user.institute.title}/`;
          }
        }
      }
    }

    return (
      <>
        {expandAppbar() && (

          <MuiAppBar
            position="fixed"
            className={openSideMenu === true ? "appBarShift" : "appBar"}
            elevation={0}
          >



            <Toolbar>
              <img src={Image} className="bip-logo-style" />
              <Typography variant="h6" noWrap className="app-title">
                {constructBreadCrumb()}
                <FormattedMessage
                  id={pageName || "navigation.page.root.title"}
                />
              </Typography>
              <span>
                {toolBarComponent && React.createElement(toolBarComponent)}
              </span>
            </Toolbar>
          </MuiAppBar>
        )}
        {showAppbar() && (
          <MuiAppBar position="fixed" className="appBarLogin" elevation={0}>
            <Toolbar className="appBarLoginLogo">
              {/* <Grid className="w-40">
                <Grid item className="login-logo-style">
                  <Grid item className="login-logo-small">
                  </Grid>
                </Grid>
              </Grid> */}
              <Typography variant="h6" noWrap className="app-title">
                <FormattedMessage
                  id={"navigation.page.root.title"}
                />
              </Typography >
              {toolBarComponent && React.createElement(toolBarComponent)}
            </Toolbar>
          </MuiAppBar>
        )}
      </>
    );
  }
}
