import { ReduxConnect } from "@hypereact/state/js/lib/decorators/redux.connect";
import { StoreManager } from "@hypereact/state/js/lib/managers/store.manager";
import {
  ListItem,



  ListItemIcon
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import { ConfigSetPanelsMenuAction } from "../../../actions/config.actions";
import { ReduxState } from "../../../config/redux.config";
import { RouteEnum } from "../../enums/route.enum";
import { ConfigState } from "../../states/config.state";
import TooltipComponent from "../TooltipComponent/tooltip.component";

export interface IMenuItemProps {
  name: string;
  route?: RouteEnum;
  selected?: boolean;
  disabled?: boolean;
  onClick: (route?: RouteEnum) => void;
  icon?: JSX.Element | React.ReactNode;
  config?: ConfigState;
}
@ReduxConnect((state: ReduxState) => {
  return {
    config: state.config,
  };
})

export class MenuItem extends React.Component<IMenuItemProps> {
  private storeManager: StoreManager;


  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: IMenuItemProps) {
    super(props);
    this.storeManager = StoreManager.getInstance();
  }

  handleDrawerClick() {
    const { config } = this.props;
    this.storeManager.dispatch(
      new ConfigSetPanelsMenuAction(!config?.panels.menu)
    );
  }

  render() {
    const { name, route, selected, disabled, onClick, icon, config } = this.props;

    const menuOpen = config?.panels?.menu;



    return (
      <div onClick={() => onClick(route)} className={`menu ${selected ? "selected-item-menu" : ""}`} >
        <TooltipComponent placement="right" title={`${this.context.formatMessage({ id: `${name}` })}`}>
          <ListItem button selected={selected} disabled={disabled} className={`menu ${menuOpen ? "menuOpen" : "menuClose"}`}   >
            <ListItemIcon className="menu-icon">{icon}</ListItemIcon>
            <div className="menu-avatar" >
              <div className=" formatted-message-style">
                <FormattedMessage id={`${name}`} />
              </div>
            </div>
          </ListItem>
        </TooltipComponent>
      </div>
    );
  }
}
