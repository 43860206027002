import { RouterConfig } from "../config/router.config";
import { RouteEnum } from "../shared/enums/route.enum";
import { ReduxAction } from "@hypereact/state";
import { ReduxSlices } from "../config/enums/redux.slices";
import { NavigationState } from "../shared/states/navigation.state";
import { NavigationDataInterface } from "../shared/interfaces/navigation/navigation.data.interface";

enum NavigationActions {
  SetRoute = "NAVIGATION_SET_ROUTE",
  SetLink = "NAVIGATION_SET_LINK",
}

const routerConfig = RouterConfig.getInstance();
@ReduxAction(NavigationActions.SetRoute, ReduxSlices.Navigation)
export class SetRouteAction {
  constructor(public previousUrl: string, public currentUrl: string, public currentRoute: RouteEnum) {}

  reduce(navigationState: NavigationState) {
    if (this.previousUrl !== undefined) {
      navigationState.back = this.previousUrl;
    }
    navigationState.url = this.currentUrl;
    navigationState.route = this.currentRoute;
    navigationState.data = routerConfig
      .getRoutes()
      .get(this.currentRoute) as NavigationDataInterface;
    return navigationState;
  }
}

@ReduxAction(NavigationActions.SetLink, ReduxSlices.Navigation)
export class NavigationSetLinkAction {
  constructor(public link?: RouteEnum) {}

  reduce(navigationState: NavigationState) {
    navigationState.link = this.link;
    return navigationState;
  }
}
