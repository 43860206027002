import { ReduxAction } from "@hypereact/state";
import { ReduxSlices } from "../config/enums/redux.slices";
import { HttpState } from "../shared/states/http.state";

enum HttpActions {
    SetRequestPending = "SET_HTTP_REQUEST_PENDING",
    SetRequestCompleted = "SET_HTTP_REQUEST_COMPLETED"
}

@ReduxAction(HttpActions.SetRequestPending, ReduxSlices.Http)
export class SetRequestPendingAction {

    reduce(httpState: HttpState) {
        httpState.pending = httpState.pending + 1;
        httpState.loading = true;
        return httpState;
    }
}

@ReduxAction(HttpActions.SetRequestCompleted, ReduxSlices.Http)
export class SetRequestCompletedAction {

    reduce(httpState: HttpState) {
        if (httpState.pending > 0) {
            httpState.pending = httpState.pending - 1;
        } else {
            httpState.pending = 0;
        }
        if(httpState.pending === 0){
            httpState.loading = false
        }
        httpState.completed = httpState.completed + 1;
        return httpState;
    }
}