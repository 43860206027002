import { IsNotEmpty, MaxLength } from "class-validator";

export class InstituteCreateFormData {

    @IsNotEmpty()
    @MaxLength(30)
    code!: string;

    @IsNotEmpty()
    @MaxLength(100)
    title!: string;

    @IsNotEmpty()
    grade!: string;

    instituteURL: string;
    instituteKEY: string;
    studentURL: string;
    studentKEY: string;
}