import * as jsonpatch from 'fast-json-patch';
import { Book } from "../../api/entities/book";
import { BookCreateModel } from "../../api/entities/book-create-model";
import { BookSearchFilter } from "../../api/entities/book-search-filter";
import { BookSearchResponse } from "../../api/entities/book-search-response";
import { BookApiClients, IBookApiClients } from "./book.api.client";

export interface IBookApiService {
    create(book: BookCreateModel): Promise<Book>;
    getById(id: number): Promise<Book>;
    patch(oldBook: Book, updatedBook: Book): Promise<Book>;
    search(
        page: number,
        size: number,
        query?: string,
        sort?: { [key: string]: string | undefined },
        filter?: BookSearchFilter
    ): Promise<BookSearchResponse>;
    upload(id: number, file: any): Promise<Book>;
    delete(id: number);
    download(id): Promise<object>;
    
}

export class BookApiService implements IBookApiService {
    private static instance: BookApiService;
    private api: IBookApiClients;

    private constructor(basePath: string) {
        this.api = BookApiClients.getInstance(basePath);
    }
    
    

    static getInstance(basePath: string) {
        if (BookApiService.instance == null) {
            BookApiService.instance = new BookApiService(basePath);
        }
        return BookApiService.instance;
    }

    async delete(id: number) {
        await this.api.book.deleteBookById(id);   
    }

    async create(book: BookCreateModel): Promise<Book> {
        return (await this.api.book.createBook(book)).data;
    }

    async upload(id: number, file: any): Promise<Book> {
        return (await this.api.book.uploadBook(id, file)).data;
    }

    async download(id: number): Promise<object> {
        return (await this.api.download.downloadBook(id)).data;
    }

    async getById(id: number): Promise<Book> {
        return (await this.api.book.getBookById(id)).data;
    }

    async patch(oldBook: Book, updatedBook: Book): Promise<Book> {
        const requestPatch: any = jsonpatch.compare(oldBook, updatedBook);
        const book: Book = (
            await this.api.book.patchBook(oldBook.id, requestPatch)
        ).data;
        return book;
    }

    async search(
        page: number,
        size: number,
        query?: string,
        sort?: { [key: string]: string | undefined },
        filter?: BookSearchFilter
    ): Promise<BookSearchResponse> {
        return (
            await this.api.book.searchBook({
                page,
                size,
                query,
                sort,
                filter,
            })
        ).data;
    }
}