// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InstituteCreateModel
 */
export interface InstituteCreateModel {
    /**
     * institute code
     * @type {string}
     * @memberof InstituteCreateModel
     */
    code: string;
    /**
     * institute grade
     * @type {string}
     * @memberof InstituteCreateModel
     */
    grade?: InstituteCreateModelGradeEnum;
    /**
     * institute title
     * @type {string}
     * @memberof InstituteCreateModel
     */
    title: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InstituteCreateModelGradeEnum {
    _01 = '01',
    _02 = '02'
}



