import { StoreManager } from "@hypereact/state";
import moment from "moment-with-locales-es6";
import { ReduxSlices } from "../../config/enums/redux.slices";

export interface IDateFormatter {
  toLongDate(date?: Date | string): string;
  toLongMinDate(date?: Date | string): string;
  toShortDate(date?: Date | string): string;
  formatDoDate(date?: Date | string): string;
  formatDateTime(date?: Date | string, showTime?: boolean): string;
  formatDoTime(date?: Date | string, showTime?: boolean): string;
  formatToDate(date?: Date | string): string;
}

export class DateFormatter implements IDateFormatter {
  private static instance: DateFormatter;
  private storeManager: StoreManager;

  private constructor() {
    this.storeManager = StoreManager.getInstance();
  }

  static getInstance() {
    if (DateFormatter.instance == null) {
      DateFormatter.instance = new DateFormatter();
    }
    return DateFormatter.instance;
  }

  private getLanguage(): string {
     let language = this.storeManager.getState(ReduxSlices.Config).language;
  if (language === undefined){
   language = navigator.language
  }
     return language !== 'al' ? language : 'sq' as string;
  }

  toLongDate(date?: Date | string): string {
    let locale:string = this.getLanguage();
    moment.locale(locale);

    return date && moment(date).isValid()
      ? moment.locale(locale) !== "it" ?
      moment(date).format("DD/MM/YYYY - h:mm:ss A") :
      moment(date).format("DD/MM/YYYY - HH:mm:ss")
      : "-";
  }

  toLongMinDate(date?: Date | string): string {
    let time :any = (date as string).substr(11,18)
    let locale:string = this.getLanguage();
    moment.locale(locale);

    return date && moment(date).isValid()
      ? moment.locale(locale) !== "it" ? 
      moment(date).format("DD/MM/YYYY") + " " + this.time24hTo12hConvert(time) :
      moment(date).format("DD/MM/YYYY") + " " + time.substr(0,5)
      : "-";
  }

  toShortDate(date?: Date | string): string {
    return date && moment(date).isValid()
      ? moment(date).format("DD/MM/YYYY")
      : "-";
  }

  formatDateTime(date?: Date | string, showTime?: boolean): string {
    let locale:string = this.getLanguage();
    moment.locale(locale);

    let localeDate = moment(date);
    return date && moment(date).isValid()
      ? showTime && moment.locale(locale) !== "it"
        ? localeDate.format("MMMM DD, YYYY h:mm A")
        : showTime && moment.locale(locale) === "it" ?
          localeDate.format("MMMM DD, YYYY HH:mm") 
          : localeDate.format("MMMM DD, YYYY")
      : "-";
  }

  formatDoDate(date?: Date | string): string {
    let locale:string = this.getLanguage();
    moment.locale(locale);
    let localeDate = moment(date);

    return date && moment(date).isValid()
      ? localeDate.format("LL")
      : "-";
  }

  formatDoTime(date?: Date | string): string {
    let time :any = (date as string).substr(11,18)
    let locale:string = this.getLanguage();
    moment.locale(locale);

    return date && moment(date).isValid()
      ? moment.locale(locale) !== "it"
      ? this.time24hTo12hConvert(time)
      : time.substr(0,5)
      : "-";
  }

  formatToDate(date?: Date | string): string {
    return date && moment(date).isValid()
      ? moment(date).format("DD")
      : "-";
  }


  time24hTo12hConvert (time : any) {
    let timeWithoutMs = time.substr(0,5);
    let subTime = timeWithoutMs.substr(0,2);
    let moduledTime = subTime % 12 || 12;
    let ampm= (subTime < 12 || subTime === 24) ? "AM" : "PM";
    return timeWithoutMs = moduledTime + timeWithoutMs.substr(2, 3) + " " + ampm;
  }
  
  
}
