import { AuthControllerApi, UserControllerApi } from "../../api";


export interface IAuthApiClients {
  auth: AuthControllerApi;
  user: UserControllerApi;
}

export class AuthApiClients {
  private static instance: IAuthApiClients;

  private constructor() {}

  static getInstance(basePath: string): IAuthApiClients {
    if (AuthApiClients.instance == null) {
      AuthApiClients.instance = {
        auth: new AuthControllerApi({
          basePath: basePath,
        }),
        user: new UserControllerApi({
          basePath: basePath,
        })        
      };
    }
    return AuthApiClients.instance;
  }
}
