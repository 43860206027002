import { InstituteControllerApi } from "../../api";


export interface IInstituteApiClients {
  institute: InstituteControllerApi;
}

export class InstituteApiClients {
  private static instance: IInstituteApiClients;

  private constructor() {}

  static getInstance(basePath: string): IInstituteApiClients {
    if (InstituteApiClients.instance == null) {
        InstituteApiClients.instance = {
            institute: new InstituteControllerApi({
          basePath: basePath,
        }),    
      };
    }
    return InstituteApiClients.instance;
  }
}