import { IsEmail, IsNotEmpty, MaxLength } from "class-validator";

export class LoginFormData {
    @IsEmail()
    @IsNotEmpty()
    @MaxLength(100)
    email!: string;

    @IsNotEmpty()
    @MaxLength(50)
    password!: string;

    tenant?: number;
}