// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Approval } from './approval';
import { External } from './external';
import { Status } from './status';
import { UserDetails } from './user-details';

/**
 * json data of a user
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserData
     */
    type?: Array<UserDataTypeEnum>;
    /**
     * 
     * @type {UserDetails}
     * @memberof UserData
     */
    details?: UserDetails;
    /**
     * 
     * @type {Status}
     * @memberof UserData
     */
    status?: Status;
    /**
     * 
     * @type {Approval}
     * @memberof UserData
     */
    approvals?: Approval;
    /**
     * 
     * @type {External}
     * @memberof UserData
     */
    externals?: External;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDataTypeEnum {
    INTERNAL = 'INTERNAL'
}



