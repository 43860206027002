import React, { ReactElement, useEffect, useState } from "react";
import { PopupManager } from "../../../managers/popup.manager";

let popupManager: PopupManager;

const PopupContainer: React.FC = () => {
    const [popup, setPopup] = useState<ReactElement | null>(null);

    useEffect(() => {
        popupManager = PopupManager.getInstance(setPopup);
    }, []);

    return popup;
}

export default PopupContainer;
