
import { StoreManager } from "@hypereact/state";
import { ReduxSlices } from "../../../config/enums/redux.slices";
import { GradeFilterBarItem } from "../../../shared/components/FilterBar/grade.filterbar.item.component";
import { OrderFilterBarItem } from "../../../shared/components/FilterBar/order.filterbar.item.component";
import { QueryFilterBarItem } from "../../../shared/components/FilterBar/query.filterbar.item.component";
import { FilterBarEnum } from "../../../shared/enums/filterBar.enum";
import { UserState } from "../../../shared/states/user.state";

export class FilterBarItems {

    private static getUserState(): UserState {
        return StoreManager.getInstance().getState(ReduxSlices.User) as UserState;
      }
    

    static getFilterBarItems(): Map<string, any>{

        const userState = this.getUserState();
        const filterBarConf = new Map<string, any>();
        filterBarConf.set(FilterBarEnum.QUERY, QueryFilterBarItem);
        
        if(userState.personalData.roles[0].name === 'ROLE_ADMIN') {
            filterBarConf.set(FilterBarEnum.GRADE, GradeFilterBarItem);
        }

        filterBarConf.set(FilterBarEnum.ORDER, OrderFilterBarItem);
        return filterBarConf;

    }
}

