import { ReduxConnect, StoreManager } from "@hypereact/state";
import { Box, Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  ConfigSetStatisticsOptionAction
} from "../../../actions/statistics.actions";
import { ReduxState } from "../../../config/redux.config";
import { StatisticsManager } from "../../../managers/statistics.manager";
import {
  StatisticsOptionsEnum,
  StatisticsUtil
} from "../../../shared/enums/statistics.options";
import { StatisticsState } from "../../../shared/states/statistics.state";

interface IStatisticsOptionProps {
  statistics?: StatisticsState;
}

@ReduxConnect((state: ReduxState) => {
  return {
    statistics: state.statistics,
  };
})
export class StatisticsOptionsComponent extends React.Component<IStatisticsOptionProps> {
  private storeManager: StoreManager;
  private statisticsManager: StatisticsManager;

  constructor(props) {
    super(props);
    this.storeManager = StoreManager.getInstance();
    this.statisticsManager = StatisticsManager.getInstance();
  }

  render() {
    const { statisticsOption } = this.props.statistics;

    return (
      <>
        <Box display="flex">
          {StatisticsUtil.getEnumValues().map((currentOption, index) => (
            <Box mr={3} key={index}>
            <Button
              color="primary"
              variant={
                (currentOption as StatisticsOptionsEnum) === statisticsOption
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                if (currentOption !== statisticsOption) {
                  this.storeManager.dispatch(
                    new ConfigSetStatisticsOptionAction(
                      currentOption as StatisticsOptionsEnum
                    )
                  );

                  this.statisticsManager.fullReset();
                }
              }}
            >
              {<FormattedMessage id={`statistics.buttons.option.${currentOption}`}/>}
            </Button>
            </Box>
          ))}
        </Box>
      </>
    );
  }
}
