import { StoreManager } from "@hypereact/state";
import { fi } from "date-fns/locale";
import {
  BookStatisticsResetFilterAction,
  BookStatisticsResetQueryAction,
  BookStatisticsSetGradesAction,
  BookStatisticsSetItemsAction,
  BookStatisticsSetOrderAction,
  BookStatisticsSetPaginationAction,
  BookStatisticsSetQueryAction,
  BookStatisticsSetShowClearFilter,
  InstituteStatisticsResetFilterAction,
  InstituteStatisticsResetQueryAction,
  InstituteStatisticsSetGradesAction,
  InstituteStatisticsSetItemsAction,
  InstituteStatisticsSetOrderAction,
  InstituteStatisticsSetPaginationAction,
  InstituteStatisticsSetQueryAction,
  InstituteStatisticsSetShowClearFilter,
  ResetBookStatisticsGradeAction,
  ResetInstituteStatisticsGradeAction,
  StatisticsTotalNumberOfDownload
} from "../actions/statistics.actions";
import { InstituteGradeEnum, StatsSearchFilter } from "../api/entities";
import { ReduxSlices } from "../config/enums/redux.slices";
import {
  IStatisticsApiService,
  StatisticsApiService
} from "../services/statistics/statistics.api.service";
import { FilterBarEnum } from "../shared/enums/filterBar.enum";
import { StatisticsOptionsEnum } from "../shared/enums/statistics.options";
import { Config } from "../shared/interfaces/list/config.interface";
import { ConfigState } from "../shared/states/config.state";
import {
  BooksStatisticsState,
  initialBookStatisticsState,
  initialInstituteStatisticsState,
  InstituteStatisticsState,
  StatisticsState
} from "../shared/states/statistics.state";

export interface IStatisticsManager { }

export enum GradeEnum {
  _01 = 'O1',
  _02 = 'O2'
}

export class StatisticsManager implements IStatisticsManager {
  private statisticsService: IStatisticsApiService;

  private storeManager: StoreManager;
  private static instance: StatisticsManager;

  private constructor() {
    this.storeManager = StoreManager.getInstance();
    this.statisticsService = StatisticsApiService.getInstance(
      process.env.REACT_APP_MICROSERVICE_BIP_BASEPATH!
    );
  }

  static getInstance() {
    if (StatisticsManager.instance == null) {
      StatisticsManager.instance = new StatisticsManager();
    }
    return StatisticsManager.instance;
  }

  async getTotal() {
    const response = await this.statisticsService.getTotal();
    this.storeManager.dispatch(
      new StatisticsTotalNumberOfDownload(response.hits)
    );
  }

  async exportCSV() {
    window.open(
      `${window.location.origin}${process.env.REACT_APP_MICROSERVICE_BIP_BASEPATH}/stats/export`
    );
  }


  async getBookStatistics() {
    const bookStatisticsState = this.storeManager.getState(
      ReduxSlices.BookStatistics
    ) as BooksStatisticsState;

    const order = bookStatisticsState.order
      ? { [bookStatisticsState.order.by]: bookStatisticsState?.order.direction }
      : undefined;

    const statsSearchFilter: StatsSearchFilter = {
      grade: bookStatisticsState.filters.grade && bookStatisticsState.filters.grade !== '' ? bookStatisticsState.filters.grade : undefined
    }

    const response = await this.statisticsService.getBooksStats(
      bookStatisticsState.pagination.page,
      bookStatisticsState.pagination.size,
      bookStatisticsState.query,
      order,
      statsSearchFilter
    );
    this.storeManager.dispatch(
      new BookStatisticsSetItemsAction(
        response.books && response.books.length > 0
          ? response.books.filter((currentBook) => currentBook.book != null)
          : [],
        response.page.total
      )
    );
  }

  async getInstituteStatistics() {
    const instituteStatisticsState = this.storeManager.getState(
      ReduxSlices.InstituteStatistics
    ) as InstituteStatisticsState;

    const statsSearchFilter: StatsSearchFilter = {
      grade: instituteStatisticsState.filters.grade && instituteStatisticsState.filters.grade !== '' ? instituteStatisticsState.filters.grade : undefined
    }
    const order = instituteStatisticsState.order
      ? {
        [instituteStatisticsState.order.by]:
          instituteStatisticsState?.order.direction,
      }
      : undefined;

    const response = await this.statisticsService.getInstitutesStats(
      instituteStatisticsState.pagination.page,
      instituteStatisticsState.pagination.size,
      instituteStatisticsState.query,
      order,
      statsSearchFilter
    );
    this.storeManager.dispatch(
      new InstituteStatisticsSetItemsAction(
        response.institutes && response.institutes.length > 0
          ? response.institutes.filter(
            (currentInstitute) => currentInstitute.institute != null
          )
          : [],
        response.institutes?.length
      )
    );
  }

  getStatisticsOption() {
    return (
      this.storeManager.getState(ReduxSlices.Statistics) as StatisticsState
    ).statisticsOption;
  }

  private getState(
    option: StatisticsOptionsEnum
  ): BooksStatisticsState | InstituteStatisticsState {
    if (StatisticsOptionsEnum.Book === option) {
      return this.storeManager.getState(
        ReduxSlices.BookStatistics
      ) as BooksStatisticsState;
    } else {
      return this.storeManager.getState(
        ReduxSlices.InstituteStatistics
      ) as InstituteStatisticsState;
    }
  }

  private getConfigState(option: StatisticsOptionsEnum): any {
    const state = this.storeManager.getState(ReduxSlices.Config) as ConfigState;
    return StatisticsOptionsEnum.Book === option
      ? state.config.bookStatistics
      : state.config.instituteStatistics;
  }

  fullReset(makeCall: boolean = true) {
    this.clearFilters();
    if (makeCall) {
      if (StatisticsOptionsEnum.Book === this.getStatisticsOption()) {
        this.getBookStatistics();
        this.storeManager.dispatch(new BookStatisticsSetShowClearFilter(false));
      } else {
        this.getInstituteStatistics();
        this.storeManager.dispatch(
          new InstituteStatisticsSetShowClearFilter(false));
      }
    }

  }

  getConfig(
    items: Map<string, any>,
    option: StatisticsOptionsEnum
  ): Map<string, Config> {
    let configMap: Map<string, Config> = new Map();
    let itemKeys = Array.from(items.keys());
    itemKeys.forEach((key) => {
      switch (key) {
        case FilterBarEnum.QUERY:
          configMap.set(FilterBarEnum.QUERY, {
            name: `statistics.${option.toLowerCase()}`,
            value: this.getState(option).query,
          });
          break;
        case FilterBarEnum.ORDER:
          configMap.set(FilterBarEnum.ORDER, {
            value: this.getState(option).order,
            items: this.getConfigState(option).items,
          });
          break;
        case FilterBarEnum.GRADE:

          const value: string[] = this.getState(option).filters.grade
            ? this.getState(option).filters.grade!.split(",")
            : [];
          configMap.set(FilterBarEnum.GRADE, {
            value: value,
            items: GradeEnum
          });
          break;
        default:
      }
    });
    return configMap;
  }




  onFilterChange(name: string, value: any) {
    const statisticsOption = (
      this.storeManager.getState(ReduxSlices.Statistics) as StatisticsState
    ).statisticsOption;

    switch (name) {
      case FilterBarEnum.QUERY:
        const stateQuery = this.getState(statisticsOption).query;
        const query = value !== "" ? value : undefined;
        if (StatisticsOptionsEnum.Book === statisticsOption) {
          this.storeManager.dispatch(new BookStatisticsSetQueryAction(query));

          if (query !== stateQuery && query?.length! >= 3) {
            this.getBookStatistics();
          }
        } else {
          this.storeManager.dispatch(
            new InstituteStatisticsSetQueryAction(query)
          );

          if (query !== stateQuery && query?.length! >= 3) {
            this.getInstituteStatistics();
          }
        }
        break;
      case FilterBarEnum.ORDER:
        if (StatisticsOptionsEnum.Book === statisticsOption) {
          this.storeManager.dispatch(new BookStatisticsSetOrderAction(value));
        } else {
          this.storeManager.dispatch(
            new InstituteStatisticsSetOrderAction(value)
          );
        }

        if (StatisticsOptionsEnum.Book === statisticsOption) {
          this.getBookStatistics();
        } else {
          this.getInstituteStatistics();
        }
        break;
      case FilterBarEnum.GRADE:
        if (StatisticsOptionsEnum.Book === statisticsOption) {
          this.storeManager.dispatch(new BookStatisticsSetGradesAction(value));
          this.getBookStatistics();
        } else {
          this.storeManager.dispatch(new InstituteStatisticsSetGradesAction(value));
          this.getInstituteStatistics();
        }
        break;
      default:
        break;
    }
  }

  clearFilters(): void {
    const statisticsOption = (
      this.storeManager.getState(ReduxSlices.Statistics) as StatisticsState
    ).statisticsOption;

    if (StatisticsOptionsEnum.Book === statisticsOption) {
      this.storeManager.dispatch(new BookStatisticsResetFilterAction());
      this.storeManager.dispatch(new BookStatisticsResetQueryAction());
      this.storeManager.dispatch(new ResetBookStatisticsGradeAction());
    } else {
      this.storeManager.dispatch(new InstituteStatisticsResetFilterAction());
      this.storeManager.dispatch(new InstituteStatisticsResetQueryAction());
      this.storeManager.dispatch(new ResetInstituteStatisticsGradeAction());
    }
  }

  async setPagination(page: number, size: number): Promise<void> {
    this.storeManager.dispatch(
      StatisticsOptionsEnum.Book === this.getStatisticsOption()
        ? new BookStatisticsSetPaginationAction(page, size)
        : new InstituteStatisticsSetPaginationAction(page, size)
    );
    if (StatisticsOptionsEnum.Book === this.getStatisticsOption()) {
      this.getBookStatistics();
    } else {
      this.getInstituteStatistics();
    }
  }

  resetPagination(): void {
    this.storeManager.dispatch(
      StatisticsOptionsEnum.Book === this.getStatisticsOption()
        ? new BookStatisticsSetPaginationAction(initialBookStatisticsState.pagination.page, initialBookStatisticsState.pagination.size)
        : new InstituteStatisticsSetPaginationAction(initialInstituteStatisticsState.pagination.page, initialInstituteStatisticsState.pagination.size)
    );
  }
}
