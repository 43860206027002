import { BookControllerApi, DownloadControllerApi } from "../../api";


export interface IBookApiClients {
  book: BookControllerApi;
  download: DownloadControllerApi;
}

export class BookApiClients {
  private static instance: IBookApiClients;

  private constructor() {}

  static getInstance(basePath: string): IBookApiClients {
    if (BookApiClients.instance == null) {
        BookApiClients.instance = {
        book: new BookControllerApi({
          basePath: basePath,
        }),    
        download: new DownloadControllerApi({
          basePath: basePath,
        })
      };
    }
    return BookApiClients.instance;
  }
}
