export enum RouteEnum {
  /*TECHNICAL ROUTES*/
  ROOT = "/",
  FALLBACK = "*",
  HOME = "/home",

  /*AUTH ROUTES*/
  LOGIN = "/login",
  
  /*STATISTICS ROUTES*/
  STATISTICS = "/statistics",
  
  /*BOOK ROUTES*/ 
  BOOK = "/book",
  BOOK_CREATE = "/book/create",
  
  /*INSTITUTE ROUTES*/
  INSTITUTE = "/institute",
  INSTITUTE_CREATE = "/institute/create",
  
}
