import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import CountUp from "react-countup";
import { FormattedMessage } from "react-intl";
import { BookStats } from "../../../api/entities";

interface IStatisticViewProps {
  items?: any[]
}

export class StatisticsBookSearchResultComponent extends React.Component<IStatisticViewProps> {
  constructor(props: IStatisticViewProps) {
    super(props);
  }

  render() {
    const {items} = this.props;
    return (
        items && items.length > 0) ?
                <TableContainer component={Paper} className="table-container-statistics">
                    <Table aria-label="simple table">
                        <TableHead className="table-head table-body">
                            <TableRow>
                                <TableCell  className="upload-document-list-header table-head links-style">
                                    <FormattedMessage id="statistics.books.result.code.cellhead" />
                                </TableCell>
                                <TableCell className="upload-document-list-header table-head links-style">
                                    <FormattedMessage id="statistics.books.result.title.cellhead" />
                                </TableCell>
                                <TableCell className="upload-document-list-header table-head links-style">
                                    <FormattedMessage id="statistics.books.result.grade.cellhead" />
                                </TableCell>
                                <TableCell className="upload-document-list-header table-head links-style">
                                    <FormattedMessage id="statistics.books.result.level.cellhead" />
                                </TableCell>
                                <TableCell className="upload-document-list-header table-head links-style">
                                    <FormattedMessage id="statistics.books.result.hitnumber.cellhead" />
                                </TableCell>                        
                            </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                            {items.map((currentBook: BookStats, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell className="table-cell table-cell-statistics">
                                                <div
                                                >
                                                    {currentBook.book.code ?? "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell className="table-cell table-cell-statistics">
                                                <div
                                                >
                                                    {currentBook.book.title ?? "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell className="table-cell table-cell-statistics">
                                                <div
                                                >
                                                    {currentBook.book.grade ? 
                                                    <FormattedMessage id={`statistics.books.grade.${currentBook.book.grade}`} />
                                                     : "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell className="table-cell table-cell-statistics">
                                                <div
                                                >
                                                    {currentBook.book.level ? 
                                                    <FormattedMessage id={`statistics.books.level.${currentBook.book.level}`} />
                                                     : "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell className="table-cell table-cell-statistics">
                                                <div
                                                >
                                                    {currentBook.hits ? <CountUp end={currentBook.hits } duration={1}/> : "-"}
                                                </div>
                                            </TableCell>                                
                                        </TableRow>
                                    )
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
    : <h4>
    <FormattedMessage id="statistics.book.empty" />
</h4>
  }
}
