import { ReduxConnect, StoreManager } from "@hypereact/state";
import React from "react";
import { BookSetFileErrorAction } from "../../../actions/book.actions";
import {
  BookCreateModel,
  BookCreateModelGradeEnum,
  BookCreateModelLevelEnum,
} from "../../../api/entities/book-create-model";
import { ReduxState } from "../../../config/redux.config";
import { BookManager } from "../../../managers/book.manager";
import { ActionType } from "../../../managers/form.manager";
import { NotificationFactory } from "../../../managers/notification.factory";
import { Emitter } from "../../../shared/components/Upload/event.emitter.component";
import { RouteEnum } from "../../../shared/enums/route.enum";
import { SeverityEnum } from "../../../shared/enums/severity.enum";
import { NavigationService } from "../../../shared/services/navigation.service";
import { BookState } from "../../../shared/states/book.state";
import { ConfigState } from "../../../shared/states/config.state";
import { BookFormData } from "../beans/book.form.data";
import BookFormContainer from "../components/book.form.container";

interface IBookViewProps {
  bookState: BookState;
  config?: ConfigState;
}

@ReduxConnect((state: ReduxState) => {
  return {
    bookState: state.book,
  };
})
export class BookCreateView extends React.Component<IBookViewProps> {
  private eventEmitter: Emitter;
  private bookManager: BookManager;
  private storeManager: StoreManager;
  private notificationFactory: NotificationFactory;

  private bookFile: File;

  private navigationService: NavigationService;

  constructor(props: IBookViewProps) {
    super(props);
    this.state = {
      selected: true,
    };
    this.eventEmitter = new Emitter();
    this.bookManager = BookManager.getInstance();
    this.storeManager = StoreManager.getInstance();
    this.navigationService = NavigationService.getInstance();
    this.notificationFactory = NotificationFactory.getInstance();

    this.onActions = this.onActions.bind(this);
  }

  private async handleSubmit(formData: BookFormData) {
    const bookCreateModel: BookCreateModel = {
      code: formData.code,
      title: formData.subject,
      grade: formData.grade as BookCreateModelGradeEnum,
      cover: formData.cover,
      level: formData.level as BookCreateModelLevelEnum
    };

    try {
      const createdBook = await this.bookManager.create(
        bookCreateModel,
        this.bookFile
      );

      this.bookFile = undefined;
      if (createdBook) {
        this.navigationService.goto(RouteEnum.BOOK);
        this.notificationFactory.showToast({
          message: `book.create.toast.success.message`,
          variant: SeverityEnum.SUCCESS,
        });
      }
    } catch (error) {
      NotificationFactory.getInstance().handleError(error);
    }
  }

  onActions(actionType: ActionType, payload: any): void {
    switch (actionType) {
      case ActionType.SUBMIT: {
        this.handleSubmit(payload as BookFormData);
        break;
      }
      default:
        break;
    }
  }

  onBookUploaded(file: File): void {
    this.bookFile = file;
    this.storeManager.dispatch(new BookSetFileErrorAction(false));
  }

  render() {
    const { bookState } = this.props;

    return (
      <>
        <BookFormContainer
          formValues={bookState}
          emitter={this.eventEmitter}
          onActions={this.onActions}
          onBookUploaded={(file) => this.onBookUploaded(file)}
          bookFileError={bookState.bookFileError}
        />
      </>
    );
  }
}
