import { StoreManager } from "@hypereact/state";
import { LoginSetInstituteAction, LoginSetUserAction } from "../actions/login.actions";
import { UserSetCredentialAction, UserSetItemsAction } from "../actions/user.actions";
import { Role, User, UserSearchResponse } from "../api/entities";

import { ReduxSlices } from "../config/enums/redux.slices";
import {
  AuthApiService,
  IAuthApiService,
} from "../services/auth/auth.api.service";
import { AuthEnum } from "../shared/enums/auth.enum";
import { RoleEnum } from "../shared/enums/role.enum";
import { UserState } from "../shared/states/user.state";
import { ProgressUtil } from "../shared/utils/progress.util";
import { InstituteManager } from "./institute.manager";

export interface IAuthManager {
  getUsers(): void;
  login(email: string, password: string): Promise<User>;
  check(): Promise<User>;
  logout(): Promise<void>;
  getUserById(id: number): Promise<User>;
  isUserAllowed(roles: Array<string>);
  isAdmin(): boolean;
  isStudent(): boolean;
  isInstitute(): boolean;
  getUserRoles(): void;
}

export class AuthManager implements IAuthManager {
  private storeManager: StoreManager;
  
  private static instance: AuthManager;
  private authService: IAuthApiService;
  private progressUtil: ProgressUtil;

  private constructor() {
    this.storeManager = StoreManager.getInstance();
    this.progressUtil = ProgressUtil.getInstance();
    
    this.authService = AuthApiService.getInstance(
      process.env.REACT_APP_MICROSERVICE_BIP_BASEPATH!
      );
    
  }

  getUserRoles() {
    return this.getState().personalData?.roles;
  }

  static getInstance() {
    if (AuthManager.instance == null) {
      AuthManager.instance = new AuthManager();
    }
    return AuthManager.instance;
  }

  private getState(): UserState {
    return this.storeManager.getState(ReduxSlices.User) as UserState;
  }

  async getUsers() {
    const response: UserSearchResponse = await this.progressUtil.queue(
      this.authService.search(0, 1000),
      true,
      () => {}
    );
    this.storeManager.dispatch(new UserSetItemsAction(response?.user!));
    return response;
  }

  async login(email: string, password: string): Promise<User> {
    const response = await this.progressUtil.handle(
      this.authService.login(email, password)
    );

    

    if(response.data.externals.institute.id) {
      const loggedInstitute = await InstituteManager.getInstance().getById(response.data.externals.institute.id);
      this.storeManager.dispatch(
        new LoginSetInstituteAction(loggedInstitute)
      )
    }

    this.storeManager.dispatch(
      new LoginSetUserAction(response, AuthEnum.Login, {})
    );

    return response;
  }

  async check(): Promise<User> {
    const response = await this.authService.check();
    return response;
  }

  async logout(): Promise<void> {
    await this.progressUtil.queue(this.authService.logout(), true, () => {});
    this.storeManager.suspendStorage();
  }

  async getUserById(id: number): Promise<User> {
    const response = await this.progressUtil.queue(
      this.authService.getUserById(id),
      true,
      () => {}
    );
    
    return response;
  }

  isUserAllowed(roles: Array<string>) {
    const userRole = this.getState().personalData?.roles;

    if (
      roles === undefined ||
      roles.length === 0 ||
      (userRole !== undefined &&
        userRole.some((role) => roles.indexOf(role.name!) >= 0))
    ) {
      return true;
    }
    return false;
  }

  isAdmin(): boolean {
    return this.getState().personalData?.roles?.some(
      (role: Role) => role.name === RoleEnum.ROLE_ADMIN
    )
      ? true
      : false;
  }

  isStudent(): boolean {
    return this.getState().personalData?.roles?.some(
      (role: Role) => role.name === RoleEnum.ROLE_STUDENT
    )
      ? true
      : false;
  }

  isInstitute(): boolean {
    return this.getState().personalData?.roles?.some(
      (role: Role) => role.name === RoleEnum.ROLE_INSTITUTE
    )
      ? true
      : false;
  }

  async saveFormValue(name: string, value: any) {
    await this.storeManager.dispatch(new UserSetCredentialAction(name, value));
  }
}
