import React from "react";
import {
  InstituteCreateModel,
  InstituteCreateModelGradeEnum
} from "../../../api/entities";
import { ActionType } from "../../../managers/form.manager";
import { InstituteManager } from "../../../managers/institute.manager";
import { NotificationFactory } from "../../../managers/notification.factory";
import { RouteEnum } from "../../../shared/enums/route.enum";
import { SeverityEnum } from "../../../shared/enums/severity.enum";
import { NavigationService } from "../../../shared/services/navigation.service";
import { InstituteCreateFormData } from "../beans/institute.create.form.data";
import CreateForm from "../components/institute.create.form";

export class InstituteCreateView extends React.Component {
  private instituteManager: InstituteManager;
  private navigationService: NavigationService;
  private notificationFactory: NotificationFactory;
  constructor(props) {
    super(props);
    this.instituteManager = InstituteManager.getInstance();
    this.onActions = this.onActions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.navigationService = NavigationService.getInstance();
    this.notificationFactory = NotificationFactory.getInstance();
  }

  private async handleSubmit(formData: InstituteCreateFormData) {
    try {
      const instituteCreateModel: InstituteCreateModel = {
        code: formData.code,
        title: formData.title,
        grade: formData.grade as InstituteCreateModelGradeEnum,
      };
      await this.instituteManager.create(instituteCreateModel);
      this.instituteManager.resetContext();
      this.navigationService.goto(RouteEnum.INSTITUTE);
      this.notificationFactory.showToast({
        message: `institute.create.toast.success.message`,
        variant: SeverityEnum.SUCCESS,
      });
      
    } catch (error) {
      NotificationFactory.getInstance().handleError(error);
    }
  }

  private async handleChange(
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) {
    await this.instituteManager.setFormField(
      event.target.name,
      event.target.value
    );
  }
  onActions(actionType: ActionType, payload: InstituteCreateFormData): void {
    switch (actionType) {
      case ActionType.SUBMIT:
        {
          this.handleSubmit(payload);
        }

        break;
      case ActionType.CHANGE:
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <CreateForm onActions={this.onActions} onChange={this.handleChange} />
    );
  }
}
