import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import { AuthManager } from "../../../managers/auth.manager";
import { RouteEnum } from "../../enums/route.enum";
import { RouterService } from "../../services/router.service";


interface IRouterGeneratorProps {
  entries: any;
}

export class RouterGenerator extends React.Component<
  IRouterGeneratorProps,
  any
> {
  private routerService: RouterService;
  private authManager: AuthManager;

  constructor(props: IRouterGeneratorProps) {
    super(props);
    this.routerService = RouterService.getInstance();
    this.authManager = AuthManager.getInstance();
  }

  getRoutes() {
    let routes: any = [];
    for (let [key, value] of this.props.entries) {
      let router = value.router;
      if (router && Object.keys(router).length > 0) {
        // render all routes
        if (value.router.render) {
          routes.push(
            <GuardedRoute
              key={key}
              exact={value.router.exact}
              strict={value.router.strict}
              path={key}
              render={value.router.render}
              meta={{ router: router }}
              guards={router.guards || []}
            />
          );
        } else if (
          value.router.component &&
          this.authManager.isUserAllowed(value.router.roles)
        ) {
          routes.push(
            <GuardedRoute
              key={key}
              exact={value.router.exact}
              strict={value.router.strict}
              path={key}
              component={value.router.component}
              meta={{ router: router }}
              guards={router.guards || []}
            />
          );
        }
      }
    }
    /*
     * this MUST be the last route because <Switch> of react-router-dom returns FIRST matching route
     */
    routes.push(
      <GuardedRoute
        key={RouteEnum.FALLBACK}
        path={RouteEnum.FALLBACK}
        render={() => {
          if (this.routerService.isUserAuthenticated()) {
            return <Redirect to={RouteEnum.HOME} />;
          } else {
            return <Redirect to={RouteEnum.LOGIN} />;
          }
        }}
      />
    );
    return routes;
  }

  render() {
    return (
      <GuardProvider
        guards={[
          (to: any, from: any, next: any) =>
            this.routerService.isAllowedRoute(to, from, next),
        ]}
      >
        <Switch>{this.getRoutes()}</Switch>
      </GuardProvider>
    );
  }
}
