// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Book } from '../entities';
// @ts-ignore
import { BookCreateModel } from '../entities';
// @ts-ignore
import { BookSearchRequest } from '../entities';
// @ts-ignore
import { BookSearchResponse } from '../entities';
// @ts-ignore
import { JsonErrorResponse } from '../entities';
/**
 * BookControllerApi - axios parameter creator
 * @export
 */
export const BookControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create new book
         * @param {BookCreateModel} bookCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook: async (bookCreateModel: BookCreateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookCreateModel' is not null or undefined
            if (bookCreateModel === null || bookCreateModel === undefined) {
                throw new RequiredError('bookCreateModel','Required parameter bookCreateModel was null or undefined when calling createBook.');
            }
            const localVarPath = `/book/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bookCreateModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bookCreateModel !== undefined ? bookCreateModel : {}) : (bookCreateModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete book by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBookById.');
            }
            const localVarPath = `/book/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get book by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBookById.');
            }
            const localVarPath = `/book/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * patch book by id
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBook: async (id: number, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchBook.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchBook.');
            }
            const localVarPath = `/book/{id}/patch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of books matching sorting, filtering and pagination constraints
         * @param {BookSearchRequest} bookSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBook: async (bookSearchRequest: BookSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookSearchRequest' is not null or undefined
            if (bookSearchRequest === null || bookSearchRequest === undefined) {
                throw new RequiredError('bookSearchRequest','Required parameter bookSearchRequest was null or undefined when calling searchBook.');
            }
            const localVarPath = `/book/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bookSearchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bookSearchRequest !== undefined ? bookSearchRequest : {}) : (bookSearchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * upload document
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBook: async (id: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadBook.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadBook.');
            }
            const localVarPath = `/book/{id}/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookControllerApi - functional programming interface
 * @export
 */
export const BookControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * create new book
         * @param {BookCreateModel} bookCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBook(bookCreateModel: BookCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Book>> {
            const localVarAxiosArgs = await BookControllerApiAxiosParamCreator(configuration).createBook(bookCreateModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete book by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBookById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BookControllerApiAxiosParamCreator(configuration).deleteBookById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get book by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Book>> {
            const localVarAxiosArgs = await BookControllerApiAxiosParamCreator(configuration).getBookById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * patch book by id
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBook(id: number, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Book>> {
            const localVarAxiosArgs = await BookControllerApiAxiosParamCreator(configuration).patchBook(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve a list of books matching sorting, filtering and pagination constraints
         * @param {BookSearchRequest} bookSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBook(bookSearchRequest: BookSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookSearchResponse>> {
            const localVarAxiosArgs = await BookControllerApiAxiosParamCreator(configuration).searchBook(bookSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * upload document
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBook(id: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Book>> {
            const localVarAxiosArgs = await BookControllerApiAxiosParamCreator(configuration).uploadBook(id, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BookControllerApi - factory interface
 * @export
 */
export const BookControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * create new book
         * @param {BookCreateModel} bookCreateModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook(bookCreateModel: BookCreateModel, options?: any): AxiosPromise<Book> {
            return BookControllerApiFp(configuration).createBook(bookCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * delete book by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookById(id: number, options?: any): AxiosPromise<void> {
            return BookControllerApiFp(configuration).deleteBookById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get book by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookById(id: number, options?: any): AxiosPromise<Book> {
            return BookControllerApiFp(configuration).getBookById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * patch book by id
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBook(id: number, body: object, options?: any): AxiosPromise<Book> {
            return BookControllerApiFp(configuration).patchBook(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve a list of books matching sorting, filtering and pagination constraints
         * @param {BookSearchRequest} bookSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBook(bookSearchRequest: BookSearchRequest, options?: any): AxiosPromise<BookSearchResponse> {
            return BookControllerApiFp(configuration).searchBook(bookSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * upload document
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBook(id: number, file: any, options?: any): AxiosPromise<Book> {
            return BookControllerApiFp(configuration).uploadBook(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookControllerApi - interface
 * @export
 * @interface BookControllerApi
 */
export interface BookControllerApiInterface {
    /**
     * create new book
     * @param {BookCreateModel} bookCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApiInterface
     */
    createBook(bookCreateModel: BookCreateModel, options?: any): AxiosPromise<Book>;

    /**
     * delete book by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApiInterface
     */
    deleteBookById(id: number, options?: any): AxiosPromise<void>;

    /**
     * get book by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApiInterface
     */
    getBookById(id: number, options?: any): AxiosPromise<Book>;

    /**
     * patch book by id
     * @param {number} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApiInterface
     */
    patchBook(id: number, body: object, options?: any): AxiosPromise<Book>;

    /**
     * retrieve a list of books matching sorting, filtering and pagination constraints
     * @param {BookSearchRequest} bookSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApiInterface
     */
    searchBook(bookSearchRequest: BookSearchRequest, options?: any): AxiosPromise<BookSearchResponse>;

    /**
     * upload document
     * @param {number} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApiInterface
     */
    uploadBook(id: number, file: any, options?: any): AxiosPromise<Book>;

}

/**
 * BookControllerApi - object-oriented interface
 * @export
 * @class BookControllerApi
 * @extends {BaseAPI}
 */
export class BookControllerApi extends BaseAPI implements BookControllerApiInterface {
    /**
     * create new book
     * @param {BookCreateModel} bookCreateModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApi
     */
    public createBook(bookCreateModel: BookCreateModel, options?: any) {
        return BookControllerApiFp(this.configuration).createBook(bookCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete book by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApi
     */
    public deleteBookById(id: number, options?: any) {
        return BookControllerApiFp(this.configuration).deleteBookById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get book by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApi
     */
    public getBookById(id: number, options?: any) {
        return BookControllerApiFp(this.configuration).getBookById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * patch book by id
     * @param {number} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApi
     */
    public patchBook(id: number, body: object, options?: any) {
        return BookControllerApiFp(this.configuration).patchBook(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of books matching sorting, filtering and pagination constraints
     * @param {BookSearchRequest} bookSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApi
     */
    public searchBook(bookSearchRequest: BookSearchRequest, options?: any) {
        return BookControllerApiFp(this.configuration).searchBook(bookSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * upload document
     * @param {number} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookControllerApi
     */
    public uploadBook(id: number, file: any, options?: any) {
        return BookControllerApiFp(this.configuration).uploadBook(id, file, options).then((request) => request(this.axios, this.basePath));
    }

}
