import { ReduxAction } from "@hypereact/state";
import { ReduxSlices } from "../config/enums/redux.slices";
import { LanguageEnum } from "../shared/enums/language.enum";
import { ConfigState } from "../shared/states/config.state";
enum ConfigActions {
    SetLanguage = "CONFIG_SET_LANGUAGE",
    SetPanelsMenu = "CONFIG_SET_PANELS_MENU",
    SetTypeConfig = "CONFIG_SET_TYPE_CONFIG",
    SetDefaultConfig = "CONFIG_SET_DEFAULT_CONFIG",
    SetUserConfig = "CONFIG_SET_USER_CONFIG",
    SetLocationConfig = "CONFIG_SET_LOCATION_CONFIG",
    SetCompanyConfig = "CONFIG_SET_COMPANY_CONFIG",
    SetSeniorityConfig = "CONFIG_SET_SENIORITY_CONFIG",
    SetSkillConfig = "CONFIG_SET_SKILL_CONFIG",
    SetFamilyConfig = "CONFIG_SET_FAMILY_CONFIG",
    SetSpecializationConfig = "CONFIG_SET_SPECIALIZATION_CONFIG",
    SetPrefixConfig = "CONFIG_SET_PREFIX_CONFIG",
    SetMotivationConfig = "CONFIG_SET_MOTIVATION_CONFIG",
    SetPreassessmentConfig = "CONFIG_SET_PREASSESSMENT_CONFIG",
}

@ReduxAction(ConfigActions.SetLanguage, ReduxSlices.Config)
export class ConfigSetLanguageAction {
    constructor(public language: LanguageEnum) {}

    reduce(configState: ConfigState) {
        configState.language = this.language;
        return configState;
    }
}

@ReduxAction(ConfigActions.SetPanelsMenu, ReduxSlices.Config)
export class ConfigSetPanelsMenuAction {
    constructor(public menu: boolean) {}

    reduce(configState: ConfigState) {
        configState.panels.menu = this.menu;
        return configState;
    }
}

@ReduxAction(ConfigActions.SetDefaultConfig, ReduxSlices.Config)
export class ConfigSetDefaultConfigAction {
    constructor(public config: any) {}

    reduce(configState: ConfigState) {
        configState.config.default = this.config;
        return configState;
    }
}
@ReduxAction(ConfigActions.SetUserConfig, ReduxSlices.Config)
export class ConfigSetUserConfigAction {
    constructor(public config: any) {}

    reduce(configState: ConfigState) {
        configState.config.user = this.config;
        return configState;
    }
}


