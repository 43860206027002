import { ReduxConnect, StoreManager } from "@hypereact/state";
import {
  IconButton
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import React from "react";
import { IntlContext } from "react-intl";
import { ConfigSetPanelsMenuAction } from "../../../actions/config.actions";
import Expand from "../../../assets/icons/Menu-Icon/Expand.svg";
import LogOut from "../../../assets/icons/Menu-Icon/Logout.svg";
import Reduce from "../../../assets/icons/Menu-Icon/Reduce.svg";
import { MenuConfig } from "../../../config/menu.config";
import { ReduxState } from "../../../config/redux.config";
import { AuthManager } from "../../../managers/auth.manager";
import { PopupManager } from "../../../managers/popup.manager";
import { AuthEnum } from "../../enums/auth.enum";
import { RouteEnum } from "../../enums/route.enum";
import { NavigationService } from "../../services/navigation.service";
import { ConfigState } from "../../states/config.state";
import { NavigationState } from "../../states/navigation.state";
import { UserState } from "../../states/user.state";
import { PopupType } from "../Popup/popup.item";
import { IMenuItemProps, MenuItem } from "./menu.item.element";


interface IMenuContainerProps {
  user?: UserState;
  config?: ConfigState;
  navigation?: NavigationState;
}

@ReduxConnect((state: ReduxState) => {
  return {
    user: state.user,
    config: state.config,
    navigation: state.navigation,
  };
})
export class MenuContainer extends React.Component<IMenuContainerProps> {
  private storeManager: StoreManager;
  private authManager: AuthManager;
  private navigationService: NavigationService;
  private menuConfig: MenuConfig;


  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;


  constructor(props: IMenuContainerProps) {
    super(props);

    this.authManager = AuthManager.getInstance();
    this.storeManager = StoreManager.getInstance();
    this.navigationService = NavigationService.getInstance();
    this.menuConfig = MenuConfig.getInstance();

  }

  handleDrawerClick() {
    const { config } = this.props;
    this.storeManager.dispatch(
      new ConfigSetPanelsMenuAction(!config?.panels.menu)
    );
  }

  async handleOpenDialog() {
  
    PopupManager.getInstance().show({
      type: PopupType.Info,
      title: 'popup.menu.logout.title',
      description: 'popup.menu.logout.description',
      maxWidth: 'xs',
      stylingProp: "popup-display",
        ctas: {
          primary: {
            label: "popup.button.ok",
            hide: false,
            className: "popup-ok-button",
            cta: () => this.handleLogoutOnClick()
          },
          secondary: {
            hide: true
          }
        }
    })

  }

  async handleLogoutOnClick() {
    await this.authManager.logout();
    window.location.href = process.env.REACT_APP_ROUTER_BASEHREF + "login";
  }

  handleProfileOnClick() {
   // this.navigationService.goto(RouteEnum.USER_PROFILE);
  }

  handleInitialsOnClick() {
    //this.navigationService.goto(RouteEnum.USER_PROFILE);
  }

  handleOnCloseDialog() {
    // this.popupManager.close();
  }

  render() {
    const { user, config, navigation } = this.props;

    const menuOpen = config?.panels?.menu;
    const menuItems = this.menuConfig.getFilteredMenuItems();
    
    const initials = user?.personalData?.data?.details?.initials;
    const isLoginPage: boolean =
      this.navigationService.getCurrentRoute() === RouteEnum.LOGIN;
    const isUserAuthenticated: boolean = user?.state === AuthEnum.Login;
  
    let showMenu: boolean =
      isUserAuthenticated && !isLoginPage;
    return (
      <>
        {showMenu && (
          <>
            <Drawer
              variant="permanent"
              className={`drawer ${menuOpen ? "drawerOpen" : "drawerClose"}`}
              classes={{
                paper: `drawerPaper ${menuOpen ? "drawerOpen" : "drawerClose"}`,
              }}
            >
              <div>
                {/* <div className="toolbar" >
                  <div
                    className="initials"
                    onClick={() => this.handleInitialsOnClick()}
                  >
                    <Avatar className="avatar">{initials}</Avatar>
                  </div>
                </div>
                <Divider className="toolbar-divider1" /> */}
                <List>
                  {menuItems.map((item: IMenuItemProps, index: number) => (                    
                    <div key={index}>
                      <MenuItem
                        
                        route={item.route}
                        name={item.name}
                        onClick={item.onClick}
                        selected={item.route === navigation.url}
                        icon={<img src={item.icon as string} />}
                      />
                    </div>
                  ))}
                </List>
              </div>
              <List className="toolbar-bottom">
                <div>
                  <MenuItem
                    name="popup.menu.logout.title"
                    onClick={() => this.handleOpenDialog()}
                    icon={<img src={LogOut} />}
                  />
                </div>
                <div className='arrow shiftTextLeft' style={{ backgroundColor: '#DFE1E6' }}>
                  <IconButton onClick={() => this.handleDrawerClick()} >
                    {menuOpen ? <img src={Reduce} /> : <img src={Expand} />}
                  </IconButton>
                </div>
              </List>
            </Drawer>
          </>
        )}
      </>
    );
  }
}
