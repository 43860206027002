import { validationMetadatasToSchemas } from "class-validator-jsonschema";
import { getFromContainer, MetadataStorage } from "class-validator";
import * as yup from "yup";
import { ClassType } from "../types/class.type";
import { SchemaObject } from "openapi3-ts";

export interface IValidationType {
    maxLength: number;
}

export class ValidationService {
    static getFormHookSchemaFromClass<T extends object>(
        classType: ClassType<T>
    ) {
        return ValidationService.getFormHookSchemaFromClassName(
            (<any>classType).name
        );
    }

    static getFormHookSchemaFromClassName(className: string) {
        const metadatas: any = (getFromContainer(MetadataStorage) as any)
            .validationMetadatas;
        const schemas: any = validationMetadatasToSchemas(metadatas);
        const classSchema: any = schemas[className];

        let transcodedSchema: any = {};

        if (classSchema?.properties) {
            for (let property in classSchema.properties) {
                transcodedSchema[
                    property
                ] = ValidationService.getYupShapeFromSchema(
                    property,
                    classSchema
                );
            }
        }

        let schema = yup.object().shape(transcodedSchema);

        return schema;
    }

    private static getYupShapeFromSchema(
        property: string,
        parentSchema: any
    ): any {
        let propertySchema = parentSchema.properties[property];
        let propertyRequired = parentSchema.required.includes(property);

        let yupShape = null;
        switch (propertySchema.type) {
            case "boolean":
                yupShape = yup.boolean();
                break;
            case "integer":
            case "number":
                yupShape = yup.number();
                if (propertySchema.minimum) {
                    yupShape = yupShape?.min(propertySchema.minimum);
                }
                if (propertySchema.maximum) {
                    yupShape = yupShape?.max(propertySchema.maximum);
                }
                if (propertySchema.exclusiveMinimum) {
                    yupShape = yupShape?.positive();
                }
                if (propertySchema.exclusiveMaximum) {
                    yupShape = yupShape?.negative();
                }
                break;
            case "string":
                yupShape = yup.string().trim();
                if (propertySchema.minLength) {
                    yupShape = yupShape?.min(propertySchema.minLength);
                }
                if (propertySchema.maxLength) {
                    yupShape = yupShape?.max(propertySchema.maxLength);
                }
                if (propertySchema.format === "email") {
                    yupShape = yupShape?.email();
                }
                if (propertySchema.pattern) {
                    yupShape = yupShape?.matches(propertySchema.pattern);
                }
                break;
            case "array":
                yupShape = yup.array();
                if (propertySchema.minItems) {
                    yupShape = yupShape?.min(propertySchema.minItems);
                }
                if (propertySchema.maxItems) {
                    yupShape = yupShape?.max(propertySchema.maxItems);
                }
                break;
            default:
                if (propertySchema.oneOf) {
                    for (let { format, type } of propertySchema.oneOf) {
                        if (format === "date" || format === "date-time") {
                            yupShape = yup.date();
                        }
                    }
                } else {
                    throw new Error(
                        `property schema type is unknown for property ${property}`
                    );
                }
        }

        if (propertyRequired) {
            yupShape = yupShape?.required();
        }
        return yupShape;
    }

    static getValidations(className: string): SchemaObject {
        const schemas = validationMetadatasToSchemas();
        const obj = schemas[className];
        if (!obj) {
            throw new Error(`{classnName} not found into schemas`);
        }

        return obj;
    }

    static extractFromSchemaObject(
        schema: SchemaObject,
        attribute: string
    ): IValidationType {
        if (schema.properties && schema.properties[attribute]) {
            return schema.properties[attribute] as IValidationType;
        }
        throw new Error(
            `attribute ${attribute} not found into schema ${JSON.stringify(
                schema
            )}`
        );
    }
}
