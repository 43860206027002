import { ReduxAction } from "@hypereact/state";
import { ReduxSlices } from "../config/enums/redux.slices";
import { UserState } from "../shared/states/user.state";
import { AuthEnum } from "../shared/enums/auth.enum";
import { Institute, User } from "../api/entities";

enum LoginActions {
  SetUser = "LOGIN_SET_USER",
  SetState = "LOGIN_SET_STATE",
  SetInstitute = "LOGIN_SET_INSTITUTE"
}

@ReduxAction(LoginActions.SetUser, ReduxSlices.User)
export class LoginSetUserAction {
  constructor(public user: User, public state: AuthEnum, public config: any) {}

  reduce(userState: UserState) {
    userState.state = this.state;
    userState.personalData = this.user;
    userState.config = this.config;
    return userState;
  }
}

@ReduxAction(LoginActions.SetInstitute, ReduxSlices.User)
export class LoginSetInstituteAction {
  constructor(public institute: Institute) {}

  reduce(userState: UserState) {
    userState.institute = this.institute;
    return userState;
  }
}

@ReduxAction(LoginActions.SetState, ReduxSlices.User)
export class LoginSetStateAction {
  constructor(public state: AuthEnum) {}

  reduce(userState: UserState) {
    userState.state = this.state;
    return userState;
  }
}
