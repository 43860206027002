import {
    IReduxConfig,
    PersistentReduceableReducer,
    ReduceableReducer,
    StoreManager
} from "@hypereact/state";
import { Institute } from "../api/entities";
import { AuthManager } from "../managers/auth.manager";
import { InstituteManager } from "../managers/institute.manager";
import { AuthEnum } from "../shared/enums/auth.enum";
import { BookState, initialBookState } from "../shared/states/book.state";

import {
    ConfigState, initialConfigState
} from "../shared/states/config.state";

import { HttpState, initialHttpState } from "../shared/states/http.state";
import { initialInstituteState, InstituteState } from "../shared/states/institute.state";

import {
    initialNavigationState, NavigationState
} from "../shared/states/navigation.state";
import { BooksStatisticsState, initialBookStatisticsState, initialInstituteStatisticsState, initialStatisticsState, InstituteStatisticsState, StatisticsState } from "../shared/states/statistics.state";
import {
    initialUserState,
    PersistedUserState, UserState
} from "../shared/states/user.state";
import { ReduxSlices } from "./enums/redux.slices";


export interface ReduxState {
    book: BookState;
    user: UserState;
    http: HttpState;
    navigation: NavigationState;
    config: ConfigState;
    institute: InstituteState
    statistics: StatisticsState;
    bookStatistics: BooksStatisticsState;
    instituteStatistics: InstituteStatisticsState;

}

export class UserReducer extends PersistentReduceableReducer<UserState> {
    private authManager: AuthManager;
    constructor(state: UserState) {
        super(state);
        this.authManager = AuthManager.getInstance();
    }

    dehydrate(
        userState: UserState,
        manager: StoreManager
    ): PersistedUserState | null {
        if (userState.state === AuthEnum.Login) {
            return {
                state: userState.state,
                password : userState.password!,
                email : userState.email!,
                personalData: {
                    id: userState.personalData?.id!,
                },
                institute: userState.institute
            };
        }
        return null;
    }

    // rehydration includes user details fetching from the server if the user was authenticated
    async rehydrate(
        state: UserState,
        data: PersistedUserState,
        root: any,
        manager: StoreManager
    ): Promise<UserState> {
        // if user was authenticated
        if (data.state === AuthEnum.Login) {
            try {
                const user = await this.authManager.check();
                let institute: Institute = undefined;
                if(user.data.externals.institute.id) {
                    institute = await InstituteManager.getInstance().getById(user.data.externals.institute.id);
                }
                return {
                    state: AuthEnum.Login,
                    personalData: user,
                    institute: institute
                };
            } catch (e) {
                // tokens/cookies expired or not anymore valid
            }
        }
        // initial (not authenticated user) state
        return state;
    }
}



export const ReducerConfig: IReduxConfig = {
    [ReduxSlices.Book]: new ReduceableReducer(initialBookState),
    [ReduxSlices.User]: new UserReducer(initialUserState),
    [ReduxSlices.Http]: new ReduceableReducer(initialHttpState),
    [ReduxSlices.Navigation]: new ReduceableReducer(initialNavigationState),
    [ReduxSlices.Config]: new ReduceableReducer(initialConfigState),
    [ReduxSlices.Statistics]: new ReduceableReducer(initialStatisticsState),
    [ReduxSlices.BookStatistics]: new ReduceableReducer(initialBookStatisticsState),
    [ReduxSlices.InstituteStatistics]: new ReduceableReducer(initialInstituteStatisticsState),
    [ReduxSlices.Institute]: new ReduceableReducer(initialInstituteState)
    
};
