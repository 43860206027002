import {
  Button,
  Grid,




  MenuItem,

  TextField
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import CaretDownEmpty from "../../../assets/icons/Form-icon/Caret-down_empty.svg";
import OrderAsc from "../../../assets/icons/Mix/Order_asc.svg";
import OrderDesc from "../../../assets/icons/Mix/Order_desc.svg";
import { OrderDirectionEnum } from "../../enums/order.direction.enum";
import { Order } from "../../interfaces/list/order.interface";
import TooltipComponent from "../TooltipComponent/tooltip.component";
import { IFilterBarItemProps } from "./filterbar.item.interface";


const directionItems: OrderDirectionEnum[] = [
  OrderDirectionEnum.ASC,
  OrderDirectionEnum.DESC,
];

interface IOrderFilterBarItemState {
  orderList?: string[];
  open: boolean;
  isASC: boolean;
  isStatusShown: boolean;
}
interface OrderFilterBarItemProps extends IFilterBarItemProps {
  value: Order;
  items: string[];
  iconName: string;
  onChange: (value: Order) => void;
}
export class OrderFilterBarItem extends React.Component<
  OrderFilterBarItemProps,
  IOrderFilterBarItemState
> {
  orderType: string = OrderDesc;


  constructor(props: OrderFilterBarItemProps) {
    super(props);
    this.state = {
      orderList: [],
      open: false,
      isASC: true,
      isStatusShown: false,
    };
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  public set _iconName(iconName: string) {
    this._iconName = iconName;
  }

  handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    let order = {
      ...this.props.value,
      [event.target.name]: event.target.value,
    };

    this.handleClose();
    this.props.onChange(order);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleOrder = () => {
    this.setState({ isASC: !this.state.isASC });
    let order: { by: string; direction: OrderDirectionEnum };
    if (this.state.isASC) {
      this.orderType = OrderAsc;
      order = {
        ...this.props.value,
        direction: OrderDirectionEnum.ASC,
      };
    } else {
      this.orderType = OrderDesc;
      order = {
        ...this.props.value,
        direction: OrderDirectionEnum.DESC,
      };
    }
    this.props.onChange(order);
  };

  render() {
    
    const { name, value, items } = this.props;
    return (
      <Grid  className={value.by === "updatedAt" ? "order-button-background" : "order-button-background-color"}>
        <div className="order-text-style orderStyle">
          <FormattedMessage id={`filter.bar.${name}.by.placeholder`} />
        </div>
        <Grid item>
          <TextField
            className="borderStyle-fieldset"
            name="by"
            size="small"
            select
            value={value.by}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.handleOnChange(event)
            }
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                elevation:5
              },
              disableUnderline: true,
              open: this.state.open,
              onOpen: () => {
                this.handleOpen();
              },
              onClose: () => {
                this.handleClose();
              },

              IconComponent: () => (
                <Button
                  className="button-order-filter"
                  onClick={() => {
                    this.handleOpen();
                  }}
                >
                  {" "}
                  <img src={CaretDownEmpty} />
                </Button>
              ),
            }}
          >
            {items.map((item: string) => (
              <MenuItem
                key={item}
                value={item}
                className="order-text-style orderStyle"
              >
                <FormattedMessage id={`filter.bar.${name}.by.${item}`} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TooltipComponent
            placement="right"
            title={`${this.context.formatMessage({
              id: `order.tooltip.label`,
            })}`}
          >
            <Button
              className="button-order-filter"
              onClick={() => {
                this.handleOrder();
              }}
            >
              <img src={this.orderType} className="order-asc" />
            </Button>
          </TooltipComponent>
        </Grid>
      </Grid>
    );
  }
}
