import { InstituteDetailsInterface } from "../../feature/institute/beans/institute.details.data";
import { Institute, InstituteSearchFilter } from "../../api/entities";
import { ContextEnum } from "../enums/context.enum";
import { SharedState } from "./shared.state";
import { OrderDirectionEnum } from "../enums/order.direction.enum";

export interface InstituteState extends SharedState<Institute, InstituteSearchFilter> {
        createUpdate: InstituteCreateUpdate, 
        showClearFilter: boolean
}

export interface InstituteCreateUpdate {
    details: InstituteDetailsInterface;
    context?: ContextEnum;
}

export const initialInstituteState: InstituteState = {
    items: [],
    order: {
        by: "updatedAt",
        direction: OrderDirectionEnum.DESC,
      },
    filters: {
        grade: "O1,O2"
    },
    createUpdate: {
        details: {
            code: ""
        },
        context: undefined
    },
    pagination: {
        page: 0,
        size: 10,
        total: 0
    },
    showClearFilter: false
}