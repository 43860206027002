import React, { ReactElement, useEffect, useState } from "react";
import { ToastManager } from "../../../managers/toast.manager";

let toastManager: ToastManager;

const ToastContainer: React.FC = () => {
    const [toast, setToast] = useState<ReactElement | null>(null);

    useEffect(() => {
        toastManager = ToastManager.getInstance(setToast);
    }, []);

    return toast;
}

export default ToastContainer;
