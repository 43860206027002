import React, { ReactElement } from "react";
import PopupItem, { PopupItemProps } from "../shared/components/Popup/popup.item";
import { NotificationManager } from "./notification.manager";

type Popup = ReactElement | null;

export class PopupManager extends NotificationManager {
  private static instance: PopupManager;
  private set: (popup: Popup) => void;

  static getInstance(set?: (popup: Popup) => void) {
    if (PopupManager.instance == null) {
      if (!set){
        throw new Error('popup manager: setter method must be defined at initialization time');
      }
      else PopupManager.instance = new PopupManager(set);
    }
    return PopupManager.instance;
  }

  private constructor(set: (popup: Popup) => void) {
    super();
    this.set = set;
  }
  
  show(props: PopupItemProps) {
    const popupItem: ReactElement = React.createElement(PopupItem, props);
    this.set(popupItem);
  }

  hide() {
    this.set(null);
  }
}
