import { StoreManager } from "@hypereact/state";
import { Filter } from "@material-ui/icons";
import {
  InstituteSetContextAction,
  InstituteSetFormAction,
  InstituteSetInstituteKEYInstitute,
  InstituteSetInstituteURLInstitute,
  InstituteSetList,
  InstituteSetListDetails,
  InstituteSetPaginationAction,
  InstituteSetSelectedInstitute,
  InstituteSetStudentKEYInstitute,
  InstituteSetStudentURLInstitute,
  ResetInstituteSearchFilters,
  SetInstituteSearchGrade,
  SetInstituteSearchOrder,
  SetInstituteSearchQuery,
  SetInstituteSearchShowClearFilter,
} from "../actions/institute.actions";
import {
  Institute,
  InstituteCreateModel,
  InstituteGradeEnum,
  InstituteSearchFilter,
  InstituteSearchResponse,
} from "../api/entities";
import { ReduxSlices } from "../config/enums/redux.slices";
import {
  IInstituteApiService,
  InstituteApiService,
} from "../services/institute/institute.api.service";
import { ContextEnum } from "../shared/enums/context.enum";
import { FilterBarEnum } from "../shared/enums/filterBar.enum";
import { Config } from "../shared/interfaces/list/config.interface";
import { ConfigState } from "../shared/states/config.state";
import { initialInstituteState, InstituteState } from "../shared/states/institute.state";
import { UserState } from "../shared/states/user.state";
import { ProgressUtil } from "../shared/utils/progress.util";
import { AuthManager } from "./auth.manager";
import { ConfigManager } from "./config.manager";
import { GradeEnum } from "./statistics.manager";

export interface IInstituteManager {
  create(institute: InstituteCreateModel): Promise<Institute>;
  getById(id: number): Promise<Institute>;
  patch(
    oldInstitute: Institute,
    updatedInstitute: Institute
  ): Promise<Institute>;
  search(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: InstituteSearchFilter
  ): Promise<InstituteSearchResponse>;
}

export class InstituteManager implements IInstituteManager {
  private static instance: InstituteManager;
  private instituteService: IInstituteApiService;
  private authManager: AuthManager;
  private storeManager: StoreManager;
  private configManager: ConfigManager;
  private progressUtil: ProgressUtil;

  private constructor() {
    this.instituteService = InstituteApiService.getInstance(
      process.env.REACT_APP_MICROSERVICE_BIP_BASEPATH!
    );
    this.storeManager = StoreManager.getInstance();
    this.authManager = AuthManager.getInstance();
    this.progressUtil = ProgressUtil.getInstance();
    this.configManager = ConfigManager.getInstance();
  }

  /**
   * Singleton getInstance
   * @returns The single instance of the institute manager
   */
  static getInstance(): InstituteManager {
    if (InstituteManager.instance == null) {
      InstituteManager.instance = new InstituteManager();
    }
    return InstituteManager.instance;
  }

  /**
   * Creates an institute
   * @param institute The institute to be created
   * @returns The created institute (including the id)
   */
  async create(institute: InstituteCreateModel): Promise<Institute> {
    return await this.progressUtil.queue(
      this.instituteService.create(institute),
      true,
      () => { }
    );
  }

  /**
   * Delets an institute by id
   * @param id The id of the institute to be delete
   * @returns
   */
  async delete(id: any) {
    return await this.progressUtil.queue(
      this.instituteService.delete(id),
      true,
      () => { }
    );
  }

  /**
   * Gets a institute by id
   * @param id The id of the institute to be got
   * @returns The institute with the specified id
   */
  async getById(id: number): Promise<Institute> {
    return await this.progressUtil.queue(
      this.instituteService.getById(id),
      true,
      () => { }
    );
  }

  /**
   * Patches the institute
   * @param oldInstitute The old institute to be patched with
   * @param updatedInstitute The updted institute
   */
  async patch(
    oldInstitute: Institute,
    updatedInstitute: Institute
  ): Promise<Institute> {
    if (oldInstitute.id! !== updatedInstitute.id) {
      throw new Error("the institutes identifiers are not equal");
    }
    return await this.progressUtil.queue(
      this.instituteService.patch(oldInstitute, updatedInstitute),
      true,
      () => { }
    );
  }

  private getUserState(): UserState {
    return this.storeManager.getState(ReduxSlices.User) as UserState;
  }

  /**
   * Searces the institutes in function of the searching criteria
   * @param page The page for pagination purposes
   * @param size The size for pagination purposes
   * @param query The keyword
   * @param sort The sorting options
   * @param filter The institute search filter
   * @returns The institutes that meet the criteria
   */
  async search(page?: number): Promise<InstituteSearchResponse> {
    const state = this.getState();
    const order = state.order
      ? {
        [state.order.by]: state?.order.direction,
      }
      : undefined;


    const response: InstituteSearchResponse = await this.progressUtil.queue(
      this.instituteService.search(
        page,
        state.pagination.size,
        state.query,
        order,
        state.filters
      ),
      true,
      () => { }
    );

    await this.storeManager.dispatch(
      new InstituteSetListDetails(
        response.page.page,
        response.page.size,
        response.page.total
      )
    );
    await this.storeManager.dispatch(new InstituteSetList(response.institutes));
    return response;
  }

  async setSelectedInstitute(id: number): Promise<Institute> {
    const institute: Institute = await this.getById(id);
    const userByInstitute = await this.authManager.getUserById(
      institute.instituteId
    );
    const userByStudent = await this.authManager.getUserById(
      institute.studentId
    );

    await this.storeManager.dispatch(
      new InstituteSetSelectedInstitute(institute)
    );
    await this.storeManager.dispatch(
      new InstituteSetContextAction(ContextEnum.UPDATE)
    );
    await this.storeManager.dispatch(
      new InstituteSetInstituteURLInstitute(userByInstitute)
    );
    await this.storeManager.dispatch(
      new InstituteSetInstituteKEYInstitute(userByInstitute)
    );
    await this.storeManager.dispatch(
      new InstituteSetStudentURLInstitute(userByStudent)
    );
    await this.storeManager.dispatch(
      new InstituteSetStudentKEYInstitute(userByStudent)
    );

    return institute;
  }

  async setFormField(key: string, value: any): Promise<void> {
    const response = await this.storeManager.dispatch(
      new InstituteSetFormAction(key, value)
    );
    return response;
  }

  getState(): InstituteState {
    return this.storeManager.getState(ReduxSlices.Institute) as InstituteState;
  }

  getConfig(items: Map<string, any>): Map<string, Config> {
    const instituteState = this.getState();
    let configMap: Map<string, Config> = new Map();
    let itemKeys = Array.from(items.keys());
    itemKeys.forEach((key) => {
      switch (key) {
        case FilterBarEnum.QUERY:
          configMap.set(FilterBarEnum.QUERY, {
            name: "institute",
            value: this.getState().query,
          });
          break;
        case FilterBarEnum.ORDER:
          configMap.set(FilterBarEnum.ORDER, {
            value: instituteState.order,
            items: this.configManager.getState().config.instituteSearch.items,
          });
          break;
        case FilterBarEnum.GRADE:
          const value: string[] = instituteState.filters.grade
            ? instituteState.filters.grade!.split(",")
            : [];
          configMap.set(FilterBarEnum.GRADE, {
            value: value,
            items: GradeEnum,
          });
          break;
      }
    });
    return configMap;
  }

  async onFilterChange(name: string, value: any) {
    const state = this.getState();
    switch (name) {
      case FilterBarEnum.QUERY:
        const stateQuery = state.query;
        const query = value !== "" ? value : undefined;
        this.storeManager.dispatch(new SetInstituteSearchQuery(value));
        if ((query !== stateQuery && query?.length! >= 3) || !query) {
          await this.search();
        }
        break;
      case FilterBarEnum.ORDER:
        this.storeManager.dispatch(new SetInstituteSearchOrder(value));
        await this.search();
        break;
      case FilterBarEnum.GRADE:
        this.storeManager.dispatch(new SetInstituteSearchGrade(value));
        await this.search();
        break;
      default:
        break;
    }

    this.storeManager.dispatch(new SetInstituteSearchShowClearFilter(true));
  }

  reset() {
    this.storeManager.dispatch(new ResetInstituteSearchFilters());
  }

  async resetContext(): Promise<void> {
    const response = await this.storeManager.dispatch(
      new InstituteSetContextAction(undefined)
    );
    return response;
  }

  async setPagination(page: number, size: number): Promise<void> {
    this.storeManager.dispatch(new InstituteSetPaginationAction(page, size));
    await this.search();
  }

  resetPagination(): void {
    this.storeManager.dispatch(new InstituteSetPaginationAction(initialInstituteState.pagination.page, initialInstituteState.pagination.size));
  }
}
