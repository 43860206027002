import { IReduceableAction, ReduxAction } from "@hypereact/state";
import { Institute, User } from "../api/entities";
import { ReduxSlices } from "../config/enums/redux.slices";
import { ContextEnum } from "../shared/enums/context.enum";
import { Order } from "../shared/interfaces/list/order.interface";
import { initialInstituteState, InstituteState } from "../shared/states/institute.state";

enum InstituteActions {
  setInstituteList = "SET_INSTITUTE_LIST",
  setInstituteURL = "SET_INSTITUTE_URL",
  setInstituteKEY = "SET_INSTITUTE_KEY",
  setStudentURL = "SET_STUDENT_URL",
  setStudentKEY = "SET_STUDENT_KEY",
  setSelectedInstitute = "SET_SELECTED_INSTITUTE",
  resetSelectedInsitute = "RESET_SELECTED_INSTITUTE",
  setContext = "INSTITUTE_SET_CONTEXT",
  resetForm = "RESET_FORM",
  setListDetails = "INSTITUTE_SET_LIST_DETAILS",
  setFormField = "INSTITUTE_SET_FORM_FIELD",
  setPagination = "INSTITUTE_SET_PAGINATION",
  setInstituteSearchQuery = "SET_INSTITUTE_SEARCH_QUERY",
  setInstituteSearchOrder = "SET_INSTITUTE_SEARCH_ORDER",
  setInstituteSearchGrade = "SET_INSTITUTE_SEARCH_GRADE",
  setInstituteSearchShowClearFilter = "SET_INSTITUTE_SEARCH_SHOW_CLEAR_FILTER",
  resetInstituteSearchFilters = "RESET_INSTITUTE_SEARCH_FILTERS"
}

@ReduxAction(
  InstituteActions.resetInstituteSearchFilters,
  ReduxSlices.Institute
)
export class ResetInstituteSearchFilters
  implements IReduceableAction<InstituteState>
{
  constructor() { }

  reduce(instituteState: InstituteState) {
    instituteState.filters = initialInstituteState.filters;
    instituteState.query = undefined;
    instituteState.order = initialInstituteState.order;
    instituteState.showClearFilter = false;
    return instituteState;
  }
}


@ReduxAction(
  InstituteActions.setInstituteSearchShowClearFilter,
  ReduxSlices.Institute
)
export class SetInstituteSearchShowClearFilter
  implements IReduceableAction<InstituteState>
{
  constructor(public showClearFilter: boolean) { }

  reduce(instituteState: InstituteState) {
    instituteState.showClearFilter = this.showClearFilter;
    return instituteState;
  }
}



@ReduxAction(
  InstituteActions.setInstituteSearchOrder,
  ReduxSlices.Institute
)
export class SetInstituteSearchOrder
  implements IReduceableAction<InstituteState>
{
  constructor(public order: Order) { }

  reduce(instituteState: InstituteState) {
    instituteState.order = this.order;
    if (
      instituteState.order != instituteState.order
    ) {
      instituteState.showClearFilter = true;
    }
    instituteState.pagination.page = 0;
    return instituteState;
  }
}

@ReduxAction(
  InstituteActions.setInstituteSearchGrade,
  ReduxSlices.Institute
)
export class SetInstituteSearchGrade
  implements IReduceableAction<InstituteState>
{
  constructor(public grades: string[]) { }

  reduce(instituteState: InstituteState) {
    instituteState.filters.grade = this.grades.join(',');

    if (instituteState.filters.grade != instituteState.filters.grade) {
      instituteState.showClearFilter = true;
    }
    instituteState.pagination.page = 0;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setInstituteSearchQuery, ReduxSlices.Institute)
export class SetInstituteSearchQuery {
  constructor(public searchQuery: string) { }
  reduce(instituteState: InstituteState) {
    instituteState.query = this.searchQuery;
    return instituteState;
  }
}


@ReduxAction(InstituteActions.setInstituteList, ReduxSlices.Institute)
export class InstituteSetList {
  constructor(public instituteList: Array<Institute>) { }

  reduce(instituteState: InstituteState) {
    instituteState.items = this.instituteList;
    return instituteState;
  }
}


@ReduxAction(InstituteActions.setSelectedInstitute, ReduxSlices.Institute)
export class InstituteSetSelectedInstitute {
  constructor(public institute: Institute) { }

  reduce(instituteState: InstituteState) {
    instituteState.item = this.institute;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setInstituteURL, ReduxSlices.Institute)
export class InstituteSetInstituteURLInstitute {
  constructor(public user: User) { }

  reduce(instituteState: InstituteState) {
    instituteState.createUpdate.details.instituteURL = this.user.email;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setInstituteKEY, ReduxSlices.Institute)
export class InstituteSetInstituteKEYInstitute {
  constructor(public user: User) { }

  reduce(instituteState: InstituteState) {
    instituteState.createUpdate.details.instituteKey = this.user.data.externals.institute.code;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setStudentURL, ReduxSlices.Institute)
export class InstituteSetStudentURLInstitute {
  constructor(public user: User) { }

  reduce(instituteState: InstituteState) {
    instituteState.createUpdate.details.studentURL = this.user.email;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setStudentKEY, ReduxSlices.Institute)
export class InstituteSetStudentKEYInstitute {
  constructor(public user: User) { }

  reduce(instituteState: InstituteState) {
    instituteState.createUpdate.details.studentKEY = this.user.data.externals.institute.code;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.resetSelectedInsitute, ReduxSlices.Institute)
export class InstituteResetSelectedInstitute {
  constructor() { }

  reduce(instituteState: InstituteState) {
    instituteState.item = undefined;
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setContext, ReduxSlices.Institute)
export class InstituteSetContextAction {
  constructor(public context: ContextEnum) { }

  reduce(instituteState: InstituteState) {
    instituteState.createUpdate = {
      ...initialInstituteState.createUpdate,
      context: this.context,
    }
    return instituteState;
  }
}

@ReduxAction(InstituteActions.resetForm, ReduxSlices.Institute)
export class InstituteResetFormAction {
  constructor() { }

  reduce(instituteState: InstituteState) {
    instituteState.createUpdate = {
      ...initialInstituteState.createUpdate,
      context: instituteState.createUpdate.context,
    }
    return instituteState;
  }
}

@ReduxAction(InstituteActions.setListDetails, ReduxSlices.Institute)
export class InstituteSetListDetails {
  constructor(public page: number, public size: number, public total: number) { }

  reduce(instituteState: InstituteState) {
    instituteState.pagination = {
      ...instituteState.pagination,
      page: this.page,
      size: this.size,
      total: this.total
    }
    return instituteState;
  }
}
@ReduxAction(InstituteActions.setFormField, ReduxSlices.Institute)
export class InstituteSetFormAction {
  constructor(public key: string, public value?: any) { }

  reduce(institute: InstituteState) {
    institute.createUpdate.details = {
      ...institute.createUpdate.details,
      [this.key]: this.value,
    };

    return institute;
  }
}

@ReduxAction(InstituteActions.setPagination, ReduxSlices.Institute)
export class InstituteSetPaginationAction {
  constructor(public page: number, public size: number) { }

  reduce(institute: InstituteState) {
    institute.pagination.page = this.page;
    institute.pagination.size = this.size;
    return institute;
  }
}