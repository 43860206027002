import React from "react";
import { Button, Grid } from "@material-ui/core";
import { FormattedMessage, IntlContext } from "react-intl";
import CloseIcon from "../../../assets/icons/Menu-Icon/Close_White.svg";

interface ClearFilterBarItemProps {
    onChange: () => void;
}

export class ClearFilterBarItem extends React.Component<ClearFilterBarItemProps> {
    handleOnChange() {
        this.props.onChange();
    }

    render() {
        return (
            <>
                <div>
                    <Button
                        className="lowercase clear-btn-style"
                        variant="contained"
                        disableElevation
                        onClick={() => this.handleOnChange()}
                    >
                        <FormattedMessage id={`filter.bar.clear.name`} />
                        <img src={CloseIcon} />
                    </Button>
                </div>
            </>
        );
    }
}
