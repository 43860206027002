import { StatsControllerApi } from "../../api";


export interface IStatisticsApiClients {
  statistics: StatsControllerApi;
}

export class StatisticsApiClients {
  private static instance: IStatisticsApiClients;

  private constructor() {}

  static getInstance(basePath: string): IStatisticsApiClients {
    if (StatisticsApiClients.instance == null) {
      StatisticsApiClients.instance = {
        statistics: new StatsControllerApi({
          basePath: basePath,
        })
      };
    }
    return StatisticsApiClients.instance;
  }
}
