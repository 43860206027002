import { LoginRequest, LoginResponse, User, UserSearchFilter, UserSearchResponse } from "../../api/entities";
import { AuthApiClients, IAuthApiClients } from "./auth.api.client";


export interface IAuthApiService {
  login(email: string, password: string): Promise<User>;
  getUserById(id: number): Promise<User>;
  check(): Promise<User>;
  logout(): Promise<void>;
  
  search(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: UserSearchFilter
  ): Promise<UserSearchResponse>;
}

export class AuthApiService implements IAuthApiService {
  protected static instance: IAuthApiService;
  protected api: IAuthApiClients;

  protected constructor(basePath: string) {
    this.api = AuthApiClients.getInstance(basePath);
  }

  static getInstance(basePath: string) {
    if (AuthApiService.instance == null) {
      AuthApiService.instance = new AuthApiService(basePath);
    }
    return AuthApiService.instance;
  }

  async login(email: string, password: string): Promise<User> {
    const loginRequest: LoginRequest = {
      email,
      password,
    };
    const loginResponse: LoginResponse = (
      await this.api.auth.login(loginRequest)
    ).data;
    const user: User = await this.getUserById(loginResponse.id!);
    return user;
  }

  async check(): Promise<User> {
    const checkResponse: LoginResponse = (await this.api.auth.check()).data;
    const user: User = await this.getUserById(checkResponse.id!);
    return user;
  }

  async getUserById(id: number): Promise<User> {
    return (await this.api.user.getUserById(id)).data;
  }

  async logout(): Promise<void> {
    await this.api.auth.logoff();
  }

  async search(
    page: number,
    size: number,
    query?: string,
    sort?: { [key: string]: string | undefined },
    filter?: UserSearchFilter
  ): Promise<UserSearchResponse> {
    return (
      await this.api.user.searchUsers({
        page,
        size,
        query,
        sort,
        filter,
      })
    ).data;
  }
}
