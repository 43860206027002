import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { FormattedMessage, IntlContext } from 'react-intl';
import { InstituteGradeEnum } from '../../../api/entities';
import { ActionType, FormManager } from '../../../managers/form.manager';
import { ISelectItem } from '../../../shared/components/SelectInput/select.input.outlined.component';
import SelectInput from '../../../shared/components/SelectInput/SelectInput';
import TextInput from "../../../shared/components/TextInput";
import { ContextEnum } from '../../../shared/enums/context.enum';
import { InstituteState } from '../../../shared/states/institute.state';
import { FormHelperUtil } from '../../../shared/utils/form.helper.util';
import { InstituteCreateFormData } from '../beans/institute.create.form.data';

interface IInstituteCreateForm {
    onActions: (actionType: ActionType, payload?: any) => void
    formValues?: InstituteState,
    onChange: (event: any) => void
}

const formFields: Pick<Record<keyof InstituteCreateFormData, string>, "code" | "title" | "grade" | "instituteKEY" | "instituteURL" | "studentURL" | "studentKEY"> = {
    code: 'code',
    title: 'title',
    grade: 'grade',
    instituteKEY: "instituteKEY",
    instituteURL: "instituteURL",
    studentURL: "studentURL",
    studentKEY: "studentKEY"
}

const InstituteCreateForm: React.FC<IInstituteCreateForm> = ({ onActions, formValues, onChange }) => {
    const formProxy = FormManager.getInstance().buildFormProxy<InstituteCreateFormData>(InstituteCreateFormData, onActions);
    const errors = formProxy.errors || {};
    const { handleSubmit, register, setValue, control } = formProxy;

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator("institute", intl);

    const schoolGradeItems = Object.keys(InstituteGradeEnum).map((key: string): ISelectItem => {
        return {
            name: <FormattedMessage id={`institute.grade.${InstituteGradeEnum[key]}`} />,
            value: InstituteGradeEnum[key]
        }
    })

    if (formValues?.createUpdate?.context === ContextEnum.UPDATE) {
        setValue(formFields.code, formValues.item?.code)
        setValue(formFields.title, formValues.item?.title)
        setValue(formFields.grade, formValues.item?.grade)
        setValue(formFields.instituteKEY, formValues.createUpdate.details?.instituteKey)
        setValue(formFields.instituteURL, formValues.createUpdate.details?.instituteURL)
        setValue(formFields.studentURL, formValues.createUpdate.details?.studentURL)
        setValue(formFields.studentKEY, formValues.createUpdate.details?.studentKEY)
    }


    return (
        <>
            <form id={'create-institute-form'} onSubmit={handleSubmit} className="textInputStyle" >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <TextInput
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={<FormattedMessage id={`institute.create.code.label`} />}
                            {...register('code')}
                            onChange={onChange}
                            value={formValues?.createUpdate?.details?.code ? formValues?.createUpdate?.details?.code : formValues?.item?.code ? formValues?.item?.code : undefined}
                            error={!!errors.code}
                            control={control}
                            helperText={errors.code && getMessageByFieldAndError('code', errors)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <SelectInput
                            value={formValues?.createUpdate.context === ContextEnum.UPDATE ? (formValues?.createUpdate.details.grade || formValues?.item.grade) : undefined}
                            name={formFields.grade}
                            label={
                                <FormattedMessage id="institute.create.grade.label" />
                            }
                            variant="outlined"
                            control={control}
                            error={!!errors.grade}
                            onChange={onChange}
                            items={schoolGradeItems}
                            helperText={errors.grade && getMessageByFieldAndError(formFields.grade, errors)}
                        />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <TextInput
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={<FormattedMessage id={`institute.create.title.label`} />}
                            {...register('title')}
                            error={!!errors.title}
                            onChange={onChange}
                            control={control}
                            value={formValues?.createUpdate?.details?.title ? formValues?.createUpdate?.details?.title : formValues?.item?.title ? formValues?.item?.title : undefined}
                            helperText={errors.code && getMessageByFieldAndError('title', errors)}
                        />
                    </Grid>
                    {formValues?.createUpdate?.context === ContextEnum.UPDATE && (
                        <>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={<FormattedMessage id={`institute.create.instituteURL.label`} />}
                                    InputLabelProps={{shrink: true}}
                                    disabled
                                    name="instituteURL"
                                    error={!!errors.instituteURL}
                                    onChange={onChange}
                                    control={control}
                                    value={formValues?.createUpdate?.details?.instituteURL}
                                    helperText={errors.code && getMessageByFieldAndError('instituteURL', errors)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={<FormattedMessage id={`institute.create.instituteKEY.label`} />}
                                    InputLabelProps={{shrink: true}}
                                    disabled
                                    name="instituteKEY"
                                    error={!!errors.instituteKEY}
                                    onChange={onChange}
                                    control={control}
                                    value={formValues?.createUpdate?.details?.instituteKey}
                                    helperText={errors.code && getMessageByFieldAndError('instituteKEY', errors)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={<FormattedMessage id={`institute.create.studentURL.label`} />}
                                    InputLabelProps={{shrink: true}}
                                    disabled
                                    name="studentURL"
                                    error={!!errors.studentURL}
                                    onChange={onChange}
                                    control={control}
                                    value={formValues?.createUpdate?.details?.studentURL}
                                    helperText={errors.code && getMessageByFieldAndError('studentURL', errors)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={<FormattedMessage id={`institute.create.studentKEY.label`} />}
                                    InputLabelProps={{shrink: true}}
                                    disabled
                                    name="studentKEY"
                                    error={!!errors.studentKEY}
                                    onChange={onChange}
                                    control={control}
                                    value={formValues?.createUpdate?.details?.studentKEY}
                                    helperText={errors.code && getMessageByFieldAndError('studentKEY', errors)}
                                />
                            </Grid>
                        </>
                    )}

                </Grid>
            </form>
        </>
    )
}

export default InstituteCreateForm