import { Divider, Grid } from "@material-ui/core";
import React, { ComponentElement } from "react";
import { Config } from "../../interfaces/list/config.interface";
import { ClearFilterBarItem } from "./clear.filterbar.item.component";
import { IFilterBarItemProps } from "./filterbar.item.interface";
import { RefreshFilterBarItem } from "./refresh.filterbar.item.component";

interface FilterBarProps {
  items: Map<string, React.Component<IFilterBarItemProps>>;
  config: Map<string, Config>;
  showClearFilter: boolean;
  onChange: (name: string, value?: any) => void;
  onClearFilters: () => void;
  onRefreshFilters: () => void;
}

export class FilterBar extends React.Component<FilterBarProps> {
  handleOnChange(name: string, value: any) {
    this.props.onChange(name, value);
  }

  handleClearFilters() {
    this.props.onClearFilters();
  }

  handleRefreshFilters() {
    this.props.onRefreshFilters();
  }

  createElement(): Array<
    ComponentElement<React.Component<IFilterBarItemProps>, any>
  > {
    let myComponents: Array<
      ComponentElement<React.Component<IFilterBarItemProps>, any>
    > = [];
    this.props.items.forEach(
      (component: React.Component<IFilterBarItemProps>, name: string) => {
        {
          let properties: IFilterBarItemProps = {
            name: this.props.config.get(name)?.name
              ? this.props.config.get(name)?.name!
              : name,
            value: this.props.config.get(name)?.value,
            items: this.props.config.get(name)?.items,
            onChange: (value?: any) => this.handleOnChange(name, value),
            onRefresh: () => this.handleRefreshFilters(),
          };
          myComponents.push(
            React.createElement(component as any, properties as any)
          );
        }
      }
    );
    return myComponents;
  }

  render() {
    const { showClearFilter } = this.props;
    const components = this.createElement();
    return (
      <Grid container spacing={6}>
        <Grid wrap='nowrap' container item xs={12} sm={6} md={3} lg={3} xl={3}>
          {components.map((component, index: number) => (
            <>
              {component}
            </>
          ))}
        </Grid>
        <Grid
          item
          container
          xs={2}
          sm={6}
          md={9}
          lg={9}
          xl={9}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Divider
            orientation="horizontal"
            flexItem
            className="divider-style"
          />
          {showClearFilter && (
            <ClearFilterBarItem onChange={() => this.handleClearFilters()} />
          )}
          <RefreshFilterBarItem onChange={() => this.handleRefreshFilters()} />
        </Grid>
      </Grid>
    );
  }
}
