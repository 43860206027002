import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { SubmitHandler, useForm, Mode } from "react-hook-form";
import { IFormProxy } from "../shared/interfaces/form/form.proxy.interface";
import { ValidationService } from "../shared/services/validation.service";

export type ClassType<T> = new (...args: any[]) => T;

export enum ActionType {
    SUBMIT = "SUBMIT",
    CHANGE = "CHANGE",
    BLUR = "BLUR",
}

type OnChangeType = {
    name: string;
    value: string;
};

export type PayloadType = OnChangeType;

export class FormManager {
    private static instance: FormManager | null;

    public static getInstance(): FormManager {
        if (FormManager.instance == null) {
            FormManager.instance = new FormManager();
        }
        return FormManager.instance;
    }

    private constructor() {}

    buildFormProxy<T>(
        model: ClassType<T>,
        onActions: (actionType: ActionType, payload?: any) => void,
        defaultValues?: any,
        mode?: Mode
    ): IFormProxy {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setError,
            control,
            trigger,
            watch,
            setValue,
        } = useForm<T>({
            mode,
            defaultValues,
            resolver: classValidatorResolver(model),
        });
        const onSubmit: SubmitHandler<T> = (data) =>
            onActions(ActionType.SUBMIT, data);
        const schemaObject = ValidationService.getValidations(model.name);
        const formProxy = {
            register,
            errors: errors || {},
            setError,
            name: model.name,
            handleSubmit: handleSubmit(onSubmit),
            control,
            trigger,
            watch,
            setValue,
            schemaObject: schemaObject,
        };
        return formProxy;
    }
}
