import { StoreManager } from "@hypereact/state";
import { ReduxSlices } from "../config/enums/redux.slices";
import { ConfigTypeEnum } from "../shared/enums/config.type.enum";
import { StatisticsOptionsEnum } from "../shared/enums/statistics.options";
import { ConfigState } from "../shared/states/config.state";

export interface IConfigManager {
  getConfigByType(
    configType: ConfigTypeEnum,
    option: StatisticsOptionsEnum
  ): any;
}

export class ConfigManager implements IConfigManager {
  private storeManager: StoreManager;
  private static instance: ConfigManager;

  private constructor() {
    this.storeManager = StoreManager.getInstance();
  }

  static getInstance() {
    if (ConfigManager.instance == null) {
      ConfigManager.instance = new ConfigManager();
    }
    return ConfigManager.instance;
  }

  public getState(): ConfigState {
    return this.storeManager.getState(ReduxSlices.Config) as ConfigState;
  }

  getConfigByType(
    configType: ConfigTypeEnum,
    option?: StatisticsOptionsEnum
  ): any {
    const config: ConfigState = this.getState();
    switch (configType) {
      case ConfigTypeEnum.STATISTICS:
        if (StatisticsOptionsEnum.Book === option) {
          return config.config.bookStatistics;
        } else if (StatisticsOptionsEnum.Institute === option) {
          return config.config.instituteStatistics;
        } else {
          return config.config.default;
        }
      case ConfigTypeEnum.INSTITUTES:
        return config.config.instituteSearch;
      case ConfigTypeEnum.BOOKS:
        return config.config.bookSearch;
      default:
        break;
    }
  }
}
