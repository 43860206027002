import { LoginView } from "../feature/auth/views/login.view";
import { BookView } from "../feature/book/views/book.view";
import { HomeView } from "../feature/home/views/home.view";
import { InstituteCreateView } from "../feature/institute/views/institute.create.view";
import { InstituteView } from "../feature/institute/views/institute.view";
import { StatisticsView } from "../feature/statistics/views/statistics.view";
import { RoleEnum } from "../shared/enums/role.enum";
import { RouteEnum } from "./../shared/enums/route.enum";
import { RouterService } from "./../shared/services/router.service";
import { BookCreateView } from "../feature/book/views/book.create.view";

interface IRouterConfig {
    getRoutes(): Map<string, any>;
}

export class RouterConfig implements IRouterConfig {
    private routerService: RouterService;
    private static instance: RouterConfig;

    constructor() {
        this.routerService = RouterService.getInstance();
    }

    static getInstance() {
        if (RouterConfig.instance == null) {
            RouterConfig.instance = new RouterConfig();
        }
        return RouterConfig.instance;
    }

    getRoutes(): Map<string, any> {
        return new Map(
            Object.entries({
                [RouteEnum.ROOT]: {
                    router: {
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleNonAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        name: "navigation.page.root.title",
                    },
                },
                // auth
                [RouteEnum.LOGIN]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: LoginView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleNonAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.login.title",
                    },
                    ui: {
                        navbar: false,
                        menu: false,
                    },
                },
                [RouteEnum.HOME]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: HomeView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null
                    }
                },
                [RouteEnum.STATISTICS]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: StatisticsView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.statistics.title",
                        roles: [
                            RoleEnum.ROLE_ADMIN
                        ],
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
                [RouteEnum.BOOK]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: BookView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.book.title",
                        roles: [
                            RoleEnum.ROLE_ADMIN,
                            RoleEnum.ROLE_INSTITUTE,
                            RoleEnum.ROLE_STUDENT
                        ],
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
                [RouteEnum.BOOK_CREATE]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: BookCreateView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.institute.title",
                        roles: [RoleEnum.ROLE_ADMIN],
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
                [RouteEnum.INSTITUTE]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: InstituteView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.institute.title",
                        roles: [RoleEnum.ROLE_ADMIN],
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
                [RouteEnum.INSTITUTE_CREATE]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: InstituteCreateView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.institute.create.title",
                        roles: [RoleEnum.ROLE_ADMIN],
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
                [RouteEnum.BOOK]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: BookView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.book.title",
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
                [RouteEnum.BOOK_CREATE]: {
                    router: {
                        exact: true,
                        strict: true,
                        component: BookCreateView,
                        guards: [
                            (to: any, from: any, next: any) =>
                                this.routerService.handleAuthRoute(
                                    to,
                                    from,
                                    next
                                ),
                        ],
                        parent: null,
                        name: "navigation.page.book.create.title",
                    },
                    ui: {
                        navbar: true,
                        menu: true,
                    },
                },
            })
        );
    }
}
function props(props: any, any: any) {
    throw new Error("Function not implemented.");
}
