import { StoreManager } from "@hypereact/state/js/lib/managers/store.manager";
import axios from "axios";
import {
  SetRequestPendingAction,
  SetRequestCompletedAction,
} from "../../actions/http.actions";
import { NotificationFactory } from "../../managers/notification.factory";
import { PopupItemCTA } from "../components/Popup/popup.item";

export class ProgressUtil {
  private static instance: ProgressUtil;
  private storeManager: StoreManager;
  

  private constructor() {
    this.storeManager = StoreManager.getInstance();
  
  }

  static getInstance() {
    if (ProgressUtil.instance == null) {
      ProgressUtil.instance = new ProgressUtil();
    }
    return ProgressUtil.instance;
  }

  async queue<T>(
    task: Promise<T> | void,
    showPopup?: boolean,
    callback?: ((prop?: any) => void) | Map<string, (prop?: any) => void>
  ): Promise<T> {
    this.storeManager.dispatch(new SetRequestPendingAction());
    try {
      const result: any = await task;
      this.storeManager.dispatch(new SetRequestCompletedAction());
      return result;
    } catch (e) {
      this.storeManager.dispatch(new SetRequestCompletedAction());
      const errorCode = e.response.status;

      if (!showPopup && callback && callback instanceof Function) {
        await callback;
      } else if (showPopup) {
        // await this.errorManager.queueErrorPopup(e, callback!);
      }

      throw e;
    }
  }

  async handle<T>(
    task: Promise<T> | void,
    ctas?: PopupItemCTA,
    skipError: boolean = false,
  ): Promise<T> {
    this.storeManager.dispatch(new SetRequestPendingAction());
    try {
      const result: any = await task;
      this.storeManager.dispatch(new SetRequestCompletedAction());
      return result;
    } catch (e) {
      this.storeManager.dispatch(new SetRequestCompletedAction());
      if (!skipError && axios.isAxiosError(e)) {
        NotificationFactory.getInstance().handleError(e, ctas);
      }
      throw (e);
    }
  }
}
