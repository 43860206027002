import React from "react";
import { IFilterBarItemProps } from "../components/FilterBar/filterbar.item.interface";

export class FilterBarUtil {
    static filter(
        config: any,
        filterBarItems: Map<string, React.Component<IFilterBarItemProps>>
    ): Map<string, React.Component<IFilterBarItemProps>> {

        let filteredBarItems: Map<
            string,
            React.Component<IFilterBarItemProps>
        > = new Map<string, React.Component<IFilterBarItemProps>>();
                
        filterBarItems.forEach(
            (component: React.Component<IFilterBarItemProps>, name: string) => {
                if (config?.filters[name]) {
                    filteredBarItems.set(name, component);
                }
            }
        );

        return filteredBarItems;
    }
}
