import { RouteEnum } from "../enums/route.enum";
import { NavigationDataInterface } from "../interfaces/navigation/navigation.data.interface";

export interface NavigationState {
  //TODO change to string (for wildchar/params) and fix all related redux actions
  url?: string;
  //TODO change to string (for wildchar/params) and fix all related redux actions
  back?: string;
  link?: RouteEnum;
  route: RouteEnum;
  data?: NavigationDataInterface;
}

export const initialNavigationState: NavigationState = {
  url: undefined,
  back: undefined,
  link: undefined,
  route: RouteEnum.ROOT,
  data: undefined,
};
