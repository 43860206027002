import { StoreManager } from "@hypereact/state";
import { RouteEnum } from "../enums/route.enum";
import { RouterConfig } from "../../config/router.config";
import { NavigationState } from "../states/navigation.state";
import {
  NavigationSetLinkAction,
  SetRouteAction,
} from "../../actions/navigation.actions";
import { ReduxSlices } from "../../config/enums/redux.slices";
import { UserState } from "../states/user.state";
import { AuthEnum } from "../enums/auth.enum";

interface INavigationService {
  getNavigationState(): NavigationState;
  getCurrentRoute(): RouteEnum;
  listenRouteChange(history: any): void;
  handleRouteChange(location: any): void;
  goto(url: RouteEnum): void;
  getRouterConfigEntries(): any;
}

export class NavigationService implements INavigationService {
  private storeManager: StoreManager;
  private routerConfig: RouterConfig;
  private history: any;
  private static instance: NavigationService;

  constructor() {
    this.storeManager = StoreManager.getInstance();
    this.routerConfig = RouterConfig.getInstance();
  }

  static getInstance() {
    if (NavigationService.instance == null) {
      NavigationService.instance = new NavigationService();
    }
    return NavigationService.instance;
  }

  getNavigationState(): NavigationState {
    return this.storeManager.getState(ReduxSlices.Navigation);
  }

  //TODO add return type
  getCurrentUrl(): string {
    return this.getNavigationState().url as string;
  }

  getCurrentRoute(): RouteEnum {
    return this.getNavigationState().route;
  }

  getDeepLink(): RouteEnum {
    return this.getNavigationState().link as RouteEnum;
  }

  listenRouteChange(history: any): void {
    history.listen((location: any, action: any) => {
      this.handleRouteChange(location);
    });
    if (!this.history) {
      this.history = history;
      this.handleRouteChange(history.location);
    }
  }

  async handleRouteChange(location: any) {
    window.scrollTo(0, 0);
    // The current url on navigation state will be the prevoius url when the route is changed
    const previous_url = this.getCurrentRoute();
    let current_url = location.pathname;
    if (
      process.env.REACT_APP_ROUTER_BASE_HREF != null &&
      process.env.REACT_APP_ROUTER_BASE_HREF !== "/"
    ) {
      current_url = location.pathname.replace(
        process.env.REACT_APP_ROUTER_BASE_HREF,
        ""
      );
    }
    this.storeManager.dispatch(
      new SetRouteAction(previous_url, current_url, this.getMatchingRouteEnum(current_url))
    );

    const userState: UserState = this.storeManager.getState(ReduxSlices.User);

    if (userState.state === AuthEnum.None && current_url !== RouteEnum.LOGIN) {
      this.storeManager.dispatch(
        new NavigationSetLinkAction(current_url as RouteEnum)
      );
    }

    if (userState.state === AuthEnum.Login) {
      this.storeManager.dispatch(new NavigationSetLinkAction(undefined));
    }

    if (previous_url) {
      //TODO: reset persisted state
    }
  }

  goto(url: RouteEnum | string) {
    this.history.push(url);
  }

  getRouterConfigEntries(): any {
    return this.routerConfig.getRoutes().entries();
  }

  private getMatchingRouteEnum(url: string): RouteEnum {
    const regex = new RegExp("[\*\:]|^/$");
    const result: RouteEnum | undefined =
      Object.values(RouteEnum)
        .filter((item: RouteEnum) => {
          return !item.match(regex);
        })
        .sort((a: RouteEnum, b: RouteEnum) => {
          if (a.length < b.length) {
            return 1
          } else {
            return -1
          }
        })
        .find((item: RouteEnum) => {
          return url.startsWith(item)
        })
    return result as RouteEnum;
  }
}
