
import { Institute, User } from "../../api/entities";
import { AuthEnum } from "../enums/auth.enum";

export interface UserState {
  email?: string|undefined;
  password?:string|undefined;
  tenant?:number|undefined;
  state: AuthEnum;
  personalData?: User;
  config?: any;
  items?: Array<User>;
  institute?: Institute
}

export interface PersistedUserState {
  state: AuthEnum;
  password : string,
  email :string,
  personalData: {
    id: number;
  };
  institute: Institute;
}

export const initialUserState: UserState = {
  email: undefined,
  state: AuthEnum.None,
  personalData: undefined,
  config: undefined,
  items: undefined,
  institute: undefined
};
