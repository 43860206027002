
import { BookSearchFilter, BookStats, InstituteSearchFilter, InstituteStats } from "../../api/entities";
import { OrderDirectionEnum } from "../enums/order.direction.enum";
import { StatisticsOptionsEnum } from "../enums/statistics.options";
import { SharedState } from "./shared.state";

export interface StatisticsState {
  statisticsOption: StatisticsOptionsEnum;
  totalNumberOfDownload: number;
}

export interface BooksStatisticsState extends SharedState<BookStats, BookSearchFilter> {
  showClearFilter: boolean;
}

export interface InstituteStatisticsState extends SharedState<InstituteStats, InstituteSearchFilter> {
  showClearFilter: boolean;
}

export const initialStatisticsState: StatisticsState = {
  statisticsOption: StatisticsOptionsEnum.Institute,
  totalNumberOfDownload: 0
};

export const initialBookStatisticsState: BooksStatisticsState = {
  items: [
    
  ],
  filters: {
    grade: "O1,O2"
  },
  pagination: {
    page: 0,
    size: 1000,
    total: 0,
  },
  order: {
    by: "updatedAt",
    direction: OrderDirectionEnum.DESC,
  },

  showClearFilter: false
};

export const initialInstituteStatisticsState: InstituteStatisticsState = {
  items: [],
  filters: {
    grade: "O1,O2"
  },
  pagination: {
    page: 0,
    size: 1000,
    total: 0,
  },
  order: {
    by: "updatedAt",
    direction: OrderDirectionEnum.DESC,
  },
  showClearFilter: false
};
