import * as jsonpatch from 'fast-json-patch';
import { Institute, InstituteCreateModel, InstituteSearchFilter, InstituteSearchResponse } from '../../api/entities';
import { IInstituteApiClients, InstituteApiClients } from './institute.api.client';

export interface IInstituteApiService {
    create(institute: InstituteCreateModel): Promise<Institute>;
    getById(id: number): Promise<Institute>;
    patch(oldInstitute: Institute, updatedInstitute: Institute): Promise<Institute>;
    search(
        page: number,
        size: number,
        query?: string,
        sort?: { [key: string]: string | undefined },
        filter?: InstituteSearchFilter
    ): Promise<InstituteSearchResponse>;
    delete(id: number);
}

export class InstituteApiService implements IInstituteApiService {
    private static instance: InstituteApiService;
    private api: IInstituteApiClients;

    private constructor(basePath: string) {
        this.api = InstituteApiClients.getInstance(basePath);
    }
    
    async delete(id: number) {
        await this.api.institute.deleteInstituteById(id);
    }

    static getInstance(basePath: string) {
        if (InstituteApiService.instance == null) {
            InstituteApiService.instance = new InstituteApiService(basePath);
        }
        return InstituteApiService.instance;
    }

    async create(institute: InstituteCreateModel): Promise<Institute> {
        return (await this.api.institute.createInstitute(institute)).data;
    }

    async getById(id: number): Promise<Institute> {
        return (await this.api.institute.getInstituteById(id)).data;
    }

    async patch(oldInstitute: Institute, updatedInstitute: Institute): Promise<Institute> {
        const requestPatch: any = jsonpatch.compare(oldInstitute, updatedInstitute);
        const institute: Institute = (
            await this.api.institute.patchInstitute(oldInstitute.id, requestPatch)
        ).data;
        return institute;
    }

    async search(
        page: number,
        size: number,
        query?: string,
        sort?: { [key: string]: string | undefined },
        filter?: InstituteSearchFilter
    ): Promise<InstituteSearchResponse> {
        return (
            await this.api.institute.searchInstitute({
                page,
                size,
                query,
                sort,
                filter,
            })
        ).data;
    }
}