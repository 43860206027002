
import { Button, FormLabel, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FileDetails } from "../../beans/file.details";
import { FileTypeConfig } from "../../beans/filetype.config";
import SearchIcon from '@material-ui/icons/Search';
import { BookState } from "../../states/book.state";

interface IUploadComponentProps {
  onChange: (fileDetails: FileDetails, fileTypeEnum: FileTypesEnum) => void;
  fileConfig: FileTypeConfig;
  disabled?: boolean;
  bookFileError: boolean;
  bookState?: BookState;
}

export enum FileTypesEnum {
  Cover = 'cover',
  Book = 'book'
}

export interface FileState {
  extError: boolean,
  sizeError: boolean,
  fileName?: string
}

export function UploadBookComponent(props: IUploadComponentProps) {


  const [coverState, setCoverState] = useState<FileState>({ extError: false, sizeError: false });
  const [bookState, setBookState] = useState<FileState>({ extError: false, sizeError: false });

  const [coverExt, setCoverExt] = useState("");
  const [bookExt, setBookExt] = useState("");

  useEffect(() => {
    setCoverExt(props.fileConfig[FileTypesEnum.Cover].ext.join(", "));
    setBookExt(props.fileConfig[FileTypesEnum.Book].ext.join(", "));

  }, []);

  const onFileUpload = (e: any, fileType: FileTypesEnum) => {

    let reader: FileReader = new FileReader();
    let file: File = e.target.files[0];

    if (props.fileConfig[fileType].types.filter(currentType => currentType === file.type).length === 0) {
      if (FileTypesEnum.Cover === fileType) {
        setCoverState({ ...coverState, extError: true });
        props.onChange({
          data: null
        }, fileType)
      } else {
        setBookState({ ...bookState, extError: true });
      }
     ;
      return ;
    }

    if (file?.size > props.fileConfig[fileType].maxSize) {
      if (FileTypesEnum.Cover === fileType) {
        setCoverState({ ...coverState, sizeError: true });
        props.onChange({
          data: null
        }, fileType);
      } else {
        setBookState({ ...bookState, sizeError: true });
      }
   
      return;
    }

    let fileDetails: FileDetails = {};

    fileDetails = {
      filename: file.name,
      size: file.size,
      type: fileType,
      file: file
    };

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      fileDetails.data = reader.result;
      props.onChange(fileDetails, fileType);
    };

    if (FileTypesEnum.Cover === fileType) {
      setCoverState({ extError: false, sizeError: false, fileName: fileDetails.filename });
    } else {
      setBookState({ extError: false, sizeError: false, fileName: fileDetails.filename });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="upload-textfield-container">
        <FormLabel>
          <FormattedMessage id="upload.cover.label" />
        </FormLabel>
        {coverState.sizeError ? (
          <Typography className="error-message ml-8 common-header" display="inline">
            <FormattedMessage id="upload.size.error.message" />
          </Typography>
        ) : coverState.extError ? (
          <Typography className="error-message ml-8 common-header" display="inline">
            <FormattedMessage id="upload.ext.error.message" />
          </Typography>
        ) : coverState.fileName && (
          <Typography className="links.style ml-8 common-header" display="inline">
            {coverState.fileName}
          </Typography>
        )}
        <Button component="label" className="common-button ml-8">
          <FormattedMessage id="upload.button.browse" />
          <input
            type="file"
            onChange={evt => onFileUpload(evt, FileTypesEnum.Cover)}
            onClick={(e: any) => (e.target.value = null)}
            style={{position: "absolute", left: "-99999rem"}}
            accept={coverExt}
          />
          <SearchIcon htmlColor="white" className="pl-4" />
        </Button>
      </Grid>

      <Grid item xs={6} className="upload-textfield-container">
        <FormLabel>
          <FormattedMessage id="upload.book.label" />
        </FormLabel>
        {bookState.sizeError && (
          <Typography className="error-message ml-8 common-header" display="inline">
            <FormattedMessage id="upload.size.error.message" /> 
          </Typography>
        )}
        { bookState.extError && (
          <Typography className="error-message ml-8 common-header" display="inline">
            <FormattedMessage id="upload.ext.error.message" />
          </Typography>
        )}
        {bookState.fileName && (
          <Typography className="links.style ml-8 common-header" display="inline">
            {bookState.fileName}
          </Typography>
        )}
        {props.bookFileError && (
          <Typography className="error-message ml-8 common-header" display="inline">
            <FormattedMessage id="upload.mandatory.error.message" />
          </Typography>
        )}

          <label htmlFor="raised-button-file ">
            <Button component="label" className="common-button ml-8">
              <FormattedMessage id="upload.button.browse" />
              <input
                type="file"
                onChange={evt => onFileUpload(evt, FileTypesEnum.Book)}
                onClick={(e: any) => (e.target.value = null, e.stopPropagation())}
                accept={bookExt}
                style={{position: "absolute", left: "-99999rem"}}
              />
              <SearchIcon htmlColor="white" className="pl-4" />
            </Button>
          </label>
      </Grid>
    </Grid>
  );
}
