import { LanguageEnum } from "../enums/language.enum";

export interface IPanels {
    menu: boolean;
    notification?: boolean;
    edit?: boolean;
    create?: boolean;
}

export interface ConfigState {
    language?: LanguageEnum;
    panels: IPanels;
    config: {
        default: any;
        user?: any;
        book?: any;
        institute?: any;
        bookStatistics?: any,
        instituteStatistics?: any,
        instituteSearch?: any,
        bookSearch?: any,
    };
}


export const initialConfigState: ConfigState = {
    language: LanguageEnum.IT,
    panels: {
        menu: false,
        notification: false,
        edit: false,
        create: false,
    },
    config: {
        default: undefined,
        bookStatistics: {
            filters: {
                order: true,
                query: true,
                grade: true
            },
            items:[
                "title",
                "code",
                "grade"
            ]
        },
        instituteStatistics: {
            filters: {
                order: true,
                query: true,
                grade: true
            },
            items:[
                "title",
                "code"
            ]
        },
        instituteSearch: {
            filters: {
                order: true,
                query: true,
                grade: true
            },
            items:[
                "title",
                "code"
            ]
        },
        bookSearch: {
            filters: {
                order: true,
                query: true,
                grade: true
            },
            items:[
                "title",
                "code",
                "level",
                "grade"
            ]
        },
    },

};
