// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { JsonErrorResponse } from '../entities';
// @ts-ignore
import { User } from '../entities';
// @ts-ignore
import { UserSearchRequest } from '../entities';
// @ts-ignore
import { UserSearchResponse } from '../entities';
/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * retrieve a user matching the id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserById.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of users matching sorting, filtering and pagination constraints
         * @param {UserSearchRequest} userSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (userSearchRequest: UserSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSearchRequest' is not null or undefined
            if (userSearchRequest === null || userSearchRequest === undefined) {
                throw new RequiredError('userSearchRequest','Required parameter userSearchRequest was null or undefined when calling searchUsers.');
            }
            const localVarPath = `/user/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userSearchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userSearchRequest !== undefined ? userSearchRequest : {}) : (userSearchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * retrieve a user matching the id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getUserById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * retrieve a list of users matching sorting, filtering and pagination constraints
         * @param {UserSearchRequest} userSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(userSearchRequest: UserSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResponse>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).searchUsers(userSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * retrieve a user matching the id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: number, options?: any): AxiosPromise<User> {
            return UserControllerApiFp(configuration).getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve a list of users matching sorting, filtering and pagination constraints
         * @param {UserSearchRequest} userSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(userSearchRequest: UserSearchRequest, options?: any): AxiosPromise<UserSearchResponse> {
            return UserControllerApiFp(configuration).searchUsers(userSearchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - interface
 * @export
 * @interface UserControllerApi
 */
export interface UserControllerApiInterface {
    /**
     * retrieve a user matching the id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    getUserById(id: number, options?: any): AxiosPromise<User>;

    /**
     * retrieve a list of users matching sorting, filtering and pagination constraints
     * @param {UserSearchRequest} userSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    searchUsers(userSearchRequest: UserSearchRequest, options?: any): AxiosPromise<UserSearchResponse>;

}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI implements UserControllerApiInterface {
    /**
     * retrieve a user matching the id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserById(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of users matching sorting, filtering and pagination constraints
     * @param {UserSearchRequest} userSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public searchUsers(userSearchRequest: UserSearchRequest, options?: any) {
        return UserControllerApiFp(this.configuration).searchUsers(userSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

}
