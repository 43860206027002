import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { FormattedMessage, IntlContext } from "react-intl";
import { IFilterBarItemProps } from "./filterbar.item.interface";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StoreManager } from "@hypereact/state";
import { NavigationService } from "../../services/navigation.service";
import SearchImg from "../../../assets/icons/Form-icon/Search.svg";

interface QueryFilterBarItemProps extends IFilterBarItemProps {
  name: string;
  value?: string;
  onChange: (value?: string) => void;
  onRefresh: () => void;
}

interface IQueryFilterBarItemState {
  inputValue: string | undefined;
  showCloseIcon: boolean;
  showErrorMessage: boolean;
  clearFilters: boolean;
}

export class QueryFilterBarItem extends React.Component<
  QueryFilterBarItemProps,
  IQueryFilterBarItemState
> {
  private storeManager: StoreManager;
  private navigationService: NavigationService;
  constructor(props: QueryFilterBarItemProps) {
    super(props);
    this.storeManager = StoreManager.getInstance();
    this.navigationService = NavigationService.getInstance();
    this.state = {
      inputValue: props.value,
      showCloseIcon: props.value && props.value.length > 0 ? true : false,
      showErrorMessage: props.value && props.value.length < 3 ? true : false,
      clearFilters: props.value?.length != undefined ? false : true,
    };
  }

  componentWillUpdate() {
    if (
      this.props.value === undefined &&
      this.state.clearFilters === false
    ) {
      this.setState({ inputValue: this.props.value });
      this.setState({ clearFilters: true });
    }
  }

  handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ inputValue: event.target.value });
    if (event.target.value.length === 0) {
      this.setState({ showCloseIcon: false });
    }
    if (event.target.value.length < 3) {
      this.setState({ showErrorMessage: true });
    } else {
      this.setState({ showErrorMessage: false });
    }
  }

  handleOnBlur() {
    this.props.onChange(this.state.inputValue);
    if (this.state.inputValue && this.state.inputValue.length > 0) {
      this.setState({ showCloseIcon: true });
    } else {
      this.setState({ showCloseIcon: false });
    }
    this.setState({ clearFilters: false });
  }

  handleOnEnterPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      this.props.onChange(this.state.inputValue);
      this.setState({ showErrorMessage: true });
      if (this.state.inputValue && this.state.inputValue.length > 0) {
        this.setState({ showCloseIcon: true });
      }
    }
  }

  handleOnClick() {
    this.setState({ inputValue: undefined });
    this.setState({ showCloseIcon: false });
    switch (this.navigationService.getCurrentRoute()) {
      default:
        break;
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const { name } = this.props;
    return (
      <Grid item>
        <FormControl
          className="textInputStyle size-border"
          fullWidth={true}
          error={true}
        >
          <div className="icon-cancel">
            <div>
              <TextField
                placeholder={this.context.formatMessage({
                  id: `filter.bar.query.${name}.placeholder`,
                })}
                // autoComplete = "off"
                variant="outlined"
                size="small"
                name={name}
                value={this.state.inputValue || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleOnChange(event)
                }
                onBlur={() => this.handleOnBlur()}
                onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) =>
                  this.handleOnEnterPress(event)
                }
                InputProps={{
                  classes: {
                    root: "placeholder-text-style search-position",
                  },
                  endAdornment: (this.state.inputValue === undefined ||
                    this.state.inputValue.length === 0) && (
                    <img src={SearchImg} />
                  ),
                }}
              />
            </div>
            {this.state.showCloseIcon &&
              this.props.value != undefined &&
              this.state.inputValue != undefined && (
                <IconButton
                  className="icon-cancel-component"
                  onClick={() => this.handleOnClick()}
                >
                  <HighlightOffIcon className="icon-cancel-component-highlightofficon " />
                </IconButton>
              )}
          </div>
          {this.state.showErrorMessage &&
          this.state.inputValue &&
          this.state.inputValue?.length < 3 ? (
            <FormHelperText>
              <FormattedMessage id="filter.bar.query.error" />
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
    );
  }
}
