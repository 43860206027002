import { ReduxConnect } from "@hypereact/state";
import { Grid } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Institute } from "../../../api/entities";
import { ReduxState } from "../../../config/redux.config";
import { ConfigManager } from "../../../managers/config.manager";
import { InstituteManager } from "../../../managers/institute.manager";
import { FilterBar } from "../../../shared/components/FilterBar/filterbar.component";
import { PaginationComponent } from "../../../shared/components/Pagination/pagination.component";
import { ConfigTypeEnum } from "../../../shared/enums/config.type.enum";
import { ContextEnum } from "../../../shared/enums/context.enum";
import { InstituteState } from "../../../shared/states/institute.state";
import { FilterBarUtil } from "../../../shared/utils/filterBar.util";
import { FilterBarItems } from "../../statistics/config/filterbar.config";
import { InstituteItem } from "../components/institute.item.component";
import { InstituteUpdateContainer } from "../components/institute.update.container";
interface IInstituteViewProps {
  institute: InstituteState;
}

@ReduxConnect((state: ReduxState) => {
  return {
    institute: state.institute,
  };
})
export class InstituteView extends React.Component<IInstituteViewProps> {
  private instituteManager: InstituteManager;
  private configManager: ConfigManager;
  private KEYWORD_MIN_CHAR: number = 3;

  constructor(props: IInstituteViewProps) {
    super(props);
    this.instituteManager = InstituteManager.getInstance();
    this.configManager = ConfigManager.getInstance();
  }

  async componentDidMount() {
    await this.instituteManager.search();
  }

  private async handleOnClickItem(id: number) {
    await this.instituteManager.setSelectedInstitute(id);
  }

  private async onPaginationChange(page: number, size: number): Promise<void> {
    await this.instituteManager.search(page);
  }

  async handleOnChange(name: string, value: any) {
    if (name !== "query" || (value && value.length >= this.KEYWORD_MIN_CHAR)) {
      this.instituteManager.resetPagination();
      await this.instituteManager.onFilterChange(name, value);
    }
  }

  async handleReset(makeSearch: boolean = true) {
    this.instituteManager.reset();
    if (makeSearch) {
      await this.instituteManager.search();
    }
  }

  render() {
    const { institute } = this.props;
    const filterBarItems = FilterBarUtil.filter(
      this.configManager.getConfigByType(ConfigTypeEnum.INSTITUTES),
      FilterBarItems.getFilterBarItems()
    );
    return (
      <>
        <Grid>
          <div className="">
            <FilterBar
              items={filterBarItems}
              config={this.instituteManager.getConfig(filterBarItems)}
              onChange={(name: string, value: any) => { this.instituteManager.onFilterChange(name, value) }}
              onClearFilters={() => { this.handleReset() }}
              onRefreshFilters={() => { this.instituteManager.search() }}
              showClearFilter={this.instituteManager.getState().showClearFilter}
            />
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid
            item xs={12} sm={12} md={institute?.createUpdate?.context === ContextEnum.UPDATE ? 6 : 12}
            className={`${institute?.createUpdate?.context === ContextEnum.UPDATE &&
              "institute-update-container"
              }`}
          >
            {institute?.items?.length > 0 ? (
              <>
                {institute?.items.map((item: Institute, index: number) => (
                  <div className="institute-card mb-10" key={index}>
                    <InstituteItem
                      institute={item}
                      onClick={(id: number) => this.handleOnClickItem(id)}
                    />
                  </div>
                ))}
              </>
            ) : (
              <h4>
                <FormattedMessage id="institute.list.empty" />
              </h4>
            )}
          </Grid>
          {institute?.createUpdate?.context === ContextEnum.UPDATE && (
            <Grid item xs={12} sm={12} md={6}>
              <InstituteUpdateContainer
                instituteState={institute}
                context={institute?.createUpdate?.context}
              />
            </Grid>
          )}
        </Grid>
        {institute?.items?.length > 0 && (
          <div className="align-pagination">
            <PaginationComponent
              onChange={(page: number, size: number) =>
                this.onPaginationChange(page, size)
              }
              page={institute?.pagination.page!}
              total={institute?.pagination.total!}
              size={institute?.pagination.size!}
            />
          </div>
        )}
      </>
    );
  }

  componentWillUnmount() {
    this.handleReset(false);
  }
}
