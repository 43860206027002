import { ReduxConnect } from "@hypereact/state";
import React from "react";
import { IntlContext } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ReduxState } from "../../../config/redux.config";
import { AuthEnum } from "../../enums/auth.enum";
import { RouteEnum } from "../../enums/route.enum";
import { NavigationService } from "../../services/navigation.service";
import { ConfigState } from "../../states/config.state";
import { HttpState } from "../../states/http.state";
import { NavigationState } from "../../states/navigation.state";
import { UserState } from "../../states/user.state";
import { AppBar } from "../AppBar/app.bar.component";
import { Backdrop } from "../BackDrop/backDrop.component";
import { Footer } from "../Footer/footer.component";
import { MenuContainer } from "../Menu/menu.container";
import PopupContainer from "../Popup/popup.container";
import ToastContainer from "../Toast/toast.container";

interface IAppContainerProps extends RouteComponentProps {
  children?: React.ReactNode;
  user?: UserState;
  config?: ConfigState;
  navigation?: NavigationState;
  http?: HttpState
}

@ReduxConnect((state: ReduxState) => {
  return {
    config: state.config,
    user: state.user,
    navigation: state.navigation,
    http: state.http,
  };
})
class AppContainer extends React.Component<IAppContainerProps> {

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: IAppContainerProps) {
    super(props);
  }
  render() {
    const { children, config } = this.props;
    const menuOpen = config?.panels.menu;

    return (
      <div className="app-container">
        <AppBar />
        <MenuContainer />
        <main className={menuOpen ? "contentShrink" : "content"}>
          <div className="toolbar" />
          {children}
        </main>
        {
          <Backdrop />
        }
        {/* <Footer /> */}
        <PopupContainer />
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(AppContainer);
