import { StoreManager } from "@hypereact/state";
import { ReduxSlices } from "../../config/enums/redux.slices";
import { AuthEnum } from "../enums/auth.enum";
import { RouteEnum } from "../enums/route.enum";
import { UserState } from "./../states/user.state";

interface IRouterService {
  isAllowedRoute(to: any, from: any, next: any): void;
  handleNonAuthRoute(to: any, from: any, next: any): void;
  handleAuthRoute(to: any, from: any, next: any): void;
  handleNotMatchRoute(to: any, from: any, next: any): void;
}

export class RouterService implements IRouterService {
  private storeManager: StoreManager;
  private static instance: RouterService;

  constructor() {
    this.storeManager = StoreManager.getInstance();
  }

  static getInstance() {
    if (RouterService.instance == null) {
      RouterService.instance = new RouterService();
    }
    return RouterService.instance;
  }

  private getUser(): UserState {
    return this.storeManager.getState(ReduxSlices.User);
  }

  isUserAuthenticated(): boolean {
    const user: UserState = this.getUser();
    return user?.state === AuthEnum.Login;
  }

  isPrivacyAccepted(): boolean {
    const user: UserState = this.getUser();
    return user.personalData?.data?.approvals?.privacy !== null;
  }

  isAllowedRoute(to: any, from: any, next: any) {
    const router = to?.meta?.router;
    if (router) {
      next();
    } else if (to.match.path === RouteEnum.FALLBACK) {
      this.handleNotMatchRoute(to, from, next);
    }
  }

  async handleNonAuthRoute(to: any, from: any, next: any) {
    await this.storeManager.waitUntilReady(ReduxSlices.User);
    if (this.isUserAuthenticated()) {
      next.redirect(RouteEnum.HOME);
    } else {
      next();
    }
  }

  async handleAuthRoute(to: any, from: any, next: any) {
    await this.storeManager.waitUntilReady(ReduxSlices.User);
    if (this.isUserAuthenticated()) {
      next();
    } else {
      next.redirect(RouteEnum.LOGIN);
    }
  }

  

  handleNotMatchRoute(to: any, from: any, next: any) {
    if (this.isUserAuthenticated()) {
      next.redirect(RouteEnum.HOME);
    } else {
      next.redirect(RouteEnum.LOGIN);
    }
  }
}
