// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { StatsSearchFilter } from './stats-search-filter';

/**
 * 
 * @export
 * @interface InstituteStatsSearchRequest
 */
export interface InstituteStatsSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof InstituteStatsSearchRequest
     */
    query?: string;
    /**
     * 
     * @type {number}
     * @memberof InstituteStatsSearchRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof InstituteStatsSearchRequest
     */
    size?: number;
    /**
     * 
     * @type {{ [key: string]: string | undefined; }}
     * @memberof InstituteStatsSearchRequest
     */
    sort?: { [key: string]: string | undefined; };
    /**
     * 
     * @type {StatsSearchFilter}
     * @memberof InstituteStatsSearchRequest
     */
    filter?: StatsSearchFilter;
}

/**
    * @export
    * @enum {string}
    */
export enum InstituteStatsSearchRequestSortEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}



