import { ReduxConnect } from "@hypereact/state";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import { User } from "../../../api/entities";
import Image from "../../../assets/images/login-image.png";
import { ReduxState } from "../../../config/redux.config";
import { AuthManager } from "../../../managers/auth.manager";
import { ActionType } from "../../../managers/form.manager";
import { NotificationFactory } from "../../../managers/notification.factory";
import { ToastManager } from "../../../managers/toast.manager";
import { RouteEnum } from "../../../shared/enums/route.enum";
import { SeverityEnum } from "../../../shared/enums/severity.enum";
import { FormProxy } from "../../../shared/interfaces/form/form.proxy.interface";
import { NavigationService } from "../../../shared/services/navigation.service";
import { UserState } from "../../../shared/states/user.state";
import { LoginFormData } from "../beans/login.form.data";
import LoginForm from "../components/login.form";

interface ILoginProps {
  user?: UserState;
}
@ReduxConnect((state: ReduxState) => {
  return {
    user: state.user,
  };
})
export class LoginView extends React.Component<ILoginProps> {
  private authManager: AuthManager;
  
  private navigationService: NavigationService;
  private formProxy!: FormProxy;
  private toastManager: ToastManager;
  constructor(props: ILoginProps) {
    super(props);
    this.authManager = AuthManager.getInstance();
  
    this.navigationService = NavigationService.getInstance();
    this.toastManager = ToastManager.getInstance();
    this.onActions = this.onActions.bind(this);
  }

  private async handleSubmit(formData: LoginFormData) {
    const user: User = await this.authManager.login(
      formData.email,
      formData.password
    );
    
    NotificationFactory.getInstance().showToast({
      message: `login.toast.success.message`,
      variant: SeverityEnum.SUCCESS
    })
  
    if (this.navigationService.getDeepLink() === undefined) {
        this.navigationService.goto(RouteEnum.HOME);
      } else {
        this.navigationService.goto(this.navigationService.getDeepLink());
      }
    }

  onActions(actionType: ActionType, payload: any): void {
    switch(actionType) {
      case ActionType.SUBMIT: {
        this.handleSubmit(payload as LoginFormData);
        break;
      } 
    
      default:
        break;
    }
  }

  private async handleOnChange(
    name: string,
    value: any,
    errors: any,
    event: any
  ) {
    switch (name) {
      case "email":
        this.authManager.saveFormValue(name,value)
        break;
      case "password":
        this.authManager.saveFormValue(name,value)
        break;

    }

  }
  render() {
    return (
      <>
        <div className="login-background "></div>
        <div className="lss-login-card-container">
          <Card className="lss-login-card" raised={true}>
            <CardContent>
              <Typography
                color="textPrimary"
                align="center"
                variant="h6"
                gutterBottom
              >
                <div className="lss-title">
                  {" "}
                  <FormattedMessage id="login.card.title" />{" "}
                </div>
              </Typography>
              <LoginForm onActions={this.onActions} />
              <div className="lss-button-container">
                <Button
                  className="auth-view-button"
                  color="primary"
                  size="medium"
                  variant="contained"
                  form={'login-form'}
                  fullWidth
                  type='submit'
                  disableElevation
                  disableRipple
                >
                  <FormattedMessage id={"login.form.button.continue.title"} />
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
        <img src={Image} className="login-image-style" />
      </>
    );
  }
}
