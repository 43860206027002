import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import { PopupManager } from "../../../managers/popup.manager";
import { FormattedMessage } from "react-intl";
import Error from "../../../assets/icons/Popup/Error.svg";
import Success from "../../../assets/icons/Popup/OK.svg";
import Logout from "../../../assets/icons/Mix/Logout.svg";
import Info from "../../../assets/icons/Mix/Info.svg";


export type PopupItemButton = Partial<{
    label: string;
    cta: () => void;
    hide: boolean;
    className: string;
}>;

export enum PopupType {
    Error,
    Info,
    Success,
    Logout
}

export type PopupItemCTA = Partial<{
    primary: PopupItemButton | boolean;
    secondary: PopupItemButton | boolean;
}>;

const Icons = {
    [PopupType.Error]: Error,
    [PopupType.Info]: Info,
    [PopupType.Success]: Success,
    [PopupType.Logout]: Logout
}

export interface PopupItemProps {
    type: PopupType;
    title?: string;
    children?: JSX.Element | React.ReactNode;
    description?: string;
    ctas?: PopupItemCTA;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    stylingProp?: string;
    dialogActionStyle?: string;
}

/**
 * PopupItem
 * 
 * It shows the info of the popup
 * 
 * @property {PopupType} type - type of the popup indicating the severity
 * @property {string} title - title of the popup
 * @property {JSX.Element | React.ReactNode} children - custom children to render below description
 * @property {string} description - description of the popup
 * @property {PopupItemCTA} ctas - CTA info to render the primary and secondary buttons
 * @property {string | false | undefined} maxWidth - styling prop
 * @property {string} stylingProp - styling prop
 * @property {string} dialogActionStyle - styling prop
 * 
 * @returns JSX.Element
 */

const PopupItem: React.FC<PopupItemProps> = ({
    type,
    title,
    description,
    maxWidth,
    ctas = {},
    stylingProp,
    dialogActionStyle,
    children }) => {

    const primary = ctas.primary as PopupItemButton;
    const secondary = ctas.secondary as PopupItemButton;

    const handleClick = (callback?: () => void, hide = true) => () => {
        callback && callback();
        hide && PopupManager.getInstance().hide();
    };


    return (
        <Dialog
            className={stylingProp + ' popup-container'}
            disableBackdropClick
            aria-labelledby="form-dialog"
            maxWidth={maxWidth}
            fullWidth
            open
        >
            <DialogTitle
                id="form-dialog"
                disableTypography
                className="dialog-title"
            >
                <div className="dialog-title-item">
                    <img alt={"alt"} className='popup-icon' src={Icons[type]} />
                        <Typography className="dialog-title-typography">
                            <FormattedMessage id={title || "errors.generic"} />
                        </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                {description && <DialogContentText className='popup-description'><FormattedMessage id={description} /></DialogContentText>}
                {children}
            </DialogContent>
            <DialogActions className={dialogActionStyle + ' buttons'}>
                {secondary && (
                    <Button className={secondary.className || "secondary"} onClick={handleClick(secondary.cta, secondary.hide)} color="primary">
                        <FormattedMessage id={secondary.label || "popup.button.secondary"} />
                    </Button>)}
                {primary && (<Button
                    className={primary.className || "primary"}
                    variant="contained"
                    onClick={handleClick(primary.cta, primary.hide)}
                    disableRipple
                    disableElevation>
                    <FormattedMessage id={primary.label || "popup.button.primary"} />
                </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default PopupItem;