import { Avatar, Button, Grid, Paper, SvgIcon, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Book } from "../../../api/entities/book";
import { ReactComponent as UsersIcon } from "../../../assets/icons/Mix/Users.svg";
import { BookConstants } from "../../../shared/constants/book.constants";


interface IBookItemProps {
    book?: Book;
    onClickItem?: (id: number) => void;
    onClickButton?: (id: number, e: any) => void;
    selected?: boolean;
}

export class BookItem extends React.Component<IBookItemProps> {

    handleOnClickItem(e:any) {
        const { book, onClickItem } = this.props;
        onClickItem && onClickItem(book.id)
    }

    render() {
        const { book, selected } = this.props;
        return (
            <div
                onClick={(e:any) => this.handleOnClickItem(e)}
            >
                <Paper
                    variant="outlined"
                    elevation={0}
                >
                    <div className={`book-item ${selected && "book-item-selected"}`}>
                        <Avatar src={book.cover ? book.cover : BookConstants.BOOK_THUMBNAIL_FALLBACK} className="book-avatar" />
                        <Grid
                            container
                            spacing={1}
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid container item spacing={1} xs={12}>
                                <Grid item>
                                    <Typography className="book-code">
                                        {book.code || "AIE XXX.YX - REV.XX"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="book-title">
                                        {book.title || "Materia"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={12}>
                                <Grid item className="flex">
                                    <SvgIcon component={UsersIcon} />
                                    <Typography className="book-level">
                                        { 
                                            
                                         <FormattedMessage id={book.level ? `book.item.level.${book.level}` : `book.item.level.unk`} />
                                        
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="book-grade">
                                        {
                                         <FormattedMessage id={book.grade ? `book.item.grade.${book.grade}` : `book.item.grade.unk`} />
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button className="common-button mr-16 min-width" onClick={(e: any) => this.props.onClickButton(book.id, e)}>
                            <FormattedMessage id="common.button.download.book" />
                        </Button>
                    </div>
                </Paper>
            </div>
        )
    }
}