import { ReduxConnect } from "@hypereact/state";
import { Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Book } from "../../../api/entities/book";
import { ReduxState } from "../../../config/redux.config";
import { BookCreateUpdate } from "../../../shared/states/book.state";

interface IBookHeaderCreateProps {
    book: Book;
    bookCreateUpdate: BookCreateUpdate;
}

@ReduxConnect((state: ReduxState) => {
    return {
        book: state.book.item,
        bookCreateUpdate: state.book.createUpdate
    };
})

export class BookHeaderCreate extends React.Component<IBookHeaderCreateProps> {
    render() {
            return (
                <Button 
                    className="common-button" 
                    form={"book-form"}
                    type='submit'
                >
                    <FormattedMessage id="common.button.save.book" />
                </Button>
            )
    }

}