import { StoreManager } from "@hypereact/state";
import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import "./assets/styles/app.scss";
import { ReducerConfig } from "./config/redux.config";
import { InjectionManager } from "./managers/injection.manager";
import { LanguageService } from "./shared/services/language.service";


(async () => {
    const injections: InjectionManager = InjectionManager.getInstance();
    const storeManager = StoreManager.getInstance(ReducerConfig);
    await LanguageService.initializeLanguage();
    await storeManager.waitUntilReady();

    ReactDOM.render(
        <Provider store={storeManager.getStore()}>
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASEHREF}>
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById("root")
    );
})();
