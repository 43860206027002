import React from "react";
import { AppBar, Box, Button, IconButton, Typography } from "@material-ui/core";
import { ContextEnum } from "../../../shared/enums/context.enum";
import { ReduxConnect, StoreManager } from "@hypereact/state";
import { ReduxState } from "../../../config/redux.config";
import { Institute, InstituteGradeEnum } from "../../../api/entities";
import { FormattedMessage } from "react-intl";
import { InstituteState } from "../../../shared/states/institute.state";
import { InstituteManager } from "../../../managers/institute.manager";
import UpdateForm from "./institute.create.form";
import { ActionType } from "../../../managers/form.manager";
import CloseIcon from "@material-ui/icons/Close";
import { SeverityEnum } from "../../../shared/enums/severity.enum";
import { NotificationFactory } from "../../../managers/notification.factory";
import { PopupManager } from "../../../managers/popup.manager";
import { PopupType } from "../../../shared/components/Popup/popup.item";

interface IInstituteUpdateViewProps {
  instituteState?: InstituteState;
  context?: ContextEnum;
}

@ReduxConnect((state: ReduxState) => {
  return {
    book: state.book,
  };
})
export class InstituteUpdateContainer extends React.Component<IInstituteUpdateViewProps> {
  private instituteManager: InstituteManager;
  private notificationFactory: NotificationFactory;
  private popupManager: PopupManager;
  constructor(props: IInstituteUpdateViewProps) {
    super(props);
    this.state = {
      selected: true,
    };
    this.instituteManager = InstituteManager.getInstance();
    this.notificationFactory = NotificationFactory.getInstance();
    this.popupManager = PopupManager.getInstance();
    this.onActions = this.onActions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  private async handleChange(
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) {
    await this.instituteManager.setFormField(
      event.target.name,
      event.target.value
    );
  }

  private async handleSubmit() {
    const { instituteState } = this.props;
    const instituteCreateModel: Institute = {
      ...instituteState.item,
      id: instituteState.item.id,
      code: instituteState.createUpdate.details.code
        ? instituteState.createUpdate.details.code
        : instituteState.item.code
        ? instituteState.item.code
        : undefined,
      title: instituteState.createUpdate.details.title
        ? instituteState.createUpdate.details.title
        : instituteState.item.title
        ? instituteState.item.title
        : undefined,
      grade: (instituteState.createUpdate.details.grade
        ? instituteState.createUpdate.details.grade
        : instituteState.item.grade
        ? instituteState.item.grade
        : undefined) as InstituteGradeEnum,
    };

    try {
      if (this.institutesDiffer(instituteState.item, instituteCreateModel)) {
        await this.instituteManager.patch(
          instituteState.item,
          instituteCreateModel
        );
        await this.instituteManager.search();
        this.notificationFactory.showToast({
          message: `institute.update.toast.success.message`,
          variant: SeverityEnum.SUCCESS,
        });
      }

      await this.instituteManager.resetContext();
    } catch (error) {
      NotificationFactory.getInstance().handleError(error);
    }
  }
  institutesDiffer(item: Institute, instituteCreateModel: Institute) {
    if (
      item.code !== instituteCreateModel.code ||
      item.grade !== instituteCreateModel.grade ||
      item.title !== instituteCreateModel.title
    ) {
      return true;
    }

    return false;
  }

  private async handleConfirm() {
    const { instituteState } = this.props;
    await this.instituteManager.delete(instituteState?.item.id);
    await this.instituteManager.resetContext();
    this.popupManager.hide();
    this.instituteManager.search();
  }

  private async handleOnDeleteItem() {
    this.popupManager.show({
      type: PopupType.Info,
      title: "institute.popup.delete.title",
      description: "institute.popup.delete.description",
      maxWidth: "xs",
      stylingProp: "popup-display",
      ctas: {
        primary: {
          label: "popup.button.yes",
          hide: false,
          cta: () => this.handleConfirm(),
        },
        secondary: {
          label: "popup.button.no",
          hide: true,
        },
      },
    });
  }

  onActions(actionType: ActionType, payload?: any): void {
    switch (actionType) {
      case ActionType.SUBMIT: {
        this.handleSubmit();
        break;
      }
      case ActionType.CHANGE: {
        this.handleChange(payload);
        break;
      }
      case ActionType.BLUR: {
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { instituteState } = this.props;

    return (
      <>
        <Box
          component="div"
          padding="6px 16px 8px 16px"
          style={{ backgroundColor: "#FAFBFC" }}
        >
          <AppBar
            position="static"
            elevation={0}
            style={{ backgroundColor: "#FAFBFC" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="common-button"
                onClick={() => this.handleOnDeleteItem()}
              >
                <FormattedMessage id="common.button.delete" />
              </Button>

              <IconButton
                color="inherit"
                aria-label="close"
                form={"book-form"}
                type="submit"
                style={{ padding: "0px" }}
                onClick={() => this.onActions(ActionType.SUBMIT)}
              >
                <CloseIcon htmlColor="#14438B" />
              </IconButton>
            </div>
          </AppBar>
        </Box>
        <Box
          component="div"
          padding="8px 16px"
          style={{ backgroundColor: "#FAFBFC" }}
          className="update-institute-box"
        >
          <AppBar
            position="static"
            elevation={0}
            style={{ backgroundColor: "#FAFBFC" }}
            className={"flex-1"}
          >
            <Typography className="institute-code-update">
              {instituteState?.item.code}
            </Typography>
          </AppBar>
        </Box>
        <Box
          component="div"
          padding="6px 16px"
          style={{ backgroundColor: "#FAFBFC" }}
        >
          <UpdateForm
            onActions={this.onActions}
            formValues={instituteState}
            onChange={this.handleChange}
          />
        </Box>
      </>
    );
  }
}
