import { AxiosError } from "axios";
import { PopupItemCTA, PopupItemProps, PopupType } from "../shared/components/Popup/popup.item";
import { ToastItemProps } from "../shared/components/Toast/toast.item";
import { SeverityEnum } from "../shared/enums/severity.enum";
import { FormProxy } from "../shared/interfaces/form/form.proxy.interface";
import { NotificationManager } from "./notification.manager";
import { PopupManager } from "./popup.manager";
import { ReactionType } from "./reaction.type.enum";
import { ToastManager } from "./toast.manager";

const errors: {
  [key: string]: {
    reaction: ReactionType,
    title?: boolean
  }
} = {
  'unknown': { reaction: ReactionType.TOAST },
  'other': { reaction: ReactionType.POPUP, title: true },
  'usrntfnd': {reaction: ReactionType.POPUP, title: true},
  'usrexs': {reaction: ReactionType.POPUP, title: true},
  'bookexs': { reaction: ReactionType.TOAST },
  'instexs': { reaction: ReactionType.TOAST }  
}

interface Error {
    code: string;
    message: string;
    trace: string;
}
interface ErrorResponse {
  error: {
    code: string;
    message: string;
    trace: string;
  }
  //TODO: define multiple errors structure with backend
  multipleErrors?: Array<{
    field: string;
    error: Omit<Error, 'trace'>
  }>
}

export class NotificationFactory {
  private notificationManagers: Map<ReactionType, NotificationManager> = new Map<ReactionType, NotificationManager>();
  private static instance: NotificationFactory;

  static getInstance() {
    if (this.instance == null) {
      this.instance = new NotificationFactory();
    }
    return this.instance;
  }

  private constructor() {
    this.notificationManagers.set(ReactionType.POPUP, PopupManager.getInstance());
    this.notificationManagers.set(ReactionType.TOAST, ToastManager.getInstance());
  }


  public getNotificationManager(reactionType: ReactionType): NotificationManager | undefined {
    if(this.notificationManagers.get(reactionType) === undefined) {
      throw new Error('notification manager not found for reaction type ' + reactionType);
    }
    return this.notificationManagers.get(reactionType);
  }

  public showPopup(props: PopupItemProps): void {
    this.getNotificationManager(ReactionType.POPUP)?.show(props);
  }

  public showToast(props: ToastItemProps): void {
    this.getNotificationManager(ReactionType.TOAST)?.show(props);
  }

  private getErrorInfo(errorCode: string) {
   
    const { title, reaction } = errors[errorCode];
    return {
      reaction,
      title: title ? `errors.${errorCode}.title` : `errors.popup.title`,
      description: `errors.${errorCode}`,
    }
  
  }

  public handleError(error: AxiosError<ErrorResponse>, ctas?: PopupItemCTA, formProxy?: FormProxy): void {
    const errorCode: string | undefined = error.response?.data.error?.code;
    if (!errorCode) {
      return;
    }
    if (formProxy) {
      //TODO: define multiple errors structure with backend
      const multipleErrors = error.response?.data.multipleErrors;
      multipleErrors?.forEach(({ field, error }) => formProxy.setError(field, error.code));
      return;
    }

    const { title, description, reaction } = this.getErrorInfo(errorCode);
    let props: ToastItemProps | PopupItemProps;
    if (reaction === ReactionType.TOAST) {
      props = {
        variant: SeverityEnum.ERROR,
        message: description
      }
    }
    else if (reaction === ReactionType.POPUP) {
      props = {
        type: PopupType.Error,
        title,
        description,
        ctas,
      }
    }
    else {
      //redirect to errorpage
      return;
    }
    this.getNotificationManager(reaction)?.show(props);
  }
}