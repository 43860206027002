import React from "react";
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { themeLight } from "./config/theme.config";
import { IntlProvider } from "react-intl";
import AppContainer from "./shared/components/AppContainer/app.container";
import { ReduxConnect } from "@hypereact/state";
import { ReduxState } from "./config/redux.config";
import { ConfigState } from "./shared/states/config.state";
import { LanguageService } from "./shared/services/language.service";
import { RouterGenerator } from "./shared/components/RouterGenerator/router.generator";
import { NavigationService } from "./shared/services/navigation.service";
import { NavigationState } from "./shared/states/navigation.state";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface IAppProps extends RouteComponentProps {
  config?: ConfigState;
  navigation?: NavigationState;
  history: History;
}

@ReduxConnect((state: ReduxState) => {
  return {
    config: state.config,
    navigation: state.navigation,
  };
})
class App extends React.Component<IAppProps> {
  private navigationService: NavigationService;

  constructor(props: IAppProps) {
    super(props);
    this.navigationService = NavigationService.getInstance();
    this.navigationService.listenRouteChange(props.history);
  }

  render() {
    return (
      <IntlProvider
        key={this.props?.config?.language}
        locale={LanguageService.getSelectedLanguage()}
        messages={LanguageService.getLanguagePack()}
        defaultLocale="en"
      >
        <ThemeProvider theme={themeLight}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AppContainer>
              <RouterGenerator
                entries={this.navigationService.getRouterConfigEntries()}
              />
            </AppContainer>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default withRouter(App);
