
export interface IInjectionManager {
    get(key: string, raiseException: boolean): any
}

export class InjectionManager implements IInjectionManager {

    private static instance: InjectionManager;

    private cache: Map<string, any> = new Map();

    private constructor() { }

    public static getInstance() {
        if (InjectionManager.instance == null) {
            InjectionManager.instance = new InjectionManager();
        }
        return InjectionManager.instance;
    }

    get(key: string, raiseException: boolean = false) {
        let result: any;
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }
        const element = document.querySelector(`script[id='injected-${key}']`);
        if (element == null && raiseException) {
            throw new Error(`not found injected data for key ${key}`);
        }
        try {
            result = JSON.parse(element?.textContent!);
            this.cache.set(key, result)
        } catch (e) {
            if (raiseException) {
                throw new Error(`not found injected data for key ${key}: ${e}`);
            }
        }
        return result;
    }
}