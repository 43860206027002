import { ReduxConnect, StoreManager } from "@hypereact/state";
import { Grid } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfigSetStatisticsOptionAction } from "../../../actions/statistics.actions";
import { ReduxState } from "../../../config/redux.config";
import { ConfigManager } from "../../../managers/config.manager";
import { StatisticsManager } from "../../../managers/statistics.manager";
import { FilterBar } from "../../../shared/components/FilterBar/filterbar.component";
import { ConfigTypeEnum } from "../../../shared/enums/config.type.enum";
import { StatisticsOptionsEnum } from "../../../shared/enums/statistics.options";
import { ConfigState } from "../../../shared/states/config.state";
import {
  BooksStatisticsState,
  initialStatisticsState,
  InstituteStatisticsState,
  StatisticsState,
} from "../../../shared/states/statistics.state";
import { FilterBarUtil } from "../../../shared/utils/filterBar.util";
import { StatisticsBookSearchResultComponent } from "../components/statistics.books.search.result.component";
import { StatisticsDashboardComponent } from "../components/statistics.dashboard.component";
import { StatisticsInstituteSearchResultComponent } from "../components/statistics.institutes.search.result.component";
import { StatisticsOptionsComponent } from "../components/statistiscs.options.component";
import { FilterBarItems } from "../config/filterbar.config";

interface IStatisticsViewProps {
  statistics: StatisticsState;
  config: ConfigState;
  bookStatistics: BooksStatisticsState;
  instituteStatistics: InstituteStatisticsState;
}

@ReduxConnect((state: ReduxState) => {
  return {
    statistics: state.statistics,
    config: state.config,
    bookStatistics: state.bookStatistics,
    instituteStatistics: state.instituteStatistics,
  };
})
export class StatisticsView extends React.Component<IStatisticsViewProps> {
  private configManager: ConfigManager;
  private statisticsManager: StatisticsManager;
  private KEYWORD_MIN_CHAR: number = 3;
  private storeManager: StoreManager;

  constructor(props) {
    super(props);
    this.configManager = ConfigManager.getInstance();
    this.statisticsManager = StatisticsManager.getInstance();
    this.storeManager = StoreManager.getInstance();
  }

  async componentDidMount() {
    await this.statisticsManager.getBookStatistics();
    await this.statisticsManager.getInstituteStatistics();
    await this.statisticsManager.getTotal();
  }

  async handleOnChange(name: string, value: any) {
    if (name !== "query" || (value && value.length >= this.KEYWORD_MIN_CHAR)) {
      this.statisticsManager.resetPagination();
      await this.statisticsManager.onFilterChange(name, value);
    }
  }

  async handleOnClearFilters() {
    this.statisticsManager.fullReset();
  }

  async handleOnRefresh() {
    if (StatisticsOptionsEnum.Book === this.props.statistics.statisticsOption) {
      this.statisticsManager.getBookStatistics();
    } else {
      this.statisticsManager.getInstituteStatistics();
    }
  }

  getShowFilter(): boolean {
    return StatisticsOptionsEnum.Book === this.props.statistics.statisticsOption
      ? this.props.bookStatistics.showClearFilter
      : this.props.instituteStatistics.showClearFilter;
  }

  onPaginationChange(page: number, size: number) {
    this.statisticsManager.setPagination(page, size);
  }

  showFilterBar(): boolean {
    if (StatisticsOptionsEnum.Book === this.props.statistics.statisticsOption) {
      return (
        this.props.bookStatistics.items &&
        this.props.bookStatistics.items.length > 0
      );
    } else {
      return (
        this.props.instituteStatistics.items &&
        this.props.instituteStatistics.items.length > 0
      );
    }
  }

  render() {
    const { statisticsOption, totalNumberOfDownload } = this.props.statistics;
    const filterBarItems = FilterBarUtil.filter(
      this.configManager.getConfigByType(
        ConfigTypeEnum.STATISTICS,
        statisticsOption
      ),
      FilterBarItems.getFilterBarItems()
    );

    return (
      <>
        <Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item sm={1} xs={1}>
              <StatisticsDashboardComponent total={totalNumberOfDownload} />
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormattedMessage id="statistics.view.title" />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={6}
          >
            <Grid item sm={3} xs={3}>
              <StatisticsOptionsComponent />
            </Grid>
          </Grid>

          <FilterBar
            items={filterBarItems}
            config={this.statisticsManager.getConfig(
              filterBarItems,
              this.props.statistics.statisticsOption
            )}
            showClearFilter={this.getShowFilter()}
            onChange={(name: string, value: any) =>
              this.handleOnChange(name, value)
            }
            onClearFilters={() => this.handleOnClearFilters()}
            onRefreshFilters={() => this.handleOnRefresh()}
          />

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={6}
          >
            <Grid item sm={9} xs={12}>
              {StatisticsOptionsEnum.Book === statisticsOption ? (
                <StatisticsBookSearchResultComponent
                  items={this.props.bookStatistics.items}
                ></StatisticsBookSearchResultComponent>
              ) : (
                <StatisticsInstituteSearchResultComponent
                  items={this.props.instituteStatistics.items}
                ></StatisticsInstituteSearchResultComponent>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* <div className="align-center">
             <PaginationComponent
               onChange={(page: number, size: number) =>
                 this.onPaginationChange(page, size)
               }
               page={StatisticsOptionsEnum.Book === statisticsOption ? this.props.bookStatistics.pagination.page :  this.props.instituteStatistics.pagination.page}
               total={StatisticsOptionsEnum.Book === statisticsOption ? this.props.bookStatistics.pagination.total :  this.props.instituteStatistics.pagination.total}
               size={StatisticsOptionsEnum.Book === statisticsOption ? this.props.bookStatistics.pagination.size :  this.props.instituteStatistics.pagination.size}
               sizes={[5, 10, 15, 20]}
             />
        </div> */}
      </>
    );
  }

  componentWillUnmount() {
    this.statisticsManager.fullReset(false);
    this.storeManager.dispatch(
      new ConfigSetStatisticsOptionAction(
        initialStatisticsState.statisticsOption
      )
    );
  }
}
