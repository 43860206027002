export interface HttpState {
  loading: boolean;
  pending: number;
  completed: number;
  headers: Map<string, string>;
}

export const initialHttpState: HttpState = {
  loading: false,
  pending: 0,
  completed: 0,
  headers: new Map()
};